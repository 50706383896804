import { CategoryDto } from '../../model/dto/CategoryDto';
import React, { ReactElement } from 'react';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { ThematicDto } from '../../model/dto/ThematicDto';
import {
  ResourceDataProviderEnum
} from '../../enum/ResourceDataProviderEnum';
import { Dropdown } from './Dropdown';

export function CategoryDropdown(props: {
  index:number,
  category:CategoryDto,
  resource:string
}): ReactElement {

  const dsThematics = useDataSource<ThematicDto>({
    resource: ResourceDataProviderEnum.thematic,
    params: {
      filter: { category: props.category.id, light: true },
      sort: { field: 'position', order: 'asc' },
    },
  });

  return (
    <>
      {dsThematics.total > 0 && <Dropdown
        href={`category-${props.index}`}
        label={props.category.name}
        ds={dsThematics}
        resource={props.resource}
      ></Dropdown>}
    </>
  );

}
