import React from 'react';

import PopupDemandeDejaEnregistree from './PopupDemandeDejaEnregistree';
import { CollaboratorDto } from '../../model/dto/CollaboratorDto';
import { InscritADemandeDto } from '../../model/dto/InscritADemandeDto';
import { RegionDto } from "../../model/dto/RegionDto";

type Props = {
    collaborators: CollaboratorDto[],
    trainingTitle: string,
    regions: RegionDto[],
    dejaInscrits: InscritADemandeDto [],
}

export default function PopupDemandeEnregistree(props : Props) {
    return (
        <>
            {
                // Les collaborateurs inscrits avec succes
                props.collaborators.length > 0
                && (
                    <>
                        <div className="col-md-7">
                            <h5>
                                Nous vous confirmons la demande de formation pour :
                            </h5>
                            <p className="text-white textBox">
                                {props.trainingTitle}
                            </p>
                        </div>
                        <div className="col-md-5">
                            <h5>
                                Participant(s) :
                            </h5>
                            {props.collaborators.map((inscrip) => (
                                <p className="text-white textBox" key={inscrip.id}>
                                    {inscrip.fullname}
                                </p>
                            ))}
                        </div>
                        <div className="col-md-12">
                            <h5>
                                Region(s) :
                            </h5>
                            {props.regions.map((region) => (
                                <p className="text-white textBox" key={region.id}>
                                    {region.name}
                                </p>
                            ))}
                        </div>
                    </>
                )
            }
            {
                // Les collaborateurs n'ayant pas pu être inscrits
                props.dejaInscrits.length > 0
                && (
                    <>
                        {props.collaborators.length > 0 && (
                            <hr/>
                        )}
                        <PopupDemandeDejaEnregistree
                            trainingTitle={props.trainingTitle}
                            inscrits={props.dejaInscrits}
                        />
                    </>
                )
            }
        </>
    );
}
