import * as Yup from 'yup';

import React, { useRef, useState } from 'react';

import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Add from '../../../../assets/icons/add.png';
import Hn from '../../../../components/common/Hn';
import { BaseContainer } from '../../../../components/layout/BaseContainer';
import { RouteEnum } from '../../../../enum/RouteEnum';

import './locationForm.css';
import 'react-toastify/dist/ReactToastify.css';

const validationSchema = Yup.object().shape({
  nom: Yup.string().required('Champ obligatoire.'),
  prenom: Yup.string().required('Champ obligatoire.'),
  type: Yup.string().required('Champ obligatoire.'),
  reference: Yup.string().required('Champ obligatoire.'),
});

function CreateFormer() {
  const fileRef = useRef();
  const { goTo } = useRouter();
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  let toastId = null;
  // let history = useHistory();
  const initialInputState = {
    type: '',
    nom: '',
    prenom: '',
    reference: '',
    image: '',
    telephone: '',
    email: '',
    actif: '',
    commentaire: '',
  };
  const [eachEntry, setEachEntry] = useState(initialInputState);
  const {
    type,
    nom,
    prenom,
    reference,
    image,
    telephone,
    email,
    actif,
    commentaire,
  } = eachEntry;
  const handleChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  const uri = process.env.REACT_APP_BASE_URL;
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: 'my_toast',
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log('Toast already active');
    }
  }
  // créer un nouveau formateur
  const handleSubmit = (values) => {
    const datasend = new FormData();
    datasend.append('organize', values.type);
    datasend.append('firstName', values.nom);
    datasend.append('lastName', values.prenom);
    datasend.append('ref', values.reference);
    datasend.append('brochure', values.image);
    datasend.append('tel', values.telephone);
    datasend.append('email', values.email);
    datasend.append('status', values.actif);
    datasend.append('comment', values.commentaire);

    axios.post(`${uri}/former/new`, datasend)
      .then(() => {
        displayToast(
          'Csuccess',
          'Formateur ajouté avec succès, Redirection...',
        );
        setTimeout(() => {
          goTo(RouteEnum.ADMIN_TRAINER);
        }, 3000);
      })
      .catch(() => {
        displayToast('Cerror', "Quelque chose s'est mal passé, Réessayer");
      });
  };

  return (
    <BaseContainer>
      <div className="lists p-5">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={RouteEnum.ADMIN_TRAINER}>
                Formations présentielles - Formateurs
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Ajouter un formateur
            </li>
          </ol>
        </nav>
        <div className="p-5 text-white bg-dark rounded-3">
          <Hn
            title="Ajouter un formateur"
            level="h4"
            className="h4-size pb-5"
            plus={false}
          />

          <div className="form-elements">
            <Formik
              initialValues={{
                type: '',
                nom: '',
                prenom: '',
                reference: '',
                image: '',
                telephone: '',
                email: '',
                actif,
                commentaire,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="row g-3">
                  <div className="col-md-4">
                    <label className="form-label">Formateur*</label>
                    <select
                      className="form-select"
                      name="type"
                      aria-label="Sélectionner dans la liste"
                      id="former"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.type}
                    >
                      <option value="" default>
                        Sélectionner dans la liste
                      </option>
                      <option value="interne" defaultValue>
                        Formateur interne
                      </option>
                      <option value="externe">Formateur externe</option>
                    </select>

                    <ErrorMessage name="type">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">Nom*</label>
                    <input
                      type="text"
                      name="nom"
                      className="form-control"
                      id="init"
                      placeholder="Nom"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nom}
                    />
                    <ErrorMessage name="nom">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Prénom*</label>
                    <input
                      type="text"
                      name="prenom"
                      className="form-control"
                      id="init"
                      placeholder="prénom"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prenom}
                    />
                    <ErrorMessage name="prenom">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-8">
                    <label className="form-label">Référence*</label>
                    <input
                      type="text"
                      name="reference"
                      className="form-control"
                      id="reference"
                      placeholder="Référence"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.reference}
                      autoComplete="off"
                    />
                    <ErrorMessage name="reference">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Image</label>
                    <span
                      className="form-control"
                      onClick={() => fileRef.current.click()}
                    >
                      <img src={Add} className="pe-1" />
                      {' '}
                      {fileRef?.current?.value
                        ? fileRef?.current?.value
                        : 'Ajouter une image'}
                    </span>

                    <input
                      ref={fileRef}
                      multiple={false}
                      type="file"
                      name="image"
                      onChange={(event) => {
                        setFieldValue('image', event.currentTarget.files[0]);
                      }}
                      hidden
                      accept=".png,.jpg,.gif"
                    />
                    <ErrorMessage name="image">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                    {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Téléphone</label>
                    <input
                      type="number"
                      name="telephone"
                      className="form-control"
                      id="init"
                      placeholder="00 00 00 00"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.telephone}
                    />
                    <ErrorMessage name="telephone">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      id="assoc"
                      placeholder="Email@gmail.com"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.email}
                    />
                    <ErrorMessage name="email">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Actif</label>
                    <select
                      className="form-select"
                      name="actif"
                      aria-label="Default select example"
                      id="actif"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.actif}
                    >
                      <option value defaultValue>
                        Oui
                      </option>
                      <option value={false}>Non</option>
                    </select>
                    <ErrorMessage name="actif">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">Commentaire</label>
                    <textarea
                      className="form-control"
                      name="commentaire"
                      id="pre"
                      rows="3"
                      placeholder="Ajouter un commentaire"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.commentaire}
                    />
                    <ErrorMessage name="commentaire">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 pt-5">
                    <Link to={RouteEnum.ADMIN_TRAINER} className="btn btn-link resetBtn">
                      Annuler
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-primary float-end"
                    >
                      Valider
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
}

export default CreateFormer;
