import * as Yup from 'yup';

import React, { ReactElement } from 'react';

import { SubmitBtn } from 'leeloo-react/component/form/action/SubmitBtn';
import { CheckField } from 'leeloo-react/component/form/field/CheckField';
import { Form } from 'leeloo-react/component/form/Form';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { Card } from 'react-bootstrap';

import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import RoleUserEnum from '../../../enum/RoleUserEnum';
import { RouteEnum } from '../../../enum/RouteEnum';
import { AuthReducerType } from '../../../model/authProvider/AuthReducerType';
import { LogoutAsLink } from '../../auth/logout/LogoutAsLink';
import { SimpleContainer } from '../../layout/SimpleContainer';

export function ValidateTerms(): ReactElement {
  const { goTo } = useRouter();
  const { authData, checkAccess }: {authData: AuthReducerType, checkAccess: (roles: string[]) => boolean} = useAuthProvider()! as any;
  const legalUri = process.env.REACT_APP_LEGALS;

  return (
    <SimpleContainer>
      <div className="row justify-content-center align-self-center">
        <div className="col-sm-6 col-lg-5">
          <Form
            resource={ResourceDataProviderEnum.usersValidateTerms}
            initialValue={{
              contact: authData.id,
              cgu: false,
            }}
            validationSchema={{
              contact: Yup.string(),
              cgu: Yup.boolean().oneOf([true], 'Vous devez accepter les CGV, CGU'),
            }}
            onSubmitEndUpdate={() => {
              if (checkAccess([RoleUserEnum.ROLE_SUPER_ADMIN])) {
                goTo(RouteEnum.ADMIN_DASHBOARD);
              } else {
                goTo(RouteEnum.SEARCH);
              }
            }}
          >
            <Card>
              <Card.Header>
                <h1 className="text-center p-3 pb-1">Bienvenue</h1>
                <h5 className="text-center">{`${authData.firstname} ${authData.lastname}`}</h5>
              </Card.Header>
              <Card.Body>
                <CheckField label="En poursuivant j'accepte les CGV, les CGU et le réglement intérieur de la plateforme" name="cgu" />
              </Card.Body>
              <div className="card-footer mb-3">
                <SubmitBtn className="btn btn-primary w-100 mb-3" label="Poursuivre" />
              </div>
            </Card>
          </Form>
          <div className="d-flex mt-3 justify-content-center">
            <a href={`${legalUri}reglement-interieur-2022-pdf.pdf`} target="_blank" rel="noreferrer">
              Règlement intérieur
            </a>
            <a href={`${legalUri}cgv-formations-2022.pdf`} target="_blank" rel="noreferrer" className="mx-4">
              CGV
            </a>
            <a href={`${legalUri}cgu.pdf`} target="_blank" rel="noreferrer">
              CGU
            </a>
          </div>
          { authData.loginAs && <LogoutAsLink className="btn btn-link" /> }
        </div>
      </div>
    </SimpleContainer>
  );
}
