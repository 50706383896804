import React from 'react';

import { DataSourceType } from 'leeloo-react/component/hook/useDataSource';

import { DataTableCreateButton } from './button/DataTableCreateButton';
import { DataTableDeleteButton } from './button/DataTableDeleteButton';
import { DataTableDuplicateButton } from './button/DataTableDuplicateButton';
import { DataTableExportButton } from './button/DataTableExportButton';

type Props<T> = {
    rows: (T & {id: any})[],
    createResource?: string,
    deleteResource?: string,
    duplicateResource?: string,
    exportResource?: string,
    exportNameFile?: string,
    dataSource?: DataSourceType,
    cleanSelection?: () => void
    data?: {[key in string]: any},
}
export function DataTableBulksActions(props: Props<any>) {
  return (
    <>
      {props.createResource && (
        <DataTableCreateButton resource={props.createResource} {...props} />
      )}
      {props.duplicateResource && (
        <DataTableDuplicateButton resource={props.duplicateResource} {...props} />
      )}
      {props.exportResource && (
        <DataTableExportButton resource={props.exportResource} nameFile={props.exportNameFile ?? ''} {...props} />
      )}
      {props.deleteResource && (
        <DataTableDeleteButton resource={props.deleteResource} {...props} />
      )}
    </>
  );
}

DataTableBulksActions.defaultProps = {
  dataSource: null,
  createResource: null,
  deleteResource: null,
  duplicateResource: null,
  exportResource: null,
  exportNameFile: null,
  cleanSelection: null,
  data: null,
};
