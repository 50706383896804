import React, { ReactElement, useEffect, useState } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import ReactCardFlip from 'react-card-flip';
import { useSelector } from 'react-redux';

import Placeholder from '../../assets/placeholder.png';
import { ResourceDataProviderEnum } from '../../enum/ResourceDataProviderEnum';
import { ELearningDto } from '../../model/dto/ELearningDto';
import { OnSiteDto } from '../../model/dto/OnSiteDto';
import { StateType } from '../../model/store/StateType';
import PopupSubscribe from '../popup/PopupSubscribe';
import { RatingSummary } from '../rating/RatingSummary';

type FormationCardProps = {
    formation: ELearningDto|OnSiteDto
    eLearning?: boolean
    flip?: boolean
    number?: number
}

export function FormationCard(props: FormationCardProps): ReactElement {
  const [flip, setFlip] = useState(false);
  const [nbFormationUnits, setNbFormationUnits] = useState(0);
  const [showFilterSubscribe, setShowFilterSubscribe] = useState(false);
  const { goTo } = useRouter();
  const config = useSelector((s: StateType) => s.config);
  const baseImgUrl = props.eLearning ? process.env.REACT_APP_PIC_ELEARNING_IMG : process.env.REACT_APP_PIC_FACETRAINING_PATH;

  const { oneData: scores, refreshData: refreshScores } = useDataSource<any>({
    resource: ResourceDataProviderEnum.eLearningScore,
    params: { id: props.formation.id },
    singleResult: true,
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (props.flip === true) {
      refreshScores();
    }
  }, [props.flip]);

  useEffect(() => {
    if (scores != null && scores?.scoreds.length > 0) {
      setNbFormationUnits(scores.scoreds.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.unites.length, 0));
    }
  }, [scores]);

  const handleFlip = () => {
    if (props.flip) {
      setFlip(!flip);
    }
    else{
      checkValidity();
    }
  };

  const checkValidity = () => {
    if (props.eLearning && !config.accessELearning) {
      setShowFilterSubscribe(true);
    } else {
      goTo(`/${props.eLearning ? 'detailformations' : 'detailformation'}/${props.formation.id}`);
    }
  };

  return (
    <ReactCardFlip
      isFlipped={flip}
      flipDirection="horizontal"
      containerClassName="mb-4"
    >
      <div className="formation-card">
        <div className="head" role="presentation" onClick={() => handleFlip()}>
          <span className="icon">
              <Icon name={`uil uil-${props.eLearning ? 'desktop' : 'book-alt'}`} />
          </span>
          {props.number && <div className="number">{props.number}</div>}
          <div className="cover">
            <img src={props.formation.image !== null ? `${baseImgUrl!}${props.formation.image}` : Placeholder} alt="" />
          </div>
        </div>
        {/* TODO, doit y avoir mieux à faire que ce pauvre truc pourri */}
        <PopupSubscribe
          showFilterSubscribe={showFilterSubscribe}
          setShowFilterSubscribe={setShowFilterSubscribe}
          title={('intitule' in props.formation && props.formation?.intitule) || ('title' in props.formation && props.formation?.title) || ''}
        />
        <div className="body" onClick={() => checkValidity()} role="presentation">
          <h5 className="card-title">
            {('intitule' in props.formation && props.formation?.intitule) || ('title' in props.formation && props.formation?.title)}
          </h5>
          {props.formation?.displayRating && <RatingSummary formation={props.formation} />}
        </div>
      </div>
      <div
        role="presentation"
        className="reverse-formation-card"
        onClick={() => handleFlip()}
      >
        <div>
          {(('parcours_associe' in props.formation && props.formation?.parcours_associe) || ('assoc_cours' in props.formation && props.formation?.assoc_cours)) && (
            <>
              <p className="titleBack">Parcours associé : </p>
              <p className="titleBack">{('parcours_associe' in props.formation && props.formation?.parcours_associe) || ('assoc_cours' in props.formation && props.formation?.assoc_cours)}</p>
            </>
          )}
          <p className="textBack">
            <span className="titleBack">Score actuel:</span>
            {` ${scores?.score} %`}
          </p>
          <p className="titleBack">
            Unités d’apprentissage restantes :
            {nbFormationUnits - (scores?.validated ?? 0)}
          </p>
          {/* TODo à voir comment faire avecun routerlink */}
          <button type="button" onClick={() => { goTo(`/${props.eLearning ? 'detailformations' : 'detailformation'}/${props.formation.id}?run`); }} className="btn btn-primary flex-end reprendre">
            Reprendre
          </button>
          {/* <RouterLink routeName={RouteEnum.E_LEARNING_DETAIL} params={{ id: props.formation.id }} className="btn btn-primary flex-end reprendre"> */}
          {/*    Reprendre */}
          {/* </RouterLink> */}
        </div>
      </div>
    </ReactCardFlip>
  );
}

FormationCard.defaultProps = {
  eLearning: true,
  flip: false,
};
