import React from 'react';

import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useModal } from 'leeloo-react/component/hook/useModal';
import { useTrans } from 'leeloo-react/component/hook/useTrans';

/**
 * Affiche et gère le logout client
 * Surcharger avec children pour un affichage custom
 */
export function LogoutLink(props: {children?: any, className?: string}) {
  const authProvider = useAuthProvider();
  const { t } = useTrans();
  const { confirm } = useModal();

  const logout = async () => {
    if (authProvider) {
      const response = await confirm('Êtes-vous sur de vouloir vous déconnecter');
      if (response) {
        await authProvider.logout().then(() => {
          window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout';
        });
      }
    }
  };

  return (
    <div tabIndex={-1} role="button" className={props.className} onClick={logout} onKeyUp={logout}>
      {props.children == null && <span role="presentation">{t('actions.disconnect')}</span>}
      {props.children != null && props.children}
    </div>
  );
}

LogoutLink.defaultProps = {
  children: null,
  className: 'null',
};
