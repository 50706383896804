import React, {
  ReactElement, useEffect, useState,
} from 'react';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { isEmptyArray } from 'formik';
import { SelectField } from 'leeloo-react/component/form/field/SelectField';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import moment from 'moment';

import { KpiThematiqueELearningByAgency } from './kpis/KpiThematiqueELearningByAgency';
import { KpiThematiqueELearningByCollaborator } from './kpis/KpiThematiqueELearningByCollaborator';
import { KpiThematiquePresentielByAgency } from './kpis/KpiThematiquePresentielByAgency';
import { KpiThematiquePresentielByCollaborator } from './kpis/KpiThematiquePresentielByCollaborator';
import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { AuthReducerType } from '../../../model/authProvider/AuthReducerType';
import { AgencyDto } from '../../../model/dto/AgencyDto';
import { CollaboratorDto } from '../../../model/dto/CollaboratorDto';
import { BaseContainer } from '../../layout/BaseContainer';

export function DashboardPage(): ReactElement {
  type valueLabel = { value: string, label: string }[];
  const [valueDateRange, setValueDateRange] = useState<[Date, Date]>([new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)]);
  const [dateRange, setDateRange] = useState<{ startAt?: string | undefined; endAt?: string | undefined; } | undefined>();
  const { authData }: { authData: AuthReducerType } = useAuthProvider()! as any;
  const dsAgenciesByCollaborator = useDataSource<AgencyDto>({
    resource: ResourceDataProviderEnum.userAgenciesByCollaborator,
    noLoadAfterInit: true,
  });
  const [agencies, setAgencies] = useState<valueLabel>([]);
  const [collaborators, setCollaborators] = useState<valueLabel>([]);
  const [selectedAgency, setSelectedAgency] = useState<string>('');
  const [selectedCollaborator, setSelectedCollaborator] = useState<string>('');
  const dsCollaboratorsByAgency = useDataSource<CollaboratorDto>({
    resource: ResourceDataProviderEnum.userCollaboratorsByAgency,
    noLoadAfterInit: true,
  });

  const [multiAgencies, setMultiAgencies] = useState<boolean>(false);

  const getAgency = () => {
    if (dsAgenciesByCollaborator.dataList && !isEmptyArray(dsAgenciesByCollaborator.dataList)) {
      const agenciesData = dsAgenciesByCollaborator.dataList.map((data: any) => ({
        value: data.id,
        label: data.name,
      }));
      if (!isEmptyArray(agenciesData)) {
        setAgencies(agenciesData);
        setSelectedAgency(agenciesData[0].value);
      }
    }
  };

  const getCollaborator = () => {
    if (dsCollaboratorsByAgency.dataList && !isEmptyArray(dsCollaboratorsByAgency.dataList)) {
      const collaboratorsData = dsCollaboratorsByAgency.dataList.map((data: any) => ({
        value: data.id,
        label: data.fullname,
      }));
      setCollaborators(collaboratorsData);
      setSelectedCollaborator(authData.id);
    }
  };

  useEffect(() => {
    if (dateRange) {
      dsAgenciesByCollaborator.setParams({ id: authData.id });
    }
  }, [dateRange]);

  useEffect(() => {
    setDateRange({
      startAt: moment(valueDateRange[0]).format('Y-MM-DD'),
      endAt: moment(valueDateRange[1]).format('Y-MM-DD'),
    });
  }, [valueDateRange]);

  useEffect(() => {
    if (dsAgenciesByCollaborator.dataList && dsAgenciesByCollaborator.dataList.length !== 0) {
      getAgency();
    }
  }, [dsAgenciesByCollaborator.dataList]);

  useEffect(() => {
    if (selectedAgency != null) {
      dsCollaboratorsByAgency.setParams({ id: selectedAgency });
    }
  }, [selectedAgency]);

  useEffect(() => {
    if (dsCollaboratorsByAgency.dataList && dsCollaboratorsByAgency.dataList.length !== 0) {
      getCollaborator();
    }
    if (dsAgenciesByCollaborator.dataList && dsAgenciesByCollaborator.dataList.length > 1) {
      setMultiAgencies(true);
    }
  }, [dsCollaboratorsByAgency.dataList]);

  return (
    <BaseContainer wrapperClassName="dashboard">
      <div className="lists-title mb-4">
        <h1>Tableau de bord</h1>
      </div>
      <div className="row align-items-end mb-3">
        <div className="offset-xxl-5 col-xxl-2 offset-xl-2 col-xl-3 col-lg-4 col-sm-6">
          {multiAgencies && (
            <SelectField
              label="Agences"
              isClearable={false}
              value={selectedAgency}
              optionsList={agencies}
              onChangeValue={setSelectedAgency}
              placeholder=" "
              className="mb-3"
            />
          )}
        </div>

        <div className={multiAgencies ? "col-xxl-2 col-xl-3 col-lg-4 col-sm-6" : "col-xxl-2 col-xl-3 col-lg-4 col-sm-12"}>
          <SelectField
            label="Collaborateurs"
            isClearable={false}
            value={selectedCollaborator}
            optionsList={[{ label: 'Mes informations', value: authData.id }, ...collaborators]}
            onChangeValue={setSelectedCollaborator}
            placeholder=" "
            className="mb-3"
          />
        </div>
        <div className="col-xxl-3 col-xl-4 col-lg-4 col-sm-12">
          <DateRangePicker
            onChange={(v: any) => setValueDateRange(v)}
            value={valueDateRange}
            locale="fr-FR"
            className="form-control mb-3"
            format="dd/MM/y"
            clearIcon={null}
          />
        </div>
      </div>
      <h3 className="plus mb-4">Données agence</h3>
      <div className="row mb-4">
        <div className="col-lg-6">
          <KpiThematiquePresentielByAgency dateRange={dateRange} agency={selectedAgency} />
        </div>
        <div className="col-lg-6">
          <KpiThematiqueELearningByAgency dateRange={dateRange} agency={selectedAgency} />
        </div>
      </div>
      <h3 className="plus mb-4">Données collaborateur</h3>
      <div className="row">
        <div className="col-lg-6">
          <KpiThematiquePresentielByCollaborator dateRange={dateRange} agency={selectedAgency} collaborator={selectedCollaborator} />
        </div>
        <div className="col-lg-6">
          <KpiThematiqueELearningByCollaborator dateRange={dateRange} agency={selectedAgency} collaborator={selectedCollaborator} />
        </div>
      </div>
    </BaseContainer>
  );
}
