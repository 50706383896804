import React, { ReactElement, useEffect, useState } from 'react';

import { SimpleColumn } from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DateColumn } from 'leeloo-react/component/dataTable/column/DateColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { CustomFilterDataProviderType } from '../../../../model/dataProvider/CustomFilterDataProviderType';
import { DataTableTrainingRequestBulksActions } from '../../../dataTable/trainingRequest/DataTableTrainingRequestBulksActions';
import { DataTableHeaderActions } from '../../../dataTable/DataTableHeaderActions';
import { DataTableTrainingRequestListActions } from '../../../dataTable/trainingRequest/DataTableTrainingRequestListActions';
import {isEmptyArray} from "formik";
import {SelectField} from "leeloo-react/component/form/field/SelectField";
import {TrainingRequestDto} from "../../../../model/dto/TrainingRequestDto";

export function TrainingRequestDataTable(): ReactElement {
    const [filters] = useState<CustomFilterDataProviderType>({});
    const dsTrainingRequest = useDataSource({
        resource: ResourceDataProviderEnum.trainingRequest,
        params: {
            pagination: { page: 1, take: 50 },
        },
    });
    const dsTrainingsFromTR = useDataSource({
        resource: ResourceDataProviderEnum.trainingRequestList,
    });
    const dsRegions = useDataSource({
        resource: ResourceDataProviderEnum.regionsList,
    });

    const dsStatus = useDataSource({
        resource: ResourceDataProviderEnum.trainingRequestStatus,
    });

    type valueLabel = { value: string, label: string }[];

    const exportNameFile = 'besoins_formation.csv';
    const [selectedRegions, setSelectedRegions] = useState<valueLabel>([]);
    const [selectedTrainings, setSelectedTrainings] = useState<valueLabel>([]);
    const [selectedStatus, setSelectedStatus] = useState<valueLabel>([]);
    const [regions, setRegions] = useState<valueLabel>([]);
    const [trainings, setTrainings] = useState<valueLabel>([]);
    const [status, setStatus] = useState<valueLabel>([]);

    // Permet de charger la liste du multi-select des régions une fois que la datatable est chargée
    // Sans quoi la liste sera vide
    useEffect(() => {
        if (dsRegions.dataList && dsRegions.dataList.length !== 0) {
            getRegions();
        }
    }, [dsRegions.dataList]);

    // Permet de charger la liste du multi-select des formations une fois que la datatable est chargée
    // Sans quoi la liste sera vide
    useEffect(() => {
        if (dsTrainingsFromTR.dataList && dsTrainingsFromTR.dataList.length !== 0) {
            getTrainings();
        }
    }, [dsTrainingsFromTR.dataList]);

    // Permet de charger la liste du multi-select des statuts une fois que la datatable est chargée
    // Sans quoi la liste sera vide
    useEffect(() => {
        if (dsStatus.dataList && dsStatus.dataList.length !== 0) {
            getStatus();
        }
    }, [dsStatus.dataList]);

    // Permet de remplir la liste déroulante du multi-select des régions
    const getRegions = () => {
        if (dsRegions.dataList && !isEmptyArray(dsRegions.dataList)) {
            const regionsData = dsRegions.dataList.map((data: any) => ({
                value: data.id,
                label: data.name,
            }));
            setRegions(regionsData);
        }
    };

    // Permet de remplir la liste déroulante du multi-select des formations
    const getTrainings = () => {
        if (dsTrainingsFromTR.dataList && !isEmptyArray(dsTrainingsFromTR.dataList)) {
            const trainingsData = dsTrainingsFromTR.dataList.map((data: any) => ({
                value: data.trainingId,
                label: data.trainingTitle,
            }));

            const uniqueTrainingsData = trainingsData.filter(
                (item, index, self) => index === self.findIndex((t) => t.label === item.label),
            );
            setTrainings(uniqueTrainingsData);
        }
    };

    // Permet de remplir la liste déroulante du multi-select des statuts
    const getStatus = () => {
        if (dsStatus.dataList && !isEmptyArray(dsStatus.dataList)) {
            const statusData = dsStatus.dataList.map((data: any) => ({
                value: data.id,
                label: data.name,
            }));
            setStatus(statusData);
        }
    };

    return (
        <DataTable
            dataSource={dsTrainingRequest}
            filter={(
                <Filter>
                    <SelectField
                        name="searchTraining"
                        label="Actions de formation"
                        value={selectedTrainings}
                        onChangeValue={setSelectedTrainings}
                        optionsList={trainings}
                        isMulti
                        className="themeSelect"
                        placeholder=" "
                    />
                    <SelectField
                        name="searchRegion"
                        label="Régions"
                        value={selectedRegions}
                        onChangeValue={setSelectedRegions}
                        optionsList={regions}
                        isMulti
                        className="themeSelect"
                        placeholder=" "
                    />
                    <SelectField
                        name="searchStatus"
                        label="Statuts"
                        value={selectedStatus}
                        onChangeValue={setSelectedStatus}
                        optionsList={status}
                        isMulti
                        className="themeSelect"
                        placeholder=" "
                    />
                </Filter>
            )}
            actionHeader={(
                <DataTableHeaderActions
                    exportResource={ResourceDataProviderEnum.trainingRequestExport}
                    exportNameFile={exportNameFile}
                    exportFilters={filters}
                    dataSource={dsTrainingRequest}
                />
            )}
            actionRow={(row) => [
                <DataTableTrainingRequestListActions
                    key={0}
                    row={row}
                    dataSource={dsTrainingRequest}
                    validateResource={ResourceDataProviderEnum.trainingRequestValidate}
                    archiveResource={ResourceDataProviderEnum.trainingRequestArchive}
                    unarchiveResource={ResourceDataProviderEnum.trainingRequestUnarchive}
                />,
            ]}
            // On utilise transformCheckedRow pour ajouter des données
            // dans l'attribut "rows" du onChangeCheckedRow
            transformCheckedRow={(row: TrainingRequestDto) => {
                return {
                    id: row.id,
                    canValidate: row.canValidate,
                    canArchive: row.canArchive,
                    canUnarchive: row.canUnarchive,
                };
            }}
            onChangeCheckedRow={(rows: {id: number|string}[], cleanSelection) => (
                <DataTableTrainingRequestBulksActions
                    rows={rows}
                    dataSource={dsTrainingRequest}
                    exportResource={ResourceDataProviderEnum.trainingRequestExport}
                    validateResource={ResourceDataProviderEnum.trainingRequestValidate}
                    archiveResource={ResourceDataProviderEnum.trainingRequestArchive}
                    unarchiveResource={ResourceDataProviderEnum.trainingRequestUnarchive}
                    exportNameFile={exportNameFile}
                    cleanSelection={cleanSelection}
                />
            )}
        >
            <SimpleColumn field="trainingTitles" title="Action de formation" sortable />
            <SimpleColumn field="regions" title="Régions" sortable />
            <SimpleColumn field="contactTrainingRequestsNames" title="Inscrits" sortable />
            <SimpleColumn field="status" title="Statut" sortable />
            <DateColumn field="createdAt" title="Date" sortable />
        </DataTable>
    );
}
