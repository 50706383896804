import React, { useState } from 'react';

import { DateColumn } from 'leeloo-react/component/dataTable/column/DateColumn';
import { EditorColumn } from 'leeloo-react/component/dataTable/column/EditorColumn';
import { SimpleColumn } from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { CustomFilterDataProviderType } from '../../../../model/dataProvider/CustomFilterDataProviderType';
import { ListColumn } from '../../../dataTable/column/ListColumn';
import { DataTableBulksActions } from '../../../dataTable/DataTableBulksActions';
import { DataTableHeaderActions } from '../../../dataTable/DataTableHeaderActions';
import { DataTableListActions } from '../../../dataTable/DataTableListActions';
import { SwitchField } from '../../../form/SwitchField';

export function WebinarDataTable() {
  const [filters, setFilters] = useState<CustomFilterDataProviderType>({});
  const dsWebinars = useDataSource({
    resource: ResourceDataProviderEnum.webinarAll,
    params: {
      pagination: { page: 1, take: 50 },
    },
  });
  const exportNameFile = 'webinaires.csv';

  return (
    <DataTable
      dataSource={dsWebinars}
      filter={(
        <Filter>
          <InputField name="search" label="Rechercher" onChangeValue={(v) => setFilters({ search: v })} />
        </Filter>
      )}
      actionHeader={(
        <DataTableHeaderActions
          addRoute={RouteEnum.ADMIN_WEBINAR_ADD}
          addRouteValue="Créer un webinaire"
          importResource={ResourceDataProviderEnum.webinarImport}
          exportResource={ResourceDataProviderEnum.webinarExport}
          exportNameFile={exportNameFile}
          exportFilters={filters}
          dataSource={dsWebinars}
        />
      )}
      actionRow={(row) => [
        <DataTableListActions
          key={0}
          row={row}
          dataSource={dsWebinars}
          readRoute={RouteEnum.ADMIN_WEBINAR_SHOW}
          deleteResource={ResourceDataProviderEnum.webinar}
          duplicateResource={ResourceDataProviderEnum.webinarDuplicate}
          editRoute={RouteEnum.ADMIN_WEBINAR_EDIT}
        />,
      ]}
      onChangeCheckedRow={(rows: {id: number|string}[], cleanSelection) => (
        <DataTableBulksActions
          rows={rows}
          dataSource={dsWebinars}
          deleteResource={ResourceDataProviderEnum.webinar}
          duplicateResource={ResourceDataProviderEnum.webinarDuplicate}
          exportResource={ResourceDataProviderEnum.webinarExport}
          exportNameFile={exportNameFile}
          cleanSelection={cleanSelection}
        />
      )}
    >
      <SimpleColumn field="title" title="Webinaire" sortable />
      <ListColumn field="themes" fieldColumns="name" fieldKey="id" title="Thème" />
      <DateColumn field="date" title="Date" sortable />
      <SimpleColumn field="hour" title="Heure" sortable />
      <ListColumn field="formers" fieldColumns={['firstName', 'lastName']} fieldKey="id" title="Formateur" />
      <EditorColumn
        field="status"
        title="Status"
        resource={ResourceDataProviderEnum.webinarUpdateStatus}
        columnElement={SimpleColumn}
        inputElement={<SwitchField />}
        center
        sortable
        forceEditable
      />
    </DataTable>
  );
}
