import * as Yup from 'yup';

import React from 'react';

import { Form } from 'leeloo-react/component/form/Form';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useToast } from 'leeloo-react/component/hook/useToast';
import { Breadcrumb } from 'react-bootstrap';

import { ThematicForm } from './ThematicForm';
import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { BaseContainer } from '../../../layout/BaseContainer';

export function ThematicEditAddPage() {
  const { currentParamsRoute, goTo } = useRouter();
  const toast = useToast();
  const { goBack } = useRouter();

  return (
    <BaseContainer wrapperClassName="thematic">
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => goTo(RouteEnum.ADMIN_THEMATIC)}>
          Thématiques
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {`${currentParamsRoute?.id == null ? 'Ajouter' : 'Modifier'} une thématique`}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="h-100 p-5 text-white bg-dark rounded-3">
        <h4 className="pb-5">
          {`${currentParamsRoute?.id == null ? 'Ajouter' : 'Modifier'} une thématique`}
        </h4>
        <Form
          id={currentParamsRoute?.id}
          resource={ResourceDataProviderEnum.thematic}
          initialValue={{}}
          validationSchema={{
            ref: Yup.string().required('Champ obligatoire.'),
            name: Yup.string().required('Champ obligatoire.'),
            categories: Yup.array(
              Yup.object({
                category: Yup.object({
                  type: Yup.number().required('Champ obligatoire.').typeError('Champ obligatoire.'),
                  id: Yup.string().required('Champ obligatoire.').typeError('Champ obligatoire.'),
                }),
                position: Yup.number().typeError('Champ obligatoire.').required('Champ obligatoire.'),
              }),
            ).min(1, 'Champ obligatoire.'),
          }}
          onSubmitEndUpdate={() => {
            toast(`${currentParamsRoute?.id == null ? 'message.createSuccess' : 'message.updateSuccess'}`, 'success');
            goBack();
          }}
        >
          <ThematicForm />
        </Form>
      </div>
    </BaseContainer>
  );
}
