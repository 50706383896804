import React, { useState, useEffect, Fragment } from 'react';

import axios from 'axios';
import { Interweave } from 'interweave';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useToast } from 'leeloo-react/component/hook/useToast';
import Modal from 'react-bootstrap/Modal';
import Iframe from 'react-iframe';
import ReactPlayer from 'react-player/lazy';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import Placeholder from '../../assets/placeholder.png';
import Chart2 from '../../components/chartjs/Chart2';
import Hn from '../../components/common/Hn';
import { Spinner } from '../../components/common/Spinner';
import { BaseContainer } from '../../components/layout/BaseContainer';
import PopupVideo from '../../components/popup/PopupVideo';

import './DetailFormation.css';
import { RouteEnum } from '../../enum/RouteEnum';
import { UnitTypeEnum } from '../../enum/UnitTypeEnum';
import { PopupRating } from '../../components/popup/PopupRating';
import { useRatingModal } from '../../hooks/useRatingModal';

export default function DetailsFormationE({ disabled }) {
  const [unite, setUnite] = useState();
  const [contentUrl, setContentUrl] = useState(false);
  const [showModalBool, setShowModalBool] = useState(false);
  const [elearning, setElearning] = useState([]);
  const [validated, setValidated] = useState(0);
  const [nbUnites, setNbUnites] = useState(0);
  const [scoreds, setScoreds] = useState([]);
  const [play, setPlay] = useState([]);
  const [score, setScore] = useState();
  const [openDetails, setOpenDetails] = useState(false);
  const [playerCurrentTime, setPlayerCurrentTime] = useState(null);
  const [start, setDateStart] = useState(new Date());
  const [showLoader, setShowLoader] = useState(false);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const toast = useToast();
  const {ratingModal, hideRatingModal, showRatingModal} = useRatingModal();

  // scorm
  let scormScore = 0;
  let scormTemps = 0;
  let scormNbQuestions = 0;

  const IMG_URL = process.env.REACT_APP_PIC_ELEARNING_IMG;
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Mode utilisé si on souhaite démarrer le composant avec la modale PopupVideo ouverte
  const runMode = searchParams.has('run');

  const router = useRouter();

  const uri = process.env.REACT_APP_BASE_URL;

  // créer les données e-learning de l'utilisateur en question.  Le score par formation, la validation,le nombre d'unités et l'avancement...
  function getUserData() {
    const data = new FormData();
    data.append('user', authData.id);

    axios
      .get(
        `${uri}/watershed/elearning/${params.id}/${authData.id}`,
      )
      .then((result) => {
        setScore(result?.data?.score);
        setScoreds(result?.data?.scoreds);
        setPlay(result?.data?.play);
        setValidated(result?.data?.validated);
        setNbUnites(result?.data?.nb_unites);
      })
      .catch(() => {
        toast('Désolé cette formation n\'a pas de contenu', 'error');
        setTimeout(() => {
          navigate(RouteEnum.E_LEARNING);
        }, 1500);
      });
  }

  // récupérer l'elearning par id
  function getElearning() {
    axios
      .get(`${uri}/elearning/${params.id}`)
      .then((result) => {
        setElearning(result?.data);
      })
      .catch((err) => {
        toast('Erreur de récupération du contenu de la formation', 'error');
      });
  }

  const img = elearning?.image ? IMG_URL + elearning.image : Placeholder;

  const refreshData = () => {
    getUserData();
    getElearning();
  };

  useEffect(() => {
    getUserData();
    getElearning();
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (showModalBool) {
        scormScore = event.data?.detail?.score;
        scormNbQuestions = event.data?.detail?.nb_questions;

        if (event.data?.detail?.exit === 'oui' && typeof unite !== 'undefined') {
          scormTemps = 1;
          handleModalHide()
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [showModalBool]);

  const showModal = (url, unite) => {
    setUnite(unite);
    setContentUrl(url);
    scormScore = 0;
    scormTemps = 0;
    scormNbQuestions = 0;
    setShowModalBool(true);
  };

  const handleModalShow = () => {
    if (!unite?.duree || unite.duree === '00:00:00') {
      initWatershed();
    }
    // reset des states
    setPlayerCurrentTime(null);
    setDateStart(new Date());
  };

  const handleModalHide = async () => {
    setShowLoader(true);
    await Promise.all([
      saveTimer(),
      saveScore(),
    ]).then(() => {
      refreshData();
      setShowLoader(false);
      setShowModalBool(false);
      showRatingModal(elearning)
    });
  };

  function UniteApprentissageItem({ uniteApprentissage }) {
    const [open, setOpen] = useState(true);
    return (
      <div className="formation-ua">

        <div className="title">{uniteApprentissage.chapitre.titre}</div>
        <div className="resume">
          <div className="resume-duree">
            <span className="label">Durée</span>
            <strong>{uniteApprentissage.chapitre.duree}</strong>
          </div>
          <div className="resume-score">
            <span className="label">Score obtenu</span>
            <strong>{uniteApprentissage.score} %</strong>
          </div>
          <div className="resume-validation">
            <span className="label">Validation</span>
            {uniteApprentissage.chapitre_passed === true && (
              <img
                style={{ width: '20px' }}
                src={require('../../assets/icons/Valid.png')}
                alt=""
              />
            )}
            {uniteApprentissage.chapitre_passed === false && (
              <img
                style={{ width: '20px' }}
                src={require('../../assets/icons/NotValid.png')}
                alt=""
              />
            )}
          </div>
        </div>

        {uniteApprentissage?.unites?.map((unite, index) => (
          <Fragment key={unite.id}>
            <div className="line"
              onClick={() => showModal(unite?.extension == 'zip' ? `${process.env.REACT_APP_PIC_ELEARNING_SCORM}${unite?.scormdirectoryname}/story.html` : `${process.env.REACT_APP_PIC_ELEARNING_FILE}${unite?.document}`, unite)}>
              <div className="line-title">
                {unite.titre}
              </div>
              <div className="line-duree">
                <span className="label">Durée</span>
                <span className="value">{unite.duree && unite.duree !== '0' ? unite.duree : '-'}</span>
              </div>
              <div className="line-score">
                <span className="label">Score obtenu</span>
                <span className="value">{unite.score} %</span>
              </div>
              <div className="line-cta">
                {unite?.typology === UnitTypeEnum.VIDEO && (
                  <span>
                    Lancer la vidéo
                    <img style={{ width: '20px' }} className="unite_icone" src={require('../../assets/icons/Play.png')} alt="" />
                  </span>
                )}
                {unite?.typology === UnitTypeEnum.AUDIO && (
                  <span>
                    Lancer le fichier audio
                    <img style={{ width: '20px' }} className="unite_icone" src={require('../../assets/icons/Play.png')} alt="" />
                  </span>
                )}
                {unite?.typology === UnitTypeEnum.DOCUMENT && (
                  <span>
                    Ouvrir le document PDF
                    <img style={{ width: '20px' }} className="unite_icone" src={require('../../assets/icons/MenuMobile.png')} alt="" />
                  </span>
                )}
                {unite?.typology === UnitTypeEnum.SCORM && (
                  <span>
                    Ouvrir le document
                    <img style={{ width: '20px' }} className="unite_icone" src={require('../../assets/icons/MenuMobile.png')} alt="" />
                  </span>
                )}
              </div>
              <div className="line-validation">
                <span className="label">Validation</span>
                {unite?.chapitre_passed === true && (
                  <img
                    style={{ width: '20px' }}
                    src={require('../../assets/icons/Valid.png')}
                    alt=""
                  />
                )}
                {unite?.chapitre_passed === false && (
                  <img
                    style={{ width: '20px' }}
                    src={require('../../assets/icons/NotValid.png')}
                    alt=""
                  />
                )}
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    );
  }

  const initWatershed = () => {
    const data = new FormData();
    const verb = 'start';

    data.append('verb', verb);
    data.append('user_email', authData.username);
    data.append('unite', unite?.id);

    axios
      .post(`${uri}/watershed/new`, data)
      .catch((err) => {
        console.log('err data', err)
        toast('Erreur de récupération des données sur la formation', 'error');
      });
  };

  const saveTimer = async () => {
    const now = new Date();
    const trackingDuration = now.getTime() - start.getTime();
    const passedTime = playerCurrentTime ?? Math.round(trackingDuration / 1000);

    const data = new FormData();
    data.append('email', authData.username);
    data.append('unite', unite?.id);
    data.append('duree', passedTime);

    return await axios
      .post(`${uri}/player_resume/new`, data)
      .catch((err) => {
        console.log('err', err);
        toast('Erreur de mise à jour des temps passés sur la formation', 'error');
      });
  };

  const saveScore = async () => {
    const data = new FormData();
    if (unite?.scormdirectoryname !== null) {
      if (scormTemps === 0) {
        return;
      }
      // validation scorm
      data.append('scorm_score', scormScore);
      data.append('scorm_nb_questions', scormNbQuestions);
    }

    const verb = 'checkin';

    data.append('user_email', authData.username);
    data.append('unite', unite?.id);
    data.append('unite_passed', true);
    data.append('verb', verb);

    return await axios
      .post(`${uri}/watershed/new`, data)
      .catch((err) => {
        toast('Erreur de mise à jour des scores sur la formation', 'error');
      });
  };

  return (
    <BaseContainer>
      <nav aria-label="breadcrumb">
        <div className="d-none d-md-block">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link rel="opener" to="/Formations-e-learning">
                Formations e-learning
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {elearning?.intitule}
            </li>
          </ol>
        </div>
        <div>
          <span className="look-like-link resetBtn" onClick={() => router.goBack()}>
            <svg viewBox="0 0 24 24" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 11H9.41l3.3-3.29a1.004 1.004 0 0 0-1.42-1.42l-5 5a1 1 0 0 0-.21.33 1 1 0 0 0 0 .76 1 1 0 0 0 .21.33l5 5a1.002 1.002 0 0 0 1.639-.325 1 1 0 0 0-.22-1.095L9.41 13H17a1 1 0 0 0 0-2Z" fill="#006FFF"/>
            </svg>
            Retour
          </span>
          <Modal
            show={showModalBool}
            onShow={handleModalShow}
            onHide={handleModalHide}
            className="videomodal bg-black"
            backdrop="static"
            fullscreen
          >
            {showLoader && <Spinner />}
            <Modal.Header closeButton>
              <div className="unite-header">
                <div>
                  <div className="title">{unite?.elearning_titre}</div>
                  <div className="sub-title">{`${unite?.chapitre_titre} | ${unite?.titre}`}</div>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div id="content_player" className="text-center">
                {(unite?.extension == 'mp3' || unite?.extension == 'mp4' || unite?.extension == 'webm') && (
                  <ReactPlayer
                    id="mp4-player"
                    url={contentUrl}
                    playing
                    controls
                    onProgress={(progress) => {
                      setPlayerCurrentTime(progress.playedSeconds);
                    }}
                  />
                )}

                {unite?.extension == 'zip' && (
                  <Iframe
                    url={contentUrl}
                    id="scorm_content"
                    display="initial"
                    position="relative"
                    className="vidIframe"
                  />
                )}

                {unite?.extension == 'pdf' && (
                  <object
                    data={contentUrl}
                    type="application/pdf"
                  >
                    <p>
                      Erreur de chargement, vous
                      pouvez télécharger le PDF
                      <a
                        href={contentUrl}
                        className="ml-1"
                      >
                        ici
                      </a>
                    </p>
                  </object>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </nav>
      <div className="row mb-4">
        <div className="col-md-9">
          <h1 className="pt-4">{elearning?.intitule}</h1>
          {elearning?.associationRoute?.id && (
            <p className="text text-white">
              {'Parcours associé : '}
              <Link to={`/${elearning.associationRoute.type === 'elearning' ? 'detailformations' : 'detailformation'}/${elearning.associationRoute.id}`}>
                {elearning.associationRoute.title}
              </Link>
            </p>
          )}
          {(elearning.obligation_de_formation || elearning.manager) && (
            <p className="d-flex gap-4 flex-wrap">
              {elearning.obligation_de_formation && (
                <span>Participe à l’obligation de formation</span>)}
              {elearning.manager && (
                <span>Spécifique au manager</span>)}
            </p>
          )}
        </div>
      </div>
      <div className="formation-header">
        <div className="row">
          <div className="col-3 d-none d-xl-block">
            <img
              src={img}
              alt={`Image de la formation ${elearning?.intitule}`}
              className="mb-3"
            />
            {!runMode && <PopupVideo
              play={play}
              handleClose={refreshData}
              className="btn btn-primary w-100 pt-3 pb-3 fw-bold text-uppercase"
            />}
          </div>
          <div className="col-xl-9 col-lg-12">
            <div className="row">
              <div className="d-xl-none col-md-5 col-lg-4 mb-4 mb-md-0">
                <img
                  src={img}
                  alt={`Image de la formation ${elearning?.intitule}`}
                  className="mb-3"
                />
                {!runMode && <PopupVideo
                  play={play}
                  handleClose={refreshData}
                  className="btn btn-primary w-100 pt-3 pb-3 fw-bold text-uppercase"
                />}
              </div>
              <div className="col-md-7 col-lg-8 col-xl-12">
                <div className="row">
                  <div className="col-xl-7">
                    <h4>Objectifs</h4>
                    {elearning?.objectifs && (
                      <div className="simpletext mb-3">
                        <Interweave content={elearning.objectifs}/>
                      </div>
                    )}
                  </div>
                  <div className="col-xl-5">
                    <div className="progress-wrapper">
                      <Chart2
                        title={`${validated}/${nbUnites}`}
                        invalidated={100 - (validated * 100) / nbUnites}
                        validated={(validated * 100) / nbUnites}
                      />
                      {`Il reste ${parseInt(nbUnites, 10) - parseInt(validated, 10)} unité(s) d'apprentissage`}
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <hr className="my-4" />

            <div className="row cards-grid">
              <div className="col-6 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Durée</h5>
                    <p className="card-text">{elearning?.duree}{' '}min</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Nombres d'unités
                    </h5>
                    <p className="card-text">{nbUnites}</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Validation</h5>
                    <p className="card-text">{elearning?.validation}%</p>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Score actuel</h5>
                    <p className="card-text">{score}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black p-4 mb-4">
        <div className="flex align-items-center gap-2 justify-content-between">
          <strong>Détails de la formation</strong>
          <span
            className="look-like-link"
            onClick={() => setOpenDetails(!openDetails)}
          >
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.516 15.548c.436-.446 1.043-.481 1.576 0L18 19.295l3.908-3.747c.533-.481 1.14-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0s-4.287-4.084-4.695-4.502c-.408-.418-.436-1.17 0-1.615Z" fill="#CCC"/></svg>
          </span>
        </div>
        <div
          className="accordion"
          style={{ display: openDetails ? 'block' : 'none' }}
        >
          <div className="pt-4">
            {elearning?.prerequis && (
              <>
                <h5 className="mb-2">Prérequis et public visé</h5>
                <div className="simpletext py-1 text-white">
                  <Interweave content={elearning.prerequis}/>
                </div>
              </>
            )}

            {(elearning?.programme_de_formation || elearning.fichier_programme) && (
              <>
                <div className="flex flex-column flex-lg-row gap-2 justify-content-lg-between">
                  <h5 className="mb-2 mt-3">Programme</h5>
                  <div>
                    {elearning.fichier_programme && (
                      <a
                        href={process.env.REACT_APP_PIC_ELEARNING_FILE + elearning.fichier_programme}
                        target="_blank"
                        className="donwload mb-2 mt-3"
                        rel="noreferrer"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                        </svg>
                        Télécharger le programme
                      </a>
                    )}
                  </div>
                </div>
                {elearning.programme_de_formation && (
                  <div className="simpletext py-1 text-white">
                    <Interweave content={elearning.programme_de_formation}/>
                  </div>
                )}
              </>
            )}

            {elearning.commentaire && (
              <>
                <h5 className="mb-2 mt-3">Commentaire</h5>
                <div className="simpletext py-1 text-white">
                  <Interweave content={elearning.commentaire}/>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`more-info ${disabled}`} />
      </div>

      {scoreds?.map((scored, i) => (
        <UniteApprentissageItem
          uniteApprentissage={scored}
          key={i}
        />
      ))}

      <PopupRating show={ratingModal} onHide={hideRatingModal} elearning={elearning} />

      {runMode && <PopupVideo
        play={play}
        run
        handleClose={refreshData}
        className="btn btn-primary w-100 pt-3 pb-3 fw-bold text-uppercase"
      />}

    </BaseContainer>
  );
}
