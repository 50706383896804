import React, { useContext } from 'react';

import get from 'get-value';
import { ContextForm } from 'leeloo-react/component/form/Form';
import { FieldContainerGroup } from 'leeloo-react/component/form/group/FieldContainerGroup';
import { useTrans } from 'leeloo-react/component/hook/useTrans';
import { FieldFormType } from 'leeloo-react/model/form/FieldFormType';
import { Form, FormControl } from 'react-bootstrap';

/**
 * Composant visuel pour une case a cocher ou un bouton radio
 * @param props
 * @returns
 */
export function SwitchField<T>(props: FieldFormType<T>) {
  const {
    form, onChangeFieldsValues, isLoad, errors,
  } = useContext(ContextForm);
  const value: any = (props.name) ? form.getFieldProps(props.name as string).value : props.value;
  const { t } = useTrans();

  const errorFound = (props.name !== null && get(errors, props.name as string) && Object.keys(get(errors, props.name as string)).length > 0);
  const className = (props.className) ? props.className : '';

  const onToggle = () => {
    if (!isLoad || props.disabled) {
      const newValue = (value !== null) ? !value : true;
      // update form
      if (props.name) onChangeFieldsValues({ [props.name]: newValue });
      if (props.onChangeValue) props.onChangeValue(newValue);
    }
  };

  return (
    <FieldContainerGroup<T> {...props} className={(errorFound) ? `${className} has-danger` : className}>
      <Form.Check
        type="switch"
        id={`custom-switch-${props.name as string}`}
        label={props.label ? t(props.label) : null}
        name={props.name as string}
        disabled={props.disabled || isLoad}
        checked={value ?? false}
        onChange={onToggle}
      />
      {errorFound
                && (
                  <FormControl.Feedback type="invalid">
                    {get(errors, props.name as string)}
                  </FormControl.Feedback>
                )}
    </FieldContainerGroup>
  );
}
