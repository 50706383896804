import React, { ReactElement } from 'react';

import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../enum/RouteEnum';
import { ThematicAbstractPage } from './ThematicAbstractPage';

export function ThematicProfilePage(): ReactElement {

  return (
    <ThematicAbstractPage
      resource={ResourceDataProviderEnum.eLearningMyProfile}
      parent={{title: 'Formations e-learning', route : RouteEnum.E_LEARNING}}
      title ='Pour votre profil'
      flip={false}
    ></ThematicAbstractPage>
  );
}
