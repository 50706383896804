import React, { ReactElement } from 'react';

import { TrainingRequestDataTable } from './TrainingRequestDataTable';
import { BaseContainer } from '../../../layout/BaseContainer';

export function TrainingRequest(): ReactElement {
    return (
        <BaseContainer>
            <div className="lists-title">
                <h1>Formations présentielles</h1>
                <h2 className="mt-4 plus">Besoins en formation</h2>
            </div>
            <div className="pt-5">
                <TrainingRequestDataTable />
            </div>
        </BaseContainer>
    );
}
