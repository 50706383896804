import React from 'react';

import {
  ArcElement, Chart as ChartJS, ChartDataset, Color, Legend, Tooltip,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

type Props = {
    label?: string,
    backgroundColor?: Color[],
    borderColor?: Color[],
    borderWidth?: number,
    labels?: string[],
    data?: number[],
    datasets?: ChartDataset<'doughnut', (number | [number, number] | null)[]>[]
}

export default function DoughnutChart(props: Props) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: props.labels?.map((item) => item) ?? [],
    datasets: props.datasets ?? [
      {
        label: props.label,
        data: props.data,
        backgroundColor: props.backgroundColor,
        borderColor: props.borderColor,
        borderWidth: props.borderWidth,
      },
    ],
  };
  return (
    <Doughnut data={data} />
  );
}

DoughnutChart.defaultProps = {
  data: null,
  label: null,
  labels: null,
  backgroundColor: null,
  borderColor: null,
  borderWidth: 1,
  datasets: null,
};
