import React, { useRef } from 'react';

import { SelectField } from 'leeloo-react/component/form/field/SelectField';
import { useDataProvider } from 'leeloo-react/component/hook/useDataProvider';
import { DataSourceType } from 'leeloo-react/component/hook/useDataSource';
import { useModal } from 'leeloo-react/component/hook/useModal';
import { useToast } from 'leeloo-react/component/hook/useToast';

import { PresenceEnum } from '../../enum/PresenceEnum';

type Props<T> = {
    rows: (T & {id: any})[],
    resource: string,
    fieldName: string,
    label?: string,
    dataSource?: DataSourceType,
}

export function UpdatePresence<T>(props: Props<T>) {
  const dp = useDataProvider();
  const valueRef = useRef();
  const toast = useToast();
  const { confirm } = useModal();

  const onChange = async () => {
    const response = await confirm('Confirmez-vous la mise à jours de ces éléments ?');
    if (response) {
      const promises = (props.rows).map((row) => dp.update!(props.resource, { id: row.id, data: { id: row.id, [props.fieldName]: valueRef.current } }));
      return Promise.all(promises).then(() => {
        if (props.dataSource) {
          props.dataSource.refreshData();
        }
        toast('Mise à jours effectuée avec avec succès', 'success');
      });
    }
    return null;
  };
  return (
    <SelectField
      isClearable={false}
      placeholder={props.label}
      optionsEnum={PresenceEnum}
      onChangeValue={(v: any) => {
        valueRef.current = v;
        onChange();
      }}
    />
  );
}

UpdatePresence.defaultProps = {
  dataSource: null,
  label: 'Présence',
};
