import React from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { DataSourceType } from 'leeloo-react/component/hook/useDataSource';
import { Dropdown } from 'react-bootstrap';

import { useDtAction } from '../../hook/useDtAction';

type Props<T> = {
    row: T & {id: any,
        status: string,
        previousStatus: string,
        canValidate: boolean,
        canArchive: boolean,
        canUnarchive: boolean,
    },
    dataSource?: DataSourceType,
    validateResource?: string,
    archiveResource?: string,
    unarchiveResource?: string,
}

export function DataTableTrainingRequestListActions(props: Props<any>) {
    const { validateStatus, archiveStatus, unarchiveStatus } = useDtAction();

    let titleActions: JSX.Element | null = (
        <Dropdown.Toggle variant="outline-primary">
            Action
        </Dropdown.Toggle>
    );

    let actions: JSX.Element | null = (
        <Dropdown.Menu>
            {props.validateResource && props.row && props.dataSource && (
                <Dropdown.Item
                    className={props.row.canValidate ? '' : 'dropdown-disabled'}
                    onClick={() => validateStatus({
                        row: props.row,
                        resource: props.validateResource ?? '',
                        dataSource: props.dataSource,
                    })}
                >
                    <Icon name="uil uil-check" additionalClasses="me-1" />
                    Confirmer
                </Dropdown.Item>
            )}
            {props.archiveResource && props.row && props.dataSource && (
                <Dropdown.Item
                    className={props.row.canArchive ? '' : 'dropdown-disabled'}
                    onClick={() => archiveStatus({
                        row: props.row,
                        resource: props.archiveResource ?? '',
                        dataSource: props.dataSource,
                    })}
                >
                    <Icon name="uil uil-file-export" additionalClasses="me-1" />
                    Archiver
                </Dropdown.Item>
            )}
            {props.unarchiveResource && props.row && props.dataSource && (
                <Dropdown.Item
                    className={props.row.canUnarchive ? '' : 'dropdown-disabled'}
                    onClick={() => unarchiveStatus({
                        row: props.row,
                        resource: props.unarchiveResource ?? '',
                        dataSource: props.dataSource,
                    })}
                >
                    <Icon name="uil uil-file-import" additionalClasses="me-1" />
                    Désarchiver
                </Dropdown.Item>
            )}
        </Dropdown.Menu>
    );

    if (!props.row.canValidate && !props.row.canArchive && !props.row.canUnarchive) {
        titleActions = null;
        actions = null;
    }

    return (
        <Dropdown key={0}>
            {titleActions}
            {actions}
        </Dropdown>
    );
}

DataTableTrainingRequestListActions.defaultProps = {
    dataSource: null,
    validateResource: null,
    archiveResource: null,
    unarchiveResource: null,
};
