import * as Yup from 'yup';

import React, { Fragment, ReactElement, useState } from 'react';

import { SubmitBtn } from 'leeloo-react/component/form/action/SubmitBtn';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { Form } from 'leeloo-react/component/form/Form';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useToast } from 'leeloo-react/component/hook/useToast';
import { useParams } from 'react-router-dom';

import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { UnitTypeEnum } from '../../../../enum/UnitTypeEnum';
import { UserDto } from '../../../../model/dto/UserDto';
import { BaseContainer } from '../../../layout/BaseContainer';

export function UpdateScore(): ReactElement {
  const params = useParams();
  const router = useRouter();
  const [formScores, setFormScores] = useState<any>({});
  const toast = useToast();

  // Récupération des différentes info de l'utilisateur
  const { oneData: user } = useDataSource<UserDto>({
    resource: ResourceDataProviderEnum.learner,
    singleResult: true,
    params: {
      id: params.user,
    },
  });

  // Récupération des différentes info du eLearning
  const { oneData: eLearning } = useDataSource<any>({
    resource: ResourceDataProviderEnum.eLearning,
    singleResult: true,
    params: {
      id: params.id,
    },
  });

  // Récupération des chapitres / unités de formations / scores
  const { oneData: scores, refreshData: refreshScores } = useDataSource<any>({
    resource: router.generatePath(ResourceDataProviderEnum.eLearningUserScores, ResourceDataProviderEnum.eLearningUserScores, { id: params.id, contact: params.user }),
    singleResult: true,
    params: {},
  });

  // Mise à jours des inputs scores dans un state
  const handleUpdateScore = (value: number, unitId: string) => {
    setFormScores((prevState: any) => ({
      ...prevState,
      [unitId]: value,
    }));
  };

  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Mise à jour des scores</h1>
        <h2 className="mt-4 plus">{`${user?.contactPrenom} ${user?.contactNom}`}</h2>
      </div>
      <div className="head-details mt-5">
        <div className="row">
          <div className="col-md-9">
            <h3>{eLearning?.intitule}</h3>
            <span className="look-like-link resetBtn" onClick={() => router.goBack()}>Retour</span>
          </div>
        </div>
      </div>
      <div className="body-details p-3">
        <div className="row pt-4 pe-2">
          <div className="col-lg-3 col-md-6 ">
            <div className="card text-white bg-primary mb-3 text-center">
              <div className="card-body">
                <h5 className="card-title">Durée</h5>
                <p className="card-text">
                  {`${eLearning?.duree} min`}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <div className="card text-white bg-primary mb-3 text-center">
              <div className="card-body">
                <h5 className="card-title">
                  Nombres d&apos;unités
                </h5>
                <p className="card-text">
                  {scores && scores.scoreds && scores.scoreds.reduce((currentCount: number, row: any) => currentCount + row.unites.length, 0)}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <div
              className="card text-white bg-primary mb-3 text-center"
            >
              <div className="card-body">
                <h5 className="card-title">Validation</h5>
                <p className="card-text">
                  {`${eLearning?.validation}%`}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <div
              className="card text-white bg-primary mb-3 text-center"
            >
              <div className="card-body">
                <h5 className="card-title">
                  Score actuel
                </h5>
                <p className="card-text">
                  {`${scores?.score}% `}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form
        resource={ResourceDataProviderEnum.eLearningScore}
        initialValue={{
          user: params.user,
          e_learning: params.id,
        }}
        validationSchema={{
          user: Yup.string().required(),
          e_learning: Yup.string().required(),
        }}
        onSubmit={async (d: any) => {
          // On POST nos scores sous l'appelation "scores"
          d.scores = formScores;
        }}
        onSubmitEndUpdate={() => {
          // on refresh notre datasource
          refreshScores();
          // on reset notre state
          setFormScores({});
          // Tout s'est bien passé on affiche le toaster
          toast('Le score a bien été mis à jour', 'success');
        }}
      >
        <div className="table pt-5">
          <table className="table table-dark table-hover">
            <thead>
              <tr className="tr">
                <th>Unité d&apos;apprentissage</th>
                <th>Durée</th>
                <th>Score obtenu</th>
              </tr>
            </thead>
            <tbody>
              {scores && scores?.scoreds != null && scores.scoreds.map((item: any) => (
                <Fragment key={item?.chapitre.id}>
                  <tr>
                    <td>{item?.chapitre.titre}</td>
                    <td>{item?.chapitre.duree}</td>
                    <td>{`${item?.score}%`}</td>
                  </tr>
                  {item?.unites != null && item.unites.map((unite: any) => (
                    <tr key={unite.id}>
                      <td className="ps-4">{unite.titre}</td>
                      <td className="ps-4">{unite.duree && unite.duree !== '0' ? unite.duree : '-'}</td>
                      <td className="ps-4">
                        <InputField
                          type="number"
                          min="0"
                          max="100"
                          step={unite.typology === UnitTypeEnum.DOCUMENT ? 100 : 1}
                          value={formScores[unite.id] ?? unite.score}
                          onChangeValue={(v: number) => handleUpdateScore(v, unite.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end">
          <SubmitBtn className="btn btn-primary">
            Valider
          </SubmitBtn>
        </div>
      </Form>

    </BaseContainer>
  );
}
