import React, { useEffect, useState } from 'react';

import axios from 'axios';
import dateFormat from 'dateformat';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import {
  useParams,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import UniteApprentissage from './UniteApprentissage';
import Hn from '../../../../../components/common/Hn';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';
import { RouteEnum } from '../../../../../enum/RouteEnum';

import 'react-toastify/dist/ReactToastify.css';
import '../session.css';
import './createFaceTraining.css';

import { FormationTags } from '../../../../../components/admin/FormationTags';


function UpdateSession() {
  const [session, setSession] = useState([]);
  const [formateurs, setFormateurs] = useState([]);
  const [lieuFormation, setLieuForamtion] = useState([]);
  const [training, setTraining] = useState([]);
  const { goTo } = useRouter();
  const params = useParams();
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  let toastId = null;
  const uri = process.env.REACT_APP_BASE_URL;

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return 'danger';
    }
    if (value) {
      return 'info';
    }
    return 'warning';
  };

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: 'my_toast',
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log('Toast already active');
    }
  }

  const createLesson = () => {
    const total = session?.lessons.length ?? 0;

    const data = {
      capacityMin: session?.seat_capacity_min || '0',
      capacityMax: 20,
      hourStartMorning: '09:30',
      hourEndMorning: '12:00',
      hourStartmidi: '14:00',
      hourEndMidi: '18:00',
      former: session?.former?.id,
      name: `Unité d’apprentissage ${total}`,
      organize: session?.former?.organize,
      location: session?.location?.id,
      date: dateFormat(addDays(session.dateStart, total), 'yyyy-mm-dd') || '',
      session: session.id,
    };

    axios
      .post(`${uri}/lesson/new`, data)
      .then(() => {
        // on refresh la liste des lessons
        // (on est obligé de refresh les sessions, il n'y a pas de endpoints sur les unités de formation)
        getUnitySession();
      })
      .catch(() => {
        console.log('error');
      });
  };

  const handleSubmit = (values) => {
    axios
      .put(`${uri}/session/edit/${params.id}`, values)
      .then(() => {
        displayToast('Csuccess', 'Modification effectuée avec succès.');
        goTo(RouteEnum.ADMIN_SESSION);
      })
      .catch((err) => {
        console.log('err bd', err);
        displayToast('Cerror', "Quelque chose s'est mal passé, Réessayer");
      });
  };

  // Récupère la liste des formateurs
  function getFormer(type) {
    let url = '/former';
    if (type) {
      url = `/former/organize/${type}`;
    }
    axios
      .get(uri + url)
      .then((result) => {
        if (type) {
          setFormateurs(result?.data);
        } else {
          setFormateurs(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log('err formateurs', err);
      });
  }

  const getLocation = () => {
    axios
      .get(`${uri}/location`)
      .then((result) => {
        setLieuForamtion(result?.data?.data);
      })
      .catch((err) => {
        console.log('err location', err);
      });
  };

  const getTraining = () => {
    axios
      .get(`${uri}/training`)
      .then((result) => {
        setTraining(result?.data?.data);
      })
      .catch((err) => {
        console.log('err training', err);
      });
  };

  const getUnitySession = () => {
    axios
      .get(`${uri}/session/${params.id}`)
      .then((result) => {
        setSession(result?.data);
      })
      .catch((err) => {
        console.log('err ', err);
      });
  };

  const changeList = (value) => {
    getFormer(value);
  };

  useEffect(() => {
    getUnitySession();
    getLocation();
    getTraining();
    getFormer(session?.former?.organize);
  }, []);

  /**
   * Met à jour les tags à la fermeture de la modale d'édition de tags
   * @param newTags
   */
  const updateTags = (newTags) => {
    setSession((session)=>({...session,tags:newTags}))
  }

  return (
    <BaseContainer>
      <div className="banner-provider container-fluid session1">
        <ToastContainer />
        <div className="row pe-3">
          <div className=" container lists pe-4">
            <nav aria-label="breadcrumb pb-3">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <RouterLink routeName={RouteEnum.ADMIN_SESSION}>
                    Formations présentielles - session de formation
                  </RouterLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {`Modification de la session : ${session.ref}`}
                </li>
              </ol>
            </nav>
            <div className="row mt-30 mb-135">
              <div className="col-12">
                <div className="h-100 p-5 text-white bg-dark rounded-3">
                  <Hn
                    title={`Modification de la session : ${session.ref}`}
                    level="h4"
                    className="h4-size pb-5"
                    plus={false}
                  />
                  <Hn
                    title="Informations générales"
                    level="h5"
                    className="h4-size pb-5"
                    plus={false}
                  />
                  <div className="form-elements">
                    <div className="g-3">
                      <Formik
                        initialValues={{
                          ref: session?.ref || '',
                          training: session?.training?.id,
                          dateStart: dateFormat(session?.dateStart, 'yyyy-mm-dd') || '',
                          dateEnd: dateFormat(session?.dateEnd, 'yyyy-mm-dd') || '',
                          location: session?.location?.id || '',
                          seat_capacity_min: session?.seat_capacity_min || '0',
                          seat_capacity_max: session?.seat_capacity_max || '0',
                          former: session?.former?.id,
                          organize: session?.former?.organize,
                          tags: session?.tags
                        }}
                        onSubmit={handleSubmit}
                        enableReinitialize
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        }) => (
                          <form className="row g-3" onSubmit={handleSubmit}>

                            <div className="col-md-8">
                              <label className="form-label">
                                Action de formation
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="training"
                                value={values.training || session?.training?.id}
                                onChange={handleChange('training')}
                                onBlur={handleBlur('training')}
                              >

                                {training.map((train) => (
                                  <option value={train.id} key={train.id}>
                                    {train?.title}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage name="training">
                                {(msg) => <div className="text-danger">{msg}</div>}
                              </ErrorMessage>
                            </div>
                            <FormationTags className="col-md-4" tags={session.tags} updateTags={updateTags}></FormationTags>
                            <div className="col-md-4">
                              <label className="form-label">Référence*</label>
                              <input
                                status={onHandelStatus(
                                  touched.ref,
                                  errors.ref,
                                  values.ref,
                                )}
                                type="text"
                                className="form-control"
                                id="ref"
                                placeholder="Référence"
                                onChange={handleChange('ref')}
                                value={values.ref}
                                onBlur={handleBlur('ref')}
                                disabled
                              />
                              <ErrorMessage name="ref">
                                {(msg) => <div className="text-danger">{msg}</div>}
                              </ErrorMessage>
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">
                                Date de début
                              </label>
                              <input
                                status={onHandelStatus(
                                  touched.dateStart,
                                  errors.dateStart,
                                  values.dateStart,
                                )}
                                type="date"
                                className="form-control"
                                name="dateStart"
                                id="dateStart"
                                value={values?.dateStart}
                                min={dateFormat(session?.dateStart, 'dd/mm/yyyy')}
                                max={values.dateEnd}
                                onChange={handleChange('dateStart')}
                                onBlur={handleBlur('dateStart')}
                              />
                              <ErrorMessage name="dateStart">
                                {(msg) => <div className="text-danger">{msg}</div>}
                              </ErrorMessage>
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">Date de fin</label>
                              <input
                                status={onHandelStatus(
                                  touched.dateEnd,
                                  errors.dateEnd,
                                  values.dateEnd,
                                )}
                                type="date"
                                className="form-control"
                                id="dateEnd"
                                placeholder="Date de fin"
                                onChange={handleChange('dateEnd')}
                                value={values.dateEnd}
                                min={values.dateStart}
                                onBlur={handleBlur('dateEnd')}
                              />
                              <ErrorMessage name="dateEnd">
                                {(msg) => <div className="text-danger">{msg}</div>}
                              </ErrorMessage>
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">Lieu de formation*</label>
                              <select
                                status={onHandelStatus(
                                  touched.location,
                                  errors.location,
                                  values.location,
                                )}
                                className="form-select"
                                aria-label="Default select example"
                                name="location"
                                id="location"
                                onChange={handleChange('location')}
                                onBlur={handleBlur('location')}
                                value={values.location}
                              >
                                <option>sélectionner dans la liste</option>
                                {lieuFormation.map((lieu) => (
                                  <option value={lieu.id} key={lieu.id}>
                                    {`${lieu?.name} - ${lieu?.address}`}
                                  </option>
                                ))}
                              </select>
                              <ErrorMessage name="location">
                                {(msg) => <div className="text-danger">{msg}</div>}
                              </ErrorMessage>
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">
                                Capacité d'accueil minimale
                              </label>
                              <input
                                status={onHandelStatus(
                                  touched.seat_capacity_min,
                                  errors.seat_capacity_min,
                                  values.seat_capacity_min,
                                )}
                                type="number"
                                className="form-control"
                                id="seat_capacity_min"
                                // max={values.seat_capacity_max}
                                placeholder="00"
                                onChange={handleChange('seat_capacity_min')}
                                value={values.seat_capacity_min}
                                onBlur={handleBlur('seat_capacity_min')}
                              />
                              <ErrorMessage name="seat_capacity_min">
                                {(msg) => <div className="text-danger">{msg}</div>}
                              </ErrorMessage>
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">
                                Capacité d'accueil maximale*
                              </label>
                              <input
                                status={onHandelStatus(
                                  touched.seat_capacity_max,
                                  errors.seat_capacity_max,
                                  values.seat_capacity_max,
                                )}
                                type="number"
                                className="form-control"
                                id="seat_capacity_max"
                                placeholder="00"
                                // min={values.seat_capacity_min}
                                onChange={handleChange('seat_capacity_max')}
                                value={values.seat_capacity_max}
                                onBlur={handleBlur('seat_capacity_max')}
                              />
                              <ErrorMessage name="seat_capacity_max">
                                {(msg) => <div className="text-danger">{msg}</div>}
                              </ErrorMessage>
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">
                                Formateur
                              </label>
                              <select
                                className="form-select"
                                onChange={(event) => {
                                  setFieldValue('organize', event.target.value);
                                  changeList(event.target.value);
                                }}
                                value={values.organize}
                              >
                                <option>sélectionner dans la liste</option>
                                <option value="interne">Interne</option>
                                <option value="externe">Externe</option>
                              </select>
                            </div>
                            <div className="col-md-4">
                              <label className="form-label">&nbsp;</label>
                              <select
                                className="form-select"
                                name="former"
                                value={values.former || session?.former?.id}
                                onChange={handleChange('former')}
                                onBlur={handleBlur('former')}
                              >
                                <option>
                                  sélectionner dans la liste
                                </option>
                                {formateurs.map((former) => (
                                  <option value={former.id} key={former.id}>
                                    {former?.lastName}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-12 mt-5">
                              <RouterLink routeName={RouteEnum.ADMIN_SESSION} className="btn btn-link text-decoration-none resetBtn">
                                Annuler
                              </RouterLink>
                              <button
                                type="submit"
                                className="btn btn-primary float-end"
                              >
                                Valider
                              </button>

                            </div>
                          </form>
                        )}
                      </Formik>
                      <div>
                        <div className="d-flex pb-5 pt-5 align-items-center">
                          <Hn
                            title="Unités de formation"
                            level="h4"
                            className="h4-size"
                            plus={false}
                          />
                          <span className="ms-3 look-like-link fw-bold" onClick={createLesson}>
                            Ajouter une unité
                          </span>
                        </div>
                        <div className="row pt-5">
                          {session?.lessons?.map((item, index) => (
                            <UniteApprentissage
                              session={session}
                              nbUnite={index}
                              lieuFormation={lieuFormation}
                              lesson={item}
                              key={item.id}
                              handleClose={getUnitySession}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
}
export default UpdateSession;
