import React, { ReactElement, useEffect } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { ProgressBar } from 'react-bootstrap';

import { ResourceDataProviderEnum } from '../../../../../enum/ResourceDataProviderEnum';
import { ELearningStatsType } from '../../../../../model/kpis/ELearningStatsType';
import { KpiType } from '../../../../../model/kpis/KpiType';
import DoughnutChart from '../../../../chart/DoughnutChart';
import { RouterCustomFileLink } from '../../../../router/RouterCustomFileLink';

export function KpiELearning({ dateRange }: KpiType): ReactElement {
  const dsELearnings = useDataSource<ELearningStatsType>({
    resource: ResourceDataProviderEnum.kpisELearnings,
    singleResult: true,
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (dateRange) {
      const params = {
        uriParameters: {
          startAt: dateRange?.startAt || null,
          endAt: dateRange?.endAt || null,
        },
      };

      dsELearnings.setParams(params);
    }
  }, [dateRange]);

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className="p-3 bg-dark-grey text-center">
            <h4>Nombre de connexions</h4>
            <p>{dsELearnings?.oneData?.stats?.nb_connexions}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="p-3 bg-dark-grey text-center">
            <h4>Nombre d&apos;utilisateurs</h4>
            <p>{dsELearnings?.oneData?.stats?.nbUsers}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="p-3 bg-dark-grey text-center">
            <h4>Temps global passé sur la plateforme</h4>
            <p>{dsELearnings?.oneData?.stats?.temps_total}</p>
          </div>
        </div>
      </div>
      <div className="mt-3 bg-dark-grey text-center p-4">
        <div className="d-flex justify-content-between">
          <RouterCustomFileLink
            resource={ResourceDataProviderEnum.kpisELearningsExport}
            params={{
              data: {
                startAt: dateRange?.startAt || null,
                endAt: dateRange?.endAt || null,
              },
            }}
            nameFile="dashboard_elearning.csv"
            className="btn btn-outline-primary square top-0 end-0 export-button-right"
          >
            <Icon name="uil uil-export" />
          </RouterCustomFileLink>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-7 col-xs-6 text-white border-end">
            <div className="row align-items-center">
              <div className="col-lg-3">
                <p>En cours:</p>
              </div>
              <div className="col-lg-5">
                <ProgressBar variant="warning" now={dsELearnings.oneData?.stats.enCoursGlobal.percentUnformatted} />
              </div>
              <div className="col-lg-4">
                {`${dsELearnings.oneData?.stats.enCoursGlobal.nb} apprenants (${dsELearnings.oneData?.stats.enCoursGlobal.percent} %)`}
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <p>Non validé:</p>
              </div>
              <div className="col-lg-5">
                <ProgressBar variant="danger" now={dsELearnings.oneData?.stats.nonValideGlobal.percentUnformatted} />
              </div>
              <div className="col-lg-4">
                {`${dsELearnings.oneData?.stats.nonValideGlobal.nb} apprenants (${dsELearnings.oneData?.stats.nonValideGlobal.percent} %)`}
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-3">
                <p>Validé:</p>
              </div>
              <div className="col-lg-5">
                <ProgressBar variant="success" now={dsELearnings.oneData?.stats.valideGlobal.percentUnformatted} />
              </div>
              <div className="col-lg-4">
                {`${dsELearnings.oneData?.stats.valideGlobal.nb} apprenants (${dsELearnings.oneData?.stats.valideGlobal.percent} %)`}
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-5">
            <div className="chartContainer">
              <DoughnutChart
                data={[
                  dsELearnings.oneData?.stats.enCoursGlobal.nb ?? 0,
                  dsELearnings.oneData?.stats.nonValideGlobal.nb ?? 0,
                  dsELearnings.oneData?.stats.valideGlobal.nb ?? 0,
                ]}
                backgroundColor={['#ffb13b', '#cc493c', '#3ea279']}
                borderColor={['#ffb13b', '#cc493c', '#3ea279']}
              />
              <div className="chartInner">
                <div className="chartStatus d-flex flex-column">
                  <span>Total</span>
                  <span>{dsELearnings.oneData?.stats.totalGlobal ?? 0}</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
