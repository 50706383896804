import {
  number, object, ref, string,
} from 'yup';

import React, { useEffect, useState } from 'react';

import axios from 'axios';
import dateFormat from 'dateformat';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { Link, useNavigate, useParams } from 'react-router-dom';

import UniteApprentissage from './UniteApprentissage';
import Hn from '../../../../../components/common/Hn';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';
import {
  ParticipantAddDataTable,
} from '../../../../../components/pages/admin/participant/ParticipantAddDataTable';
import {
  ResourceDataProviderEnum,
} from '../../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../../enum/RouteEnum';
import { FormationTags } from '../../../../../components/admin/FormationTags';

import './createFaceTraining.css';

function CreateFaceSession() {
  const params = useParams();

  const [showUnit, setShowUnit] = useState(false);
  const [lieuFormation, setLieuForamtion] = useState([]);
  const [formateurs, setFormateurs] = useState([]);
  const [newSession, setNewSession] = useState([]);
  const [tags, setTags] = useState([]);
  const [changeListFormer, setChangeListFormer] = useState(true);
  const navigate = useNavigate();
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const dte = new Date();
  const uri = process.env.REACT_APP_BASE_URL;

  const validationSchema = object().shape({
    ref: string().required('Champ obligatoire.'),
    dateStart: string().required('Champ obligatoire.'),
    dateEnd: string().required('Champ obligatoire.'),
    location: string().required('Champ obligatoire.'),
    seat_capacity_min: number(),
    seat_capacity_max: number()
      .min(
        ref('seat_capacity_min'),
        "Must be more than Capacité d'accueil minimale  ",
      )
      .required('Champ obligatoire.'),
    former: string().required('Champ obligatoire.'),
  });

  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return 'danger';
    }
    if (value) {
      return 'info';
    }
    return 'warning';
  };

  // récupérer le formateur interieur/ou extérieur
  function getFormer(type) {
    let url = '/former';
    if (type) {
      url = `/former/organize/${type}`;
    }
    axios
      .get(uri + url)
      .then((result) => {
        if (type) {
          setFormateurs(result?.data);
        } else {
          setFormateurs(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log('err formateurs', err);
      });
  }

  function getPlaces() {
    axios
      .get(`${uri}/location`) // récupérer tous les lieux de formations
      .then((result) => {
        setLieuForamtion(result?.data?.data);
      })
      .catch((err) => {
        console.log('err location', err);
      });
  }

  // si on choisit un type bien déterminé ca me donne sa liste de tous les noms et les prénoms des formateurs à choisir
  function changeList(value) {
    setChangeListFormer(false);
    getFormer(value);
  }

  useEffect(() => {
    getFormer();
    getPlaces();
  }, []);

  const handleSubmit = (values) => {
    if (!showUnit) {
      setNewSession([]);
      axios
        .post(`${uri}/session/new`, {...values, tags: tags}) // créer une nouvelle session
        .then((response) => {
          setNewSession(response.data);
          localStorage.setItem('idsession', response.data.id);
          setShowUnit(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function getSessionById(id) {
    const url = `/session/${id}`;
    axios
      .get(uri + url)
      .then((result) => {
        setNewSession(result?.data); // récupérer une session par son id
      })
      .catch((err) => {
        console.log('err session by id', err);
      });
  }

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const createLesson = () => {
    const total = newSession?.lessons ? newSession.lessons.length : 0;

    const data = {
      capacityMin: newSession?.seat_capacity_min || '0',
      capacityMax: 20,
      hourStartMorning: '09:30',
      hourEndMorning: '12:00',
      hourStartmidi: '14:00',
      hourEndMidi: '18:00',
      former: newSession?.former?.id,
      name: `Unité d’apprentissage ${total}`,
      organize: newSession?.former?.organize,
      location: newSession?.location?.id,
      date: dateFormat(addDays(newSession.dateStart, total), 'yyyy-mm-dd') || '',
      session: newSession.id,
    };

    axios
      .post(`${uri}/lesson/new`, data)
      .then(() => {
        // on refresh la liste des lessons
        // (on est obligé de refresh les sessions, il n'y a pas de endpoints sur les unités de formation)
        getSessionById(newSession.id);
      })
      .catch(() => {
        console.log('error');
      });
  };

  return (
    <BaseContainer>
      <div className="lists">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={RouteEnum.ADMIN_ON_SITE}>
                Formations présentielles - Actions de formation
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Créer une session de formation
            </li>
          </ol>
        </nav>
        <div className="h-100 p-5 text-white bg-dark rounded-3">
          <Hn
            title="Créer une action de formation"
            level="h4"
            className="h4-size"
            plus={false}
          />
          <Hn
            title="Etape 2 : créer une session de formation"
            level="p"
            className="py-2 fw-bold"
            plus={false}
          />
          <div className="form-elements mt-2">
            <div className="form-aria p-4">
              <Formik
                initialValues={{
                  ref: '',
                  dateStart: new Date(dte).toISOString().split('T')[0],
                  dateEnd: '',
                  location: '',
                  seat_capacity_min: '',
                  seat_capacity_max: '',
                  former: '',
                  training: params.id,
                  tags: tags,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  /* and other goodies */
                }) => (
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-4">
                      <label className="form-label">Référence*</label>
                      <input
                        status={onHandelStatus(
                          touched.ref,
                          errors.ref,
                          values.ref,
                        )}
                        type="text"
                        className="form-control"
                        id="ref"
                        placeholder="Référence"
                        onChange={handleChange('ref')}
                        value={values.ref}
                        onBlur={handleBlur('ref')}
                        errors={errors.ref}
                      />
                      <ErrorMessage name="ref">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label">Date de début</label>
                      <input
                        status={onHandelStatus(
                          touched.dateStart,
                          errors.dateStart,
                          values.dateStart,
                        )}
                        type="date"
                        className="form-control"
                        id="dateStart"
                        placeholder="Date de début"
                        min={new Date(dte).toISOString().split('T')[0]}
                        max={values.dateEnd}
                        onChange={handleChange('dateStart')}
                        value={values.dateStart}
                        onBlur={handleBlur('dateStart')}
                        errors={errors.dateStart}
                      />

                      <input type="hidden" value={params.id} />

                      <ErrorMessage name="dateStart">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-2">
                      <label className="form-label">Date de fin</label>
                      <input
                        status={onHandelStatus(
                          touched.dateEnd,
                          errors.dateEnd,
                          values.dateEnd,
                        )}
                        type="date"
                        className="form-control"
                        id="dateEnd"
                        placeholder="Date de fin"
                        onChange={handleChange('dateEnd')}
                        value={values.dateEnd}
                        min={values.dateStart}
                        onBlur={handleBlur('dateEnd')}
                        errors={errors.dateEnd}
                      />
                      <ErrorMessage name="dateEnd">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <FormationTags className="col-md-4" tags={tags} updateTags={setTags}></FormationTags>

                    <div className="col-md-4">
                      <label className="form-label">Lieu de formation</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        id="actif"
                        defaultValue="sélectinner dans la liste"
                        onChange={handleChange('location')}
                        value={values.location}
                        onBlur={handleBlur('location')}
                      >
                        <option>sélectionner dans la liste</option>
                        {lieuFormation.map((lieu) => (
                          <option value={lieu.id} key={lieu.id}>
                            {`${lieu?.name} - ${lieu?.address}`}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage name="location">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        Capacité d'accueil minimale
                      </label>
                      <input
                        status={onHandelStatus(
                          touched.seat_capacity_min,
                          errors.seat_capacity_min,
                          values.seat_capacity_min,
                        )}
                        type="number"
                        className="form-control"
                        id="seat_capacity_min"
                        // max={values.seat_capacity_max}
                        placeholder="00"
                        onChange={handleChange('seat_capacity_min')}
                        value={values.seat_capacity_min}
                        onBlur={handleBlur('seat_capacity_min')}
                        errors={errors.seat_capacity_min}
                      />
                      <ErrorMessage name="seat_capacity_min">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        Capacité d'accueil maximale*
                      </label>
                      <input
                        status={onHandelStatus(
                          touched.seat_capacity_max,
                          errors.seat_capacity_max,
                          values.seat_capacity_max,
                        )}
                        type="number"
                        className="form-control"
                        id="seat_capacity_max"
                        placeholder="00"
                        // min={values.seat_capacity_min}
                        onChange={handleChange('seat_capacity_max')}
                        value={values.seat_capacity_max}
                        onBlur={handleBlur('seat_capacity_max')}
                        errors={errors.seat_capacity_max}
                      />
                      <ErrorMessage name="seat_capacity_max">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">Formateur</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        id="actif"
                        defaultValue="sélectinner dans la liste"
                        onChange={(event) => changeList(event.target.value)}
                      >
                        <option value="">sélectionner dans la liste</option>
                        <option value="interne">Interne</option>
                        <option value="externe">Externe</option>
                      </select>
                      <ErrorMessage name="former">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">&nbsp;</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        id="former"
                        value={values.former}
                        onChange={handleChange('former')}
                        onBlur={handleBlur('former')}
                        errors={errors.former}
                        disabled={changeListFormer}
                      >
                        <option defaultValue>
                          sélectionner dans la liste
                        </option>
                        {formateurs.map((former, i) => (
                          <option value={former.id} key={i}>
                            {former?.lastName}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage name="former">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    {!showUnit && (
                      <div className="col-12 mt-5">
                        <button
                          type="submit"
                          className="btn btn-outline-primary float-end"
                        >
                          Valider
                        </button>
                      </div>
                    )}
                  </form>
                )}
              </Formik>
              {showUnit && (
                <div className="pt-5">
                  <div>
                    <div className="d-flex pb-5 pt-5 align-items-center">
                      <Hn
                        title="Unités de formation"
                        level="h4"
                        className="h4-size"
                        plus={false}
                      />
                      <span className="ms-3 look-like-link fw-bold" onClick={createLesson}>
                        Ajouter une unité
                      </span>
                    </div>
                    <div className="row">
                      {newSession?.lessons?.map((item, index) => (
                        <UniteApprentissage
                          session={newSession}
                          nbUnite={index}
                          lieuFormation={lieuFormation}
                          lesson={item}
                          key={item.id}
                          handleClose={() => getSessionById(newSession.id)}
                        />
                      ))}
                    </div>
                  </div>
                  {newSession.id && (
                    <ParticipantAddDataTable resource={ResourceDataProviderEnum.participantSession} idEntity={newSession.id} />
                  )}
                </div>
              )}
            </div>
            {showUnit && (
              <div className="mt-5">
                <Link to="/admin/create-formation-presentielle" className="btn btn-link text-decoration-none resetBtn">
                  Annuler
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary float-end"
                  onClick={() => {
                    navigate(`/admin/createSession-etape3/${params.id}`);
                  }}
                >
                  Suivant
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </BaseContainer>
  );
}

export default CreateFaceSession;
