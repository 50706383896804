import React, { useEffect, useState } from 'react';

import { ParametersCrudType } from 'leeloo-react/model/dataProvider/ParametersCrudType';

import { DataTableDefaultButton } from './DataTableDefaultButton';
import { CustomFilterDataProviderType } from '../../../model/dataProvider/CustomFilterDataProviderType';
import { DataTableButtonType } from '../../../model/dataTable/DataTableButtonType';
import { useCustomDownloader } from '../../hook/useCustomDownloader';

type Props = {
    nameFile: string,
    exportFilters?: CustomFilterDataProviderType,
}

export function DataTableExportButton<T>(props: DataTableButtonType<T> & Props) {
  const [isLoad, setIsLoad] = useState(false);
  const { downloadFile } = useCustomDownloader({ });

  useEffect(() => {
    if (isLoad) {
      exportItems();
    }
  }, [isLoad]);

  const exportItems = () => {
    let data:ParametersCrudType & { filter?: CustomFilterDataProviderType } = {};
    if (props.rows != null) {
      data.ids = (props.rows).map((row) => row.id);
    }
    if (props.exportFilters != null) {
      data = { ...data, filter: { ...props.exportFilters } };
    }
    downloadFile(props.resource, props.nameFile, data).then(() => isSuccess).finally(() => setIsLoad(false));
  };

  const isSuccess = () => {
    if (props.cleanSelection) {
      props.cleanSelection();
    }
  };

  const handleLoad = (newLoadState: boolean) => {
    setIsLoad(newLoadState);
  };

  return (
    <DataTableDefaultButton isLoad={isLoad} handleLoad={handleLoad} label="Exporter" icon="export" {...props} />
  );
}

DataTableExportButton.defaultProps = {
  exportFilters: null,
};
