import React, { ReactElement } from 'react';

import { SkillDataTable } from './SkillDataTable';
import { BaseContainer } from '../../../layout/BaseContainer';

export function SkillListPage(): ReactElement {
  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Compétences</h1>
      </div>
      <div className="pt-5">
        <SkillDataTable />
      </div>
    </BaseContainer>
  );
}
