import React from 'react';

import { ColumnType } from 'leeloo-react/model/dataTable/ColumnType';

/**
 * Donne la main sur un rendu libre de la valeur affiché
 * @param props
 * @returns
 */
export function LengthColumn<T>(props: ColumnType<T>) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{props.value.length}</>;
}
