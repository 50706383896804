import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Delete from '../../../../../assets/icons/delete.png';

import './createUnite.css';

function DeleteChapitre({ chapitreToBeDeleted }) {
  const [showModal, setShowModal] = useState(false);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const uri = process.env.REACT_APP_BASE_URL;

  const deleteChapitre = (chapitre) => {
    axios.post(`${uri}/chapitre/${chapitre?.id}`)
      .then(() => {
        hideModal();
        toast.success('Ce chapitre a bien été supprimé!');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((err) => {
        hideModal();
        toast.error('Désolé vous ne pouvez pas supprimer ce chapitre');
        console.log('err sessions', err);
      });
  };

  const showModalFunction = (chapitre) => {
    setShowModal(chapitre);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
  }, [chapitreToBeDeleted]);

  return (
    <>
      <span className="dropdown-item cursor-pointer" onClick={(e) => showModalFunction(chapitreToBeDeleted)}>
        <img src={Delete} className="pe-1" />
        Supprimer
      </span>
      <Modal show={showModal} onHide={(e) => hideModal()} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            Etes-vous sûr(e) de vouloir supprimer "
            {chapitreToBeDeleted?.titre}
            " ?
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={() => hideModal()} style={{ color: '#006FFF' }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-danger"
            onClick={() => deleteChapitre(chapitreToBeDeleted)}
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default DeleteChapitre;
