import { number, object, ref } from 'yup';

import React, { useState } from 'react';

import axios from 'axios';
import dateFormat from 'dateformat';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

import Icon from '../../../../../components/common/Icon';
import './createFaceTraining.css';

function UniteApprentissage({
  session, nbUnite, lieuFormation, lesson, handleClose = null,
}) {
  const [modalShow, setModalShow] = useState(false);
  const [formateurs, setFormateurs] = useState([]);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  const uri = process.env.REACT_APP_BASE_URL;

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // récupérer le formateur interieur/ou extérieur
  function getFormer(type) {
    let url = '/former';
    if (type) {
      url = `/former/organize/${type}`;
    }
    axios
      .get(uri + url)
      .then((result) => {
        if (type) {
          setFormateurs(result?.data);
        } else {
          setFormateurs(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log('err formateurs', err);
      });
  }

  const changeList = (value) => {
    setFormateurs([]);
    getFormer(value);
  };

  const removeLesson = () => {
    axios
      .delete(`${uri}/lesson/${lesson.id}`)
      .then(() => {
        if (handleClose) {
          handleClose();
        }
      })
      .catch((err) => {
        console.log('err removeLesson', err);
      });
  };

  function ModalForm(props) {
    const validationSchema = object().shape({
      // seat_capacityMin: number().required("Champ obligatoire."),
      capacityMax: number()
        .max(
          ref('capacityMax'),
          "Must be less than Capacité d'accueil maximale",
        )
        .required('Champ obligatoire.'),
      capacityMin: number()
        .min(
          ref('capacityMin'),
          "Must be more than Capacité d'accueil minimale  ",
        )
        .required('Champ obligatoire.'),
    });
    const saveUnite = (values) => {
      const data = {
        capacityMax: values.capacityMax,
        capacityMin: values.capacityMin,
        hourStartMorning: values.hourStartMorning,
        hourEndMorning: values.hourEndMorning,
        hourStartmidi: values.hourStartmidi,
        hourEndMidi: values.hourEndMidi,
        former: values.former,
        location: values.location,
        name: values.name,
        session: session.id,
        date: values.date,
      };

      axios
        .put(`${uri}/lesson/edit/${lesson.id}`, data)
        .then(() => {
          setModalShow(false);
          if (handleClose) {
            handleClose();
          }
        })
        .catch(() => {
          console.log('error');
        });
    };

    return (
      <Modal
        size="lg"
        animation={false}
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="modalLession"
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {lesson.name ?? `Unité d'apprentissage ${nbUnite}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Formik
              initialValues={{
                capacityMin: lesson.capacityMin || session.seat_capacity_min || '0',
                capacityMax: lesson.capacityMax || session.seat_capacity_max || '0',
                hourStartMorning: lesson.hourStartMorning || '',
                hourEndMorning: lesson.hourEndMorning || '',
                hourStartmidi: lesson.hourStartmidi || '',
                hourEndMidi: lesson.hourEndMidi || '',
                former: lesson?.former?.id,
                name: lesson?.name,
                organize: lesson?.former?.organize,
                location: lesson?.location?.id,
                date: dateFormat(lesson?.date ?? addDays(session.dateStart, nbUnite), 'yyyy-mm-dd') || '',
              }}
              validationSchema={validationSchema}
              onSubmit={saveUnite}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <form className="row g-3 unite" onSubmit={handleSubmit}>
                  <div className="col-md-6">
                    <label className="form-label">
                      Nom de l'unité d'apprentissage
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Ajouter un nom"
                      onChange={handleChange('name')}
                      value={values.name}
                      onBlur={handleBlur('name')}
                    />
                    <ErrorMessage name="name">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      id="date"
                      value={values?.date}
                      min={dateFormat(session?.dateStart, 'dd/mm/yyyy')}
                      max={session?.dateEnd}
                      onChange={handleChange('date')}
                      onBlur={handleBlur('date')}
                    />
                    <ErrorMessage name="date">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Heure de début matin</label>
                    <input
                      type="time"
                      name="hourStartMorning"
                      className="form-control"
                      placeholder="00:00"
                      onChange={handleChange('hourStartMorning')}
                      value={values.hourStartMorning}
                      onBlur={handleBlur('hourStartMorning')}
                    />
                    <ErrorMessage name="hourStartMorning">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Heure de fin matin</label>
                    <input
                      type="time"
                      name="hourEndMorning"
                      className="form-control"
                      placeholder="00:00"
                      onChange={handleChange('hourEndMorning')}
                      value={values.hourEndMorning}
                      onBlur={handleBlur('hourEndMorning')}
                    />
                    <ErrorMessage name="hourEndMorning">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Heure de début aprés-midi
                    </label>
                    <input
                      type="time"
                      name="hourStartmidi"
                      className="form-control"
                      placeholder="00:00"
                      onChange={handleChange('hourStartmidi')}
                      value={values.hourStartmidi}
                      onBlur={handleBlur('hourStartmidi')}
                    />
                    <ErrorMessage name="hourStartmidi">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Heure de fin aprés-midi
                    </label>
                    <input
                      type="time"
                      name="hourEndMidi"
                      className="form-control"
                      placeholder="00:00"
                      onChange={handleChange('hourEndMidi')}
                      value={values.hourEndMidi}
                      onBlur={handleBlur('hourEndMidi')}
                    />
                    <ErrorMessage name="hourEndMidi">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Lieux de formation</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      id="location"
                      onChange={handleChange('location')}
                      value={values.location}
                      onBlur={handleBlur('location')}
                    >
                      {lieuFormation?.map((lieu, i) => (
                        <option
                          value={lieu.id}
                          key={lieu.id}
                        >
                          {lieu?.address}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage name="location">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Capacité d'accueil minimale
                    </label>
                    <input
                      type="number"
                      name="capacityMin"
                      className="form-control"
                      placeholder="00"
                      onChange={handleChange('capacityMin')}
                      value={values.capacityMin}
                      onBlur={handleBlur('capacityMin')}
                    />
                    <ErrorMessage name="capacityMin">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Capacité d'accueil maximale
                    </label>
                    <input
                      type="number"
                      name="capacityMax"
                      className="form-control"
                      id="capacity-max"
                      placeholder="00"
                      onChange={handleChange('capacityMax')}
                      value={values.capacityMax}
                      onBlur={handleBlur('capacityMax')}
                    />
                    <ErrorMessage name="capacityMax">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Formateur
                    </label>
                    <select
                      className="form-select"
                      onChange={(event) => {
                        setFieldValue('organize', event.target.value);
                        changeList(event.target.value);
                      }}
                      value={values.organize}
                    >
                      <option>sélectionner dans la liste</option>
                      <option value="interne">Interne</option>
                      <option value="externe">Externe</option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">&nbsp;</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      id="former"
                      onChange={handleChange('former')}
                      onBlur={handleBlur('former')}
                      value={values.former}
                    >
                      <option defaultValue>sélectionner dans la liste</option>
                      {formateurs?.map((former) => (
                        <option value={former?.id} key={former?.id}>
                          {former?.lastName}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage name="former">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 mt-5">
                    <Button
                      variant="btn btn-link resetBtn"
                      onClick={() => setModalShow(false)}
                    >
                      Annuler
                    </Button>
                    <Button type="submit" variant="btn btn-primary float-end">
                      Valider
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <div className="col-md-6">
      <div className="cardUnit text-white  mb-3 p-3">
        <div className="card-header d-flex justify-content-between">
          <h5 className="card-title">
            {lesson.name ?? `Unité d'apprentissage ${nbUnite}`}
            {' '}
          </h5>
          <button
            className="styleNone"
            onClick={removeLesson}
          >
            <Icon name="delete" />
          </button>
        </div>
        <div className="card-body pt-3">
          <Icon
            name="localisation"
            data={lesson?.location ? `${lesson.location.address ?? ''}, ${lesson.location.zip ?? ''} ${lesson.location.town ?? ''}` : 'Non renseigné'}
          />
          <ul className="list-inline ps-0">
            <li className="list-inline-item">
              <Icon
                name="Calendar"
                data={dateFormat(lesson.date ?? session?.dateStart, 'dd/mm/yyyy')}
              />
            </li>
            <li className="list-inline-item">
              <Icon name="Clock" data={`${lesson?.hourStartMorning}/${lesson?.hourEndMorning} et ${lesson?.hourStartmidi}/${lesson?.hourEndMidi}`} />
            </li>
            <li className="list-inline-item">
              <Icon
                name="profil"
                data={`${lesson.former.firstName} ${lesson.former.lastName}`}
              />
            </li>
          </ul>
        </div>
        <div className="card-footer bg-transparent">
          <button
            className="styleNone"
            onClick={() => {
              setModalShow(true);
              changeList(lesson?.former?.organize);
            }}
          >
            <Icon name="edit" />
          </button>
        </div>
      </div>
      <ModalForm
        show={modalShow}
      />
    </div>
  );
}
export default UniteApprentissage;
