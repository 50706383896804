import React, { ReactElement, useEffect, useState } from 'react';

import { InputField } from 'leeloo-react/component/form/field/InputField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';

import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../enum/RouteEnum';
import { ELearningDto } from '../../../model/dto/ELearningDto';
import { OnSiteDto } from '../../../model/dto/OnSiteDto';
import { FormationCard } from '../../formation/FormationCard';
import { BaseContainer } from '../../layout/BaseContainer';

// Définition d'une fct permettant de mettre un timeout après la recherche d'un utilisateur
// afin d'éviter l'envoie de requetes inutiles
const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(
  func: F,
  waitFor: number,
) => {
  let timeout: NodeJS.Timeout;

  return (...args: Parameters<F>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };
};

export function SearchPage(): ReactElement {
  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [searchResult, setSearchResult] = useState<(ELearningDto|OnSiteDto)[]>([]);
  const settingsImgBasePath = process.env.REACT_APP_PIC_SETTINGS_IMG;

  // On va récupérer l'image à afficher sur la page de recherche
  const searchImage = useDataSource({
    resource: ResourceDataProviderEnum.settings,
    params: {
      id: 'search_image',
    },
    singleResult: true,
  });

  // TODO il faudrait faire un endpoint de recherche plutot que tout ca
  const dsELearnings = useDataSource<ELearningDto>({
    resource: ResourceDataProviderEnum.eLearning,
    noLoadAfterInit: true,
  });

  const dsTraining = useDataSource<OnSiteDto>({
    resource: ResourceDataProviderEnum.training,
    noLoadAfterInit: true,
  });

  const handleSearchChange = (value: string) => {
    debounce(() => {
      setSearchTerm(value);
    }, 500)();
  };

  useEffect(() => {
    // On vient appliquer mon filtre de recherche
    dsELearnings.setParams({ filter: { search: searchTerm, tag: searchTerm } });
    dsTraining.setParams({ filter: { search: searchTerm, tag: searchTerm } });
  }, [searchTerm]);

  /**
   * Compare deux formations par tags.
   * Si la première formation n'a oas de tag qui matche searchTerm et que l'autre a un tag qui matche
   *   search term, alors on renvoie true, sinon false
   * @param a
   * @param b
   * @param searchTerm
   */
  const compareByTags = (a : OnSiteDto | ELearningDto, b : OnSiteDto | ELearningDto, searchTerm: string) : boolean =>
    (!a.tags || a.tags.join().indexOf(searchTerm) === -1) && b.tags && b.tags.join().indexOf(searchTerm) !== -1

  useEffect(() => {
    const results:(ELearningDto|OnSiteDto)[] = [];
    dsELearnings.dataList.forEach((eLearning) => {
      eLearning.eLearning = true;
      results.push(eLearning);
    });
    dsTraining.dataList.forEach((onSite) => {
      onSite.eLearning = false;
      results.push(onSite);
    });
    // on vient trier mes resultat par titre
    // Pour faciliter le tout c'est 2 variables différents, yeah
    results.sort((a, b) => {
      // On commence par trier par tag si besoin (spec client)
      if( searchTerm ){
        if ( compareByTags(a, b, searchTerm) ) {
          return 1;
        }
        if ( compareByTags(b, a, searchTerm) ) {
          return -1;
        }
      }
      // sinon on trie par intitule ou par titre selon le cas de figure (elearning ou presentiel)
      const titleA = ('intitule' in a && a.intitule) || ('title' in a && a.title);
      const titleB = ('intitule' in b && b.intitule) || ('title' in b && b.title);
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
    // Et on assigne mes résultats
    setSearchResult(results);
  }, [dsELearnings.dataList, dsTraining.dataList]);

  return (
    <BaseContainer>

      <div className="search-head">
        {searchImage.oneData != null && (
          <div className="text-center">
            <img src={`${settingsImgBasePath!}${searchImage.oneData}`} alt="Recherche" className="logo" />
          </div>
        )}

        <div className="position-relative">
          {searchTerm != null && searchTerm !== '' ? (
            <button onClick={() => setSearchTerm('')} className="btn-reset">
              <i className="uil uil-times" />
            </button>
          ) : <></>}
        </div>
        <InputField
          placeholder="Entrez la formation que vous recherchez"
          onChangeValue={handleSearchChange}
          className="search-input"
          value={searchTerm}
        />

        <div className="d-flex align-items-center justify-content-between">
          <div className="d-none d-md-block flex-grow-1">
            <div className="title d-xl-none">Ou choisissez un catalogue</div>
          </div>
          <div className="ctas">
            <RouterLink routeName={RouteEnum.E_LEARNING} className="btn btn-primary flex-grow-1 flew-grow-lg-0">
              E-Learning
            </RouterLink>
            <RouterLink routeName={RouteEnum.PRESENTIEL} className="btn btn-primary">
              Présentiel
            </RouterLink>
          </div>
        </div>

      </div>

      {searchTerm != null && searchTerm !== '' ? (
        <h3 className="plus mb-4">Votre résultat de recherche</h3>
      ) : <></>}

      <div className="row">
        {searchResult.map((formation) => (
          <div className="col-6 col-md-3 col-xxl-2" key={formation.id}>
            <FormationCard
              formation={formation}
              eLearning={formation.eLearning}
            />
          </div>
        ))}

      </div>

    </BaseContainer>
  );
}
