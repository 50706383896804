import * as Yup from 'yup';

import React, {
  useEffect, useRef, useState, Fragment,
} from 'react';

import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { Dropdown, ProgressBar } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import DeleteChapitre from './DeleteChapitre';
import DeleteUniteDeFormation from './DeleteUniteDeFormation';
import EditChapitre from './EditChapitre';
import EditUniteDeFormation from './EditUniteDeFormation';
import Add from '../../../../../assets/icons/add.png';
import Option from '../../../../../assets/icons/options.svg';
import Play from '../../../../../assets/icons/Play.png';
import Hn from '../../../../../components/common/Hn';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';

import './createUnite.css';

const validationSchema = Yup.object().shape({
  titre: Yup.string().required('Le titre est obligatoire'),
  document: Yup.mixed().nullable().required('Choissisez un document SVP'),
});

function CreateUniteDeFormation() {
  const fileRef = useRef();
  const [chapitre, setChapitre] = useState([]);
  const [unites, setUnites] = useState([]);
  const [duree, setDuree] = useState('0');
  const { id } = useParams();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const uri = process.env.REACT_APP_BASE_URL;
  // Get chapitre par (id)
  function getChapitre() {
    axios.get(`${uri}/chapitre/${id}`)
      .then((result) => {
        setChapitre(result?.data);
      })
      .catch((err) => {
        console.log('err sessions', err);
      });
  }

  // Récupérer le détail du chapitre par (id) et ses unités
  function getUnites() {
    axios.get(`${uri}/chapitre/unites/${id}`)
      .then((result) => {
        setUnites(result?.data);
      })
      .catch((err) => {
        console.log('err sessions', err);
      });
  }

  useEffect(() => {
    getChapitre();
    getUnites();
  }, []);
  // Créer une unité
  // Continuez avec l'exécution du gestionnaire de soumission

  const handleSubmit = (values) => {
    // upload
    const options = {
      onUploadProgress(progressEvent) {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setUploadPercentage(percent);
        }
      },
    };

    const data = new FormData();
    data.append('titre', values.titre);
    data.append('document', values.document);
    data.append('chapitre', id);
    data.append('duree', duree);

    axios.post(
      `${uri}/unite/new`,
      data,
      options,
    )
      .then(() => {
        setUploadPercentage(100);

        setTimeout(() => {
          setUploadPercentage(0);
        }, 1000);

        getUnites();
        values.titre = '';
        values.document = null;
        document.getElementById('formulaire-ajout-unite').reset();
        setDuree('0');
      })
      .catch((err) => {
        console.log('err training', err);
        setUploadPercentage(0);
      });
  };

  // reprendre la durée d'une vidéo
  function getDuration(control) {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      const duration = new Date(video.duration * 1000).toISOString().substring(11, 19);
      setDuree(duration);
    };
    video.src = URL.createObjectURL(control.files[0]);
  }

  return (
    <BaseContainer>
      <div className="lists p-5">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Formations E-learning - Actions de formation</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Créer une action de formation
            </li>
          </ol>
        </nav>
        <div className="h-100 p-5 text-white bg-black rounded-3">
          <Hn
            title="Créer une action de formation"
            level="h4"
            className="h4-size"
            plus={false}
          />
          <Hn
            title="Etap 2 : Ajout de contenu"
            level="p"
            className="py-2 fw-bold"
            plus={false}
          />
          <div className="form-elements mt-2">
            <div className="row col-md-12 text-right m-0 pb-2">
              <a
                href={`/admin/creer-chapitre-e-learning/${chapitre?.elearning?.id}`}
                className="p-0 font-weight-600"
              >
                <img src={Add} className="pe-1" />
                {' '}
                Ajouter un chapitre
              </a>
            </div>
            <div className="form-aria">
              <div className="position-relative">
                <h4 className="header-text py-5 px-3">
                  {chapitre.titre}
                </h4>
                <Dropdown align="end" className="dropright top-50px">
                  <Dropdown.Toggle variant="secondary" className="without-caret">
                    <img src={Option} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant="dark">
                    <EditChapitre chapitreToBeEdited={chapitre} />
                    <DeleteChapitre chapitreToBeDeleted={chapitre} />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="p-3">
                <Formik
                  initialValues={{
                    titre: '',
                    document: null,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form
                      className="row g-3"
                      id="formulaire-ajout-unite"
                      onSubmit={handleSubmit}
                    >
                      <Hn
                        title="Création unité de formation"
                        level="p"
                        className="py-2 fw-bold"
                        plus={false}
                      />
                      <div className="col-md-6">
                        <label className="form-label">Titre</label>
                        <input
                          type="text"
                          className="form-control"
                          id="titre"
                          name="titre"
                          placeholder="Ajouter un titre"
                          onChange={handleChange('titre')}
                          value={values.titre}
                          onBlur={handleBlur('titre')}
                        />
                        <ErrorMessage name="titre">
                          {(msg) => (
                            <div
                              className="text-danger"
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label">
                          Document
                        </label>
                        <span
                          className="form-control"
                          onClick={() => fileRef.current.click()}
                        >
                          <img src={Add} className="pe-1" />
                          {' '}
                          {fileRef?.current?.value ? fileRef?.current?.value : 'Ajouter un fichier'}
                        </span>

                        <input
                          ref={fileRef}
                          multiple={false}
                          type="file"
                          name="document"
                          id="document"
                          onChange={(event) => {
                            setFieldValue('document', event.currentTarget.files[0]);
                            getDuration(event.currentTarget);
                          }}
                          hidden
                          accept=".mp4,.zip,.pdf,.mp3,.webm"
                        />
                        {uploadPercentage > 0 && (
                          <div className="row mt-3">
                            <div className="col pt-1">
                              <ProgressBar
                                now={uploadPercentage}
                                striped
                                label={`${uploadPercentage}%`}
                              />
                            </div>
                          </div>
                        )}
                        <ErrorMessage name="document">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>

                      </div>
                      <div className="col-md-6" />
                      <div className="col-md-6">
                        <p> Format : mp4, zip, pdf, mp3, webm </p>
                      </div>

                      {unites.map((unite, i) => (
                        <Fragment key={unite.id}>
                          <div className="col-md-11">
                            <label className="form-label">
                              {unite.titre}
                            </label>
                            <p>
                              <img src={Play} className="pe-1" />
                              {' '}
                              {unite.document}
                            </p>
                          </div>

                          <div className="col-md-1">
                            <EditUniteDeFormation
                              uniteToBeEdited={unite}
                            />
                            <DeleteUniteDeFormation
                              uniteToBeDeleted={unite}
                            />
                          </div>

                          {(unite.extension == 'mp3' || unite.extension == 'mp4' || unite.extension == 'webm')
                                && (
                                  <div className="col-md-3 mt-0 mb-5">
                                    <label className="form-label">
                                      Durée
                                    </label>
                                    <video
                                      className="hidden"
                                      id={`video-${unite?.id}`}
                                      width="320"
                                      height="240"
                                      controls
                                    >
                                      <source
                                        src={process.env.REACT_APP_PIC_ELEARNING_FILE + unite?.document}
                                        type={unite.extension == 'webm' ? 'video/webm' : 'video/mp4'}
                                      />
                                      Your browser does not support the
                                      video tag.
                                    </video>
                                    <div className="col-md-3">
                                      <input
                                        id={`duree-${unite?.id}`}
                                        type="text"
                                        className="form-controlinput duree_input"
                                        value={unite?.duree}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                )}
                        </Fragment>
                      ))}
                      <div className="col-md-12 pt-4">
                        <button
                          type="submit"
                          className="btn btn-outline-primary float-end"
                        >
                          Ajouter
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-12 mt-5">
              <Link to={`/admin/creer-chapitre-e-learning/${chapitre?.elearning?.id}`} className="btn btn-link text-decoration-none">
                Retour
              </Link>
              <Link to={`/admin/add-skills-elearning/${chapitre?.elearning?.id}`} className="btn btn-primary float-end">
                Suivant
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
}

export default CreateUniteDeFormation;
