import React, { Dispatch, SetStateAction } from 'react';

import { Button, Modal } from 'react-bootstrap';

type PopupSubscribeType = {
    showFilterSubscribe: boolean
    setShowFilterSubscribe: Dispatch<SetStateAction<boolean>>
    title?:string
}

export default function PopupSubscribe(props: PopupSubscribeType) {
  return (
    <Modal show={props.showFilterSubscribe} onHide={() => props.setShowFilterSubscribe(false)} size="lg" className="popup">
      <Modal.Header closeButton>
        <Modal.Title className="px-3 nopad">
          <h4>
            {props.title}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className=" text-white">
          <span>
            Si vous êtes intéressé pour souscrire à la formule e-learning
            merci de contacter le service formation du pôle formation :
            <a href="mailto:formation@guy-hoquet.com">formation@guy-hoquet.com</a>
          </span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => props.setShowFilterSubscribe(false)}
        >
          Valider
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

PopupSubscribe.defaultProps = {
  title: null,
};
