import React, { useState } from 'react';

import { useDataProvider } from 'leeloo-react/component/hook/useDataProvider';

import { DataTableDefaultButton } from './DataTableDefaultButton';
import { DataTableButtonType } from '../../../model/dataTable/DataTableButtonType';
import { UserSessionDto } from '../../../model/dto/UserSessionDto';

export function DataTableCreateButton<T>(props: DataTableButtonType<T>) {
  const [isLoad, setIsLoad] = useState(false);
  const dp = useDataProvider();

  const fetchPromise = () => dp.create!(props.resource, { data: { ...props.data, id: props.row?.id } });
  const fetchPromises = () => (props.rows as []).map((row: T & {id: any}) => dp.create!(props.resource, { data: { ...props.data, id: row.id } }));

  const handleLoad = (newLoadState: boolean) => {
    setIsLoad(newLoadState);
  };

  const isChecked = (id: string) : boolean => (props.rows ? props.rows.map((row: any) => row.id) : []).includes(id);

  /**
   * Renvoie la liste des participants déjà présents dans la session ou dans une session concurrente
   *   et pour lesquels on a coché la case.
   */
  const getParticipantsInSession = () : UserSessionDto[] => (
    props.dataSource
      ? (props.dataSource.dataList as UserSessionDto[]).filter(
        (item: UserSessionDto) => (isChecked(item.id) && (item.inConcurrentSession || item.inSession)),
      )
      : []
  );

  const getMessageMultiples = () : string => {
    let message = '';
    if (getParticipantsInSession().length > 0) {
      message += 'Attention un ou plusieurs participants sélectionnés sont déjà inscrits à une autre session ';
      message += 'aux mêmes dates et ne peuvent pas être ajoutés à cette session. ';
    }
    return `${message}Confirmez-vous l'ajout de ces éléments ?`;
  };

  return (
    <DataTableDefaultButton
      {...props}
      isLoad={isLoad}
      onClick={() => setIsLoad(true)}
      label="Ajouter"
      icon="plus"
      fetchPromise={fetchPromise}
      fetchPromises={fetchPromises}
      handleLoad={handleLoad}
      messageFetchOne="Confirmez-vous l'ajout de cet élément ?"
      messageFetchMultiples={getMessageMultiples()}
      messageSuccess="Ajout effectué avec succès"
    />
  );
}

DataTableCreateButton.defaultProps = {
  variant: 'primary',
};
