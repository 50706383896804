import './styles.css';

export default function ProgressBar({ bgColor, width, title }) {
  return (
    <div id="ProgressBar">
      <div id="innerBar" style={{ backgroundColor: bgColor, width, title }} />
      <div id="innerBar" style={{ backgroundColor: bgColor, width }} />
    </div>
  );
}
