import { useDataProvider } from 'leeloo-react/component/hook/useDataProvider';
import { ParametersCrudType } from 'leeloo-react/model/dataProvider/ParametersCrudType';
import { PropsUseDataProviderType } from 'leeloo-react/model/dataProvider/PropsUseDataProviderType';

import { CustomDataProviderReturnDownloaderFileType } from '../../model/dataProvider/CustomDataProviderReturnDownloaderFileType';

/**
 * Permet le téléchargement de fichier AJAX
 * ATTENTION : Le data provider utilisé doit implémenter la méthode "downloadFile"
 */
export function useCustomDownloader(props: PropsUseDataProviderType) {
  const dp = useDataProvider({ ...props }) as CustomDataProviderReturnDownloaderFileType;

  if (!dp.downloadFile) {
    throw new Error("You use downloader with a dataProvider without 'downloadFile' please implement this method, see doc Leeloo");
  }

  return {
    /**
         * A partir d'une resource + paramètre et enfin le nom du fichier
         * Génère un téléchargement a partir d'une réponse ajax sécurisé eventuellement
         * @param resource
         * @param defaultFileName
         * @param params
         */
    async downloadFile(resource: string, defaultFileName: string, params: ParametersCrudType) {
      let fileName = defaultFileName;
      const r = await dp.downloadFile!(resource, params);
      // génération d'un lien + click pour télécharger le fichier reçu
      const url = window.URL.createObjectURL(new Blob([r.data]));
      const link = document.createElement('a');
      const contentDisposition = r.headers && r.headers['content-disposition'] ? r.headers['content-disposition'] : null;
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/);
        if (fileNameMatch && fileNameMatch.length === 2) [, fileName] = fileNameMatch;
      }
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  };
}
