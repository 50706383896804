import React, { useState } from 'react';

import {
    LoginAsActionBtn,
} from 'leeloo-react/component/actionBtn/LoginAsActionBtn';
import { SimpleColumn } from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { SelectField } from 'leeloo-react/component/form/field/SelectField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { CustomFilterDataProviderType } from '../../../../model/dataProvider/CustomFilterDataProviderType';

type UserDataTableProps = {
    resource: string,
}

export function UserDataTable(props: UserDataTableProps) {
    const [filters, setFilters] = useState<CustomFilterDataProviderType>({
        searchByNom: '',
        searchByPrenom: '',
        searchByMailcontact: '',
        searchByAgencies: '',
        searchByStatus: '',
    });

    const handleFilterChange = (name: string) => (value: string) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const dsUsers = useDataSource({
        resource: props.resource,
        params: {
            pagination: { page: 1, take: 50 },
        },
    });

    /**
     * Definit les actions pour chaque utilisateur
     */
    const getActionRow = (row: { id : string, status : string }) => (
        row.status === 'actif' ? [
            <LoginAsActionBtn
                idUsers={row.id}
                icon="signin"
                label="Se connecter en tant que"
                key={0}
            />,
        ] : []
    );

    return (
        <DataTable
            className="user-table"
            dataSource={dsUsers}
            filter={(
                <Filter>
                    <InputField
                        name="searchByNom"
                        label="Rechercher par nom"
                        value={filters.searchByNom}
                        onChangeValue={handleFilterChange('searchByNom')}
                    />
                    <InputField
                        name="searchByPrenom"
                        label="Rechercher par prénom"
                        value={filters.searchByPrenom}
                        onChangeValue={handleFilterChange('searchByPrenom')}
                    />
                    <InputField
                        name="searchByMailcontact"
                        label="Rechercher par adresse mail"
                        value={filters.searchByMailcontact}
                        onChangeValue={handleFilterChange('searchByMailcontact')}
                    />
                    <InputField
                        name="searchByAgencies"
                        label="Rechercher par agence"
                        value={filters.searchByAgencies}
                        onChangeValue={handleFilterChange('searchByAgencies')}
                    />
                    <SelectField
                        optionsStringList={['actif', 'inactif']}
                        name="searchByStatus"
                        label="Rechercher par statut"
                        value={filters.searchByStatus}
                        onChangeValue={handleFilterChange('searchByStatus')}
                    />
                </Filter>
            )}
            actionRow={getActionRow}
        >
            <SimpleColumn field="nom" title="Nom" sortable />
            <SimpleColumn field="prenom" title="Prénom" sortable />
            <SimpleColumn field="mailcontact" title="E-mail" sortable />
            <SimpleColumn field="agencies" title="Agences" sortable />
            <SimpleColumn field="status" title="Statut" sortable />
        </DataTable>
    );
}
