import { AxiosResponseHeaders } from 'axios';
import { ParamsDataProviderType } from 'leeloo-react/model/dataProvider/DataProviderType';
import { ParametersCrudType } from 'leeloo-react/model/dataProvider/ParametersCrudType';
import { ResponseHttpType } from 'leeloo-react/model/dataProvider/ResponseHttpType';
import qs from 'qs';

/**
 * DataProvider simple CRUD générique
 * Basé sur ApiPlatformDataProvider dans Leeloo leeloo-react/src/dataProvider/ApiPlatformDataProvider.ts
 * Adapté pour les API Laravel
 * @param apiUrl
 * @param axios
 * @param reportError
 * @returns
 */
export const CustomDataProvider = ({ apiUrl, axios, reportError }: ParamsDataProviderType) => {
  const httpCodeSuccess = [200, 201, 202, 203, 204, 205, 206, 207, 208, 226];

  const validateStatus = (status: number) => (httpCodeSuccess.indexOf(status) > -1);

  return {
    async getList(resource: string, params: ParametersCrudType): Promise<ResponseHttpType> {
      let url = `${apiUrl}/${resource}`;

      if (typeof (params.id) !== 'undefined' && params.id != null) {
        url += `/${params.id}`;
      }

      let query: any = {
        pagination: (params.pagination != null),
      };
      // ajout pagination si voulu
      if (query.pagination) {
        query = {
          ...query,
          page: params.pagination!.page,
          take: params.pagination!.take,
        };
      }
      // ajout tri
      if (params.sort) {
        query = {
          ...query,
          order: { [params.sort.field]: params.sort.order },
        };
      }
      if (params.filter) {
        // convertion des valeurs,
        const filterList: any = { ...params.filter };
        Object.keys(filterList).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(filterList, key)) {
            filterList[key] = (typeof filterList[key] === 'object' && filterList[key] != null && Object.prototype.hasOwnProperty.call(filterList[key], 'value')) ? filterList[key].value : filterList[key];
          }
        });
        query = {
          ...query,
          ...filterList,
        };
      }
      if (params.ids) {
        query = { ...query, ids: params.ids };
      }

      url += `?${qs.stringify(query, { skipNulls: true })}`;

      try {
        const r = await axios.get<any>(url, { validateStatus });
        return {
          data: r.data.data ?? r.data ?? [],
          total: r.data.total ?? r.data.length ?? 0,
          sourceData: r.data?.customData,
        };
      } catch (e) {
        reportError(e as any);
        throw e;
      }
    },

    async getOne(resource: string, params: ParametersCrudType): Promise<ResponseHttpType> {
      let url = `${apiUrl}/${resource}`;

      if (typeof (params.id) !== 'undefined' && params.id != null) {
        url += `/${params.id}`;
      }

      if (params.uriParameters) {
        url += `?${qs.stringify(params.uriParameters, { skipNulls: true })}`;
      }

      try {
        const r = await axios.get(url, { validateStatus });
        return {
          data: r.data,
          sourceData: r.data,
        };
      } catch (e) {
        reportError(e as any);
        throw e;
      }
    },

    async update(resource: string, params: ParametersCrudType): Promise<ResponseHttpType> {
      let url = `${apiUrl}/${resource}`;

      if (params.id !== undefined && params.id != null) {
        url += `/${params.id}`;
      }

      try {
        const r = await axios.put(url, params.data, { validateStatus });
        return {
          data: r.data,
        };
      } catch (e) {
        reportError(e as any);
        throw e;
      }
    },

    async create(resource: string, params: ParametersCrudType): Promise<ResponseHttpType> {
      let url = `${apiUrl}/${resource}`;
      if (params.id) {
        url += `/${params.id}`;
      }
      try {
        const r = await axios.post(url, params.data, { validateStatus });
        return {
          data: r.data,
        };
      } catch (e) {
        reportError(e as any);
        throw e;
      }
    },

    async delete(resource: string, params: ParametersCrudType) {
      const url = `${apiUrl}/${resource}/${params.id}`;
      let data = {};
      if (params.data) {
        data = params.data;
      }
      try {
        await axios.delete(url, { data, validateStatus });
      } catch (e) {
        reportError(e as any);
        throw e;
      }
    },

    async downloadFile(resource: string, params: ParametersCrudType): Promise<{data: any, headers?: AxiosResponseHeaders}> {
      const url = `${apiUrl}/${resource}`;
      try {
        const r = await axios.post(url, params ?? null, { validateStatus, responseType: 'blob' });
        return {
          data: r.data,
          headers: r.headers as any,
        };
      } catch (e) {
        reportError(e as any);
        throw e;
      }
    },

    async getDownloadFile(resource: string, params: ParametersCrudType): Promise<{data: any, headers?: AxiosResponseHeaders}> {
      let url = `${apiUrl}/${resource}`;
      if (params.id) {
        url += `/${params.id}`;
      }
      try {
        const r = await axios.get(url, { validateStatus, responseType: 'blob' });
        return {
          data: r.data,
          headers: r.headers as any,
        };
      } catch (e) {
        reportError(e as any);
        throw e;
      }
    },
  };
};
