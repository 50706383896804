import React, { useEffect, useRef, useState } from 'react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import axios from 'axios';
import dateFormat from 'dateformat';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';

import Hn from '../../components/common/Hn';
import { useEditor } from '../../components/hook/useEditor';
import { BaseContainer } from '../../components/layout/BaseContainer';
import { RouteEnum } from '../../enum/RouteEnum';
import { getYoutubeIdHelper } from '../../helpers/getYoutubeIdHelper';

import './webinar.css';

export default function UpdateWebinair1() {
  const managerRef = useRef();
  const misEnAvantRf = useRef();
  const ObligationDeFormationRef = useRef();
  const docpresent = process.env.REACT_APP_PIC_WEBINAR_FILE;
  const pdfRef = useRef();
  const [providers, setProviders] = useState([]);
  const [webinarOne, setWebinarOne] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedThemeOption, setSelectedThemeOption] = useState([]);
  const [formateurs, setFormateurs] = useState([]);
  const [themes, setThemes] = useState([]);
  const { editorConfiguration } = useEditor();
  const { goTo } = useRouter();
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = {
    Authorization: `Bearer ${authData.token}`,
  };
  const uri = process.env.REACT_APP_BASE_URL;

  const params = useParams();

  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: '16px',
      fontWeight: state.isSelected ? '600' : '400',
      backgroundColor: '#3A4048',
      color: 'white',
      border: '1px solid #006FFF',
      borderRadius: 'none',
    }),
    dropdownIndicator: (base) => ({
      color: '#006FFF',
      marginRight: '10px',
    }),
    menu: (base) => ({
      backgroundColor: '#212730',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#1E2E45' : '#212730',
      fontWeight: state.isSelected ? '600' : '400',
      borderBottom: '1px solid #3A4048',
      color: 'white',
      margin: '5px 14px 5px 10px',
    }),
    multiValue: (base, state) => ({
      ...base,
      color: 'white',
      backgroundColor: '#1E2E45',
      fontWeight: state.isSelected ? '600' : '400',
      margin: '6px 10px 5px 10px',
      padding: '5px 14px 5px 10px',
    }),
    multiValueLabel: (base) => ({
      color: 'white',
    }),
    multiValueRemove: (base) => ({
      color: '#006FFF',
      fontSize: '16px',
      height: '20px',
      width: '20px',
    }),
  };

  const handleChangecheckbox = (e) => {
    if (e.target.checked) {
      e.target.value = 1;
    } else {
      e.target.value = 0;
    }
  };

  // récupérer les formateurs
  const getFormer = (type) => {
    let url = '/former';
    if (type) {
      url = `/former/organize/${type}`;
    }
    axios
      .get(uri + url)
      .then((result) => {
        if (type) {
          setFormateurs(result?.data);
        } else {
          setFormateurs(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log('err formateurs', err);
      });
  };

  const changeList = (value) => {
    getFormer(value);
  };

  // récupérer un webinaire par son id
  function getWebinarById() {
    axios
      .get(`${uri}/webinars/${params.id}`)
      .then((result) => {
        setWebinarOne(result?.data);

        result?.data?.formers.map((former) => {
          setSelectedOption((selectedOption) => [
            ...selectedOption,
            { value: former.id, label: former.lastName },
          ]);
        });

        setSelectedThemeOption(
          result.data.themes.map((theme) => ({
            value: theme.theme.id,
            label: theme.theme.name,
          })),
        );

        if (result?.data?.forward_catalog == 1) {
          misEnAvantRf.current.checked = 1;
        } else {
          misEnAvantRf.current.checked = 0;
        }

        if (result?.data?.manager_spec == 1) {
          managerRef.current.checked = 1;
        } else {
          managerRef.current.checked = 0;
        }

        if (result?.data?.obligation == 1) {
          ObligationDeFormationRef.current.checked = 1;
        } else {
          ObligationDeFormationRef.current.checked = 0;
        }
      })
      .catch((err) => {
        console.log('err webinars', err);
      });
  }

  // modifier un webinaire
  const handleSubmit = (values) => {
    // let target = ""+values.manager+","+ values.miseavant +","+values.obligation
    const topics = [];
    selectedOption.map((op) => {
      topics.push(op.value);
    });

    const selectedThemes = [];
    selectedThemeOption.map((op) => {
      selectedThemes.push(op.value);
    });

    const data = new FormData();
    data.append('ref', values.reference);
    data.append('title', values.intitule);
    data.append('objects', values.object);
    data.append('training_program', values.program);
    // data.append('program_file',values.pdf)
    data.append('audience', values.prerequis);
    data.append('manager_spec', 0);
    data.append('forward_catalog', 0);
    data.append('obligation', 0);
    // data.append('former',[topics]);
    topics.forEach((former) => data.append('former[]', former));
    if (misEnAvantRf.current.checked) {
      data.append('forward_catalog', 1);
    }
    if (managerRef.current.checked) {
      data.append('manager_spec', 1);
    }
    if (ObligationDeFormationRef.current.checked) {
      data.append('obligation', 1);
    }
    data.append('status', values.actif);
    data.append('themes', selectedThemes);
    data.append('comment', values.commentaire);
    // data.append('imagefile', values.image);
    if (values.pdf === null) {
      data.append('programFile', webinarOne?.programFile);
    } else {
      data.append('programFile', values.pdf);
    }
    // data.append('brochure', values.pdf);
    data.append('duration', values.duree);
    data.append('cost', values.tarif);
    data.append('provider', values.prestataire);
    data.append('capacity_max', values.capacity_max);
    data.append('capacity_min', values.capacity_min);
    data.append('date', values.date);
    data.append('hour', values.heure);
    data.append('youtubeId', getYoutubeIdHelper(values.youtubeId));

    axios
      .post(`${uri}/webinars/edit/${params.id}`, data)
      .then(() => {
        goTo(RouteEnum.ADMIN_WEBINAR_SHOW, { id: params.id });
      })
      .catch((err) => {
        console.log('err training', err);
      });
  };

  // récupérer les prestataire
  function getProviders() {
    axios
      .get(`${uri}/provider`)
      .then((result) => {
        setProviders(result?.data?.data);
      })
      .catch((err) => {
        console.log('err prestataire', err);
      });
  }

  // récupérer les thémes
  function getThem() {
    axios
      .get(`${uri}/thematic`)
      .then((result) => {
        result?.data?.data.map((theme) => {
          setThemes((options) => [
            ...options,
            { value: theme.id, label: theme.name },
          ]);
        });
      })
      .catch((err) => {
        console.log('err theme', err);
      });
  }

  useEffect(() => {
    getFormer(webinarOne?.former?.organize);
    getProviders();
    getThem();
    getWebinarById();
  }, []);

  return (
    <BaseContainer>
      <div className="lists">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={RouteEnum.ADMIN_WEBINAR}>Webinaires</Link>
            </li>
            <li
              className="breadcrumb-item active"
              aria-current="page"
            >
              Modifier webinaire -
              {' '}
              {webinarOne?.title}
            </li>
          </ol>
        </nav>
        <div className="h-100 p-5 text-white bg-dark rounded-3">
          <Hn
            title="Modifier webinar"
            level="h4"
            className="h4-size"
            plus={false}
          />
          <Hn
            title="Étape 1 : Informations générales"
            level="p"
            className="py-2 fw-bold"
            plus={false}
          />

          <div className="form-elements">
            <Formik
              initialValues={{
                reference: webinarOne.ref || '',
                intitule: webinarOne.title || '',
                youtubeId: webinarOne?.youtubeId || null,
                pdf: webinarOne?.programFile,
                theme: webinarOne?.theme?.id,
                date:
                                    dateFormat(
                                      webinarOne?.date,
                                      'yyyy-mm-dd',
                                    ) || '',
                heure: webinarOne?.hour || '',
                former: webinarOne?.former?.id,
                organize: webinarOne?.former?.organize,
                prerequis: webinarOne?.audience
                  ? webinarOne?.audience
                  : '',
                object: webinarOne?.objects
                  ? webinarOne?.objects
                  : '',
                prestataire: webinarOne?.provider?.id ?? '',
                program: webinarOne?.training_program
                  ? webinarOne?.training_program
                  : '',
                duree: webinarOne?.duration || '',
                capacity_min: webinarOne?.capacity_min || 0,
                capacity_max: webinarOne?.capacity_max || 0,
                tarif: webinarOne?.cost || 0,
                actif: webinarOne?.status ? '1' : '0',
                commentaire: webinarOne?.comment
                  ? webinarOne?.comment
                  : '',
                obligation: 0,
                miseavant: 0,
                manager: 0,
              }}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => (
                <form
                  className="row g-3"
                  onSubmit={handleSubmit}
                >
                  <div className="col-md-4">
                    <label className="form-label">
                      Référence*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ref"
                      name="reference"
                      placeholder="Référence"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reference}
                      disabled
                    />
                    <ErrorMessage name="reference">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Intitulé*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="intitule"
                      placeholder="Intitulé"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.intitule}
                    />
                    <ErrorMessage name="intitule">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label
                      className="form-label"
                      htmlFor="youtubeId"
                    >
                      ID youtube
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="youtubeId"
                      name="youtubeId"
                      placeholder="https://www.youtube.com/embed/fiSlH3rApJA"
                      onChange={handleChange('youtubeId')}
                      value={values.youtubeId}
                      onBlur={handleBlur('youtubeId')}
                    />
                    <ErrorMessage name="youtubeId">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Thème*
                    </label>
                    <Select
                      value={selectedThemeOption}
                      onChange={setSelectedThemeOption}
                      styles={customStyles}
                      options={themes}
                      isMulti
                      noOptionsMessage={() => 'Aucun résultat'}
                    />
                    <ErrorMessage name="theme">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      id="date-d"
                      placeholder="Date"
                      onChange={handleChange('date')}
                      value={values.date}
                      onBlur={handleBlur('date')}
                    />

                    <ErrorMessage name="date">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Heure
                    </label>
                    <input
                      type="time"
                      className="form-control date-hour"
                      name="heure"
                      onChange={handleChange('heure')}
                      value={values.heure}
                      onBlur={handleBlur('heure')}
                    />
                    <ErrorMessage name="heure">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Formateur
                    </label>
                    <select
                      className="form-select"
                      onChange={(event) => {
                        setFieldValue(
                          'organize',
                          event.target.value,
                        );
                        changeList(event.target.value);
                      }}
                      value={values.organize}
                    >
                      <option>
                        sélectionner dans la liste
                      </option>
                      <option value="interne">
                        Interne
                      </option>
                      <option value="externe">
                        Externe
                      </option>
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label py-2" />
                    <Select
                      value={selectedOption}
                      onChange={setSelectedOption}
                      styles={customStyles}
                      isMulti
                      options={formateurs?.map(
                        (formateur) => ({
                          label: formateur.lastName,
                          value: formateur.id,
                        }),
                      )}
                    />
                    <ErrorMessage name="tarif">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="col-12">
                    <label className="form-label">
                      Pré-requis et public visé
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.prerequis}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue(
                          'prerequis',
                          data,
                        );
                      }}
                    />
                    <ErrorMessage name="prerequis">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Objectifs
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.object}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('object', data);
                      }}
                    />
                    <ErrorMessage name="object">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Programme de formation
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.program}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('program', data);
                      }}
                    />
                    <ErrorMessage name="program">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Programme de formation
                    </label>
                    <span
                      className="form-control"
                      onClick={() => pdfRef.current.click()}
                    >
                      {webinarOne?.programFile ? (
                        <a
                          href={
                            docpresent
                                                        + webinarOne?.programFile
                          }
                          className="pe-1"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Télécharger
                        </a>
                      ) : (
                        ''
                      )}
                      {' '}
                      {pdfRef?.current?.value
                        ? pdfRef?.current?.value
                        : 'Ajouter un pdf'}
                    </span>
                    <input
                      ref={pdfRef}
                      multiple={false}
                      type="file"
                      name="pdf"
                      onChange={(event) => {
                        setFieldValue(
                          'pdf',
                          event.currentTarget.files[0],
                        );
                      }}
                      hidden
                      accept=".pdf"
                    />
                    <ErrorMessage name="pdf">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Prestataire
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="prestataire"
                      onChange={handleChange(
                        'prestataire',
                      )}
                      onBlur={handleBlur}
                      value={values.prestataire}
                    >
                      <option>
                        Sélectionner dans la liste
                      </option>
                      {providers.map((provider, i) => (
                        <option
                          key={i}
                          value={provider.id}
                        >
                          {provider?.contact_name}
                          {' '}
                          |
                          {provider?.contact_surname}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage name="prestataire">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Durée théorique (en heure)*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="duree"
                      id="duree"
                      placeholder="00:00"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.duree}
                    />
                    <ErrorMessage name="duree">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Capacité d'accueil minimale
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="capacity-min"
                      placeholder="00"
                      name="capacity_min"
                      onChange={handleChange(
                        'capacity_min',
                      )}
                      value={values.capacity_min}
                      onBlur={handleBlur('capacity_min')}
                    />
                    <ErrorMessage name="tarif">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Capacité d’accueil maximale
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="capacity-max"
                      placeholder="00"
                      name="capacity_max"
                      onChange={handleChange(
                        'capacity_max',
                      )}
                      value={values.capacity_max}
                      onBlur={handleBlur('capacity_max')}
                    />
                    <ErrorMessage name="tarif">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Tarif
                    </label>
                    <input
                      type="number"
                      className="form-control cost"
                      name="tarif"
                      id="tarif"
                      placeholder="00"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tarif}
                    />
                    <ErrorMessage name="tarif">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Actif
                    </label>
                    <select
                      className="form-select"
                      name="actif"
                      aria-label="Default select example"
                      id="actif"
                      onChange={handleChange('actif')}
                      onBlur={handleBlur}
                      value={values.actif}
                    >
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                    </select>
                    <ErrorMessage name="actif">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Commentaire
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.commentaire}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue(
                          'commentaire',
                          data,
                        );
                      }}
                    />
                    <ErrorMessage name="commentaire">
                      {(msg) => (
                        <div className="text-danger">
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4 col-xs-12">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="miseavant"
                        type="checkbox"
                        id="check"
                        ref={misEnAvantRf}
                        onBlur={handleBlur}
                        value={values.miseavant}
                        onChange={(e) => handleChangecheckbox(e)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check"
                      >
                        Mettre en avant dans le
                        catalogue
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="manager"
                        type="checkbox"
                        id="check2"
                        ref={managerRef}
                        onBlur={handleBlur}
                        value={values.manager}
                        onChange={(e) => handleChangecheckbox(e)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check2"
                      >
                        Spécifique au manager
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="obligation"
                        type="checkbox"
                        id="check3"
                        ref={ObligationDeFormationRef}
                        onChange={(e) => handleChangecheckbox(e)}
                        value={values.obligation}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="check3"
                      >
                        Participe à l'obligation de
                        formation
                      </label>
                    </div>
                  </div>

                  <div className="col-12 mt-5">
                    <button
                      onClick={() => goTo(
                        RouteEnum.ADMIN_WEBINAR_SHOW,
                        { id: params.id },
                      )}
                      className="btn btn-link text-decoration-none resetBtn"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary float-end"
                    >
                      Suivant
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
}
