import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { Link, useParams } from 'react-router-dom';

import Hn from '../../../../components/common/Hn';
import { BaseContainer } from '../../../../components/layout/BaseContainer';
import { RouteEnum } from '../../../../enum/RouteEnum';

import './locationForm.css';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';

function LocationShow() {
  const params = useParams();
  const [location, setLocation] = useState([]);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  const uri = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    locationFind(params.id);
  }, []);

  // récupérer un lieu par son id
  function locationFind(id) {
    axios
      .get(`${uri}/location/${id}`)
      .then((result) => {
        setLocation(result?.data);
      })
      .catch((err) => {
        console.log('err location', err);
      });
  }
  return (
    <BaseContainer>
      <div className="banner-provider">
        <div className=" lists pe-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={RouteEnum.ADMIN_TRAINING_PLACE}>
                  Formations présentielles - Lieux de formation
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {location?.name}
              </li>
            </ol>

          </nav>
          <div className="row pe-3">

            <div className="col-md-6">
              <Hn
                title={location?.name}
                level="h3"
                className="h4-size pb-5"
                plus
              />
            </div>
            <div className="col-md-6 ">
              <RouterLink routeName={RouteEnum.ADMIN_TRAINING_PLACE_EDIT} params={{ id: location.id }} className="btn btn-primary float-end">
                Modifier
              </RouterLink>
            </div>
          </div>

          {/* lists-filter */}
          <div className="row pe-5">

            <div className="col-12">
              <div className=" p-5 text-white bg-dark rounded-3">
                <Hn
                  title={location?.name}
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />

                <div className="form-elements">

                  <div className="row g-3">

                    <div className="col-md-4">
                      <label htmlFor="ref" className="form-label">
                        Référence
                      </label>
                      <p>
                        {' '}
                        {location?.ref}
                      </p>

                    </div>
                    <div className="col-md-4">
                      <label htmlFor="nom" className="form-label">
                        Nom
                      </label>
                      <p>
                        {' '}
                        {location?.name}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="capa" className="form-label">
                        Capacité d'accueil
                      </label>
                      <p>
                        {' '}
                        {location?.capacity}
                      </p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="denomi" className="form-label">
                        Dénomination sociale
                      </label>
                      <p>
                        {location?.company_name}
                        {' '}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="adre" className="form-label">
                        Adresse
                      </label>
                      <p>
                        {' '}
                        {location?.address}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="codepos" className="form-label">
                        Code postal
                      </label>
                      <p>
                        {' '}
                        {location?.zip}
                      </p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                        Ville
                      </label>
                      <p>
                        {' '}
                        {location?.town}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="pays" className="form-label">Pays</label>
                      <p>France</p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="coutj" className="form-label">
                        Coût par jour
                      </label>
                      <p>
                        {' '}
                        {location?.cost}
                        €
                      </p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="interloc" className="form-label">
                        Nom de l'interlocuteur
                      </label>
                      <p>{location?.interlocutor_name}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="phone" className="form-label">
                        Téléphone
                      </label>
                      <p>{location?.tel}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <p>{location?.email}</p>
                    </div>

                    <div className="col-12">
                      <label htmlFor="comm" className="form-label">
                        Commentaire
                      </label>
                      <p>{location?.comment}</p>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>

  );
}

export default LocationShow;
