import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { Link, useParams } from 'react-router-dom';

import Hn from '../../components/common/Hn';
import { BaseContainer } from '../../components/layout/BaseContainer';
import { RouteEnum } from '../../enum/RouteEnum';

export default function Competence() {
  const params = useParams();
  const [competence, setCompetence] = useState([]);
  const { goTo } = useRouter();
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const uri = process.env.REACT_APP_BASE_URL;
  // récupérer une compétence par son id
  function gecompetence() {
    axios.get(`${uri}/skills/${
      params.id
    }`).then((result) => {
      setCompetence(result?.data);
    }).catch((err) => {
      console.log('err location', err);
    });
  }

  useEffect(() => {
    gecompetence(params.id);
  }, []);

  return (
    <BaseContainer>
      <div className="lists">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={RouteEnum.ADMIN_SKILL}>
                Compétences
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {
                competence.title
              }
            </li>
          </ol>
        </nav>
        <div className="headupdate">
          <Hn
            title={
              competence.title
            }
            level="h1"
            className="py-4"
          />
          <button onClick={() => goTo(RouteEnum.ADMIN_SKILL_EDIT, { id: competence.id })} className="btn btn-primary float-end me-3">Modifier</button>
        </div>

        <div className="h-100 p-5 text-white bg-dark rounded-3">
          <Hn
            title="Informations générales"
            level="h4"
            className="h4-size pb-5"
            plus={false}
          />

          <div className="form-elements">
            <div className="row">
              <div className="col-4">
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Titre du référentiel
                  </label>
                  <br />
                  <span>
                    {
                      competence.title
                    }
                  </span>
                </div>
              </div>
            </div>

            <div className="">

              <div className="row">
                <div className="col-8">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Compétences
                    </label>
                    <br />
                    {
                      competence?.subskills?.map((val, index) => (
                        <span key={index}>
                          {
                            val.name
                          }
                          <br />
                        </span>
                      ))
                    }
                    {' '}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
}
