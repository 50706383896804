import * as Yup from 'yup';

import React, { useEffect, useRef, useState } from 'react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import Hn from '../../../../../components/common/Hn';
import { useEditor } from '../../../../../components/hook/useEditor';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';
import { RouteEnum } from '../../../../../enum/RouteEnum';

import './createFaceTraining.css';
import 'react-toastify/dist/ReactToastify.css';
import { CategoryTypeEnum } from '../../../../../enum/CategoryTypeEnum';

const validationSchema = Yup.object().shape({
  reference: Yup.string().required('Champ obligatoire.'),
  intitule: Yup.string().required(),
  image: Yup.string().nullable(),
  duree: Yup.number().required(),

});
function UpdateFaceTraining() {
  const fileRef = useRef();
  const pdfRef = useRef();
  const [competence, setCompetence] = useState([]);
  const [providers, setProviders] = useState([]);
  const [trainingDetails, setTrainingDetails] = useState([]);
  const [themes, setThemes] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [elearnings, setElearnings] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const { editorConfiguration } = useEditor();
  const { goTo } = useRouter();
  const { authData } = useAuthProvider();

  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  let toastId = null;
  const uri = process.env.REACT_APP_BASE_URL;
  const uripresent = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  const docpresent = process.env.REACT_APP_PIC_FACETRAINING_FILE;

  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: '16px',
      fontWeight: state.isSelected ? '600' : '400',
      backgroundColor: '#3A4048',
      color: 'white',
      border: '1px solid #006FFF',
      borderRadius: 'none',
    }),
    dropdownIndicator: (base) => ({
      color: '#006FFF',
      marginRight: '10px',
    }),
    menu: (base) => ({
      backgroundColor: '#212730',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#1E2E45' : '#212730',
      fontWeight: state.isSelected ? '600' : '400',
      borderBottom: '1px solid #3A4048',
      color: 'white',
      margin: '5px 14px 5px 10px',
    }),
    multiValue: (base, state) => ({
      ...base,
      color: 'white',
      backgroundColor: '#1E2E45',
      fontWeight: state.isSelected ? '600' : '400',
      margin: '6px 10px 5px 10px',
      padding: '5px 14px 5px 10px',
    }),
    multiValueLabel: (base) => ({
      color: 'white',
    }),
    multiValueRemove: (base) => ({
      color: '#006FFF',
      fontSize: '16px',
      height: '20px',
      width: '20px',
    }),
  };

  // récupérer les formations présentielles par id
  function getTrainingById(id) {
    axios
      .get(`${uri}/training/${id}`)
      .then((response) => {
        setTrainingDetails(response.data);

        setSelectedOption(response.data.themes.map((theme) => ({
          value: theme.theme.id,
          label: theme.theme.name,
        })));
      })
      .catch((error) => {
        // TODO 404 page
        console.log(error.response.data);

        return error.response;
      });
  }

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: 'my_toast',
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log('Toast already active');
    }
  }

  // récupérer les compétences
  function getcompetence() {
    axios
      .get(`${uri}/skills`)
      .then((result) => {
        setCompetence(result?.data?.data);
      })
      .catch((err) => {
        console.log('err skills', err);
      });
  }

  // modifier une formation présentielle
  const handleSubmit = (values) => {
    const selectedThemes = [];
    selectedOption.map((op) => {
      selectedThemes.push(op.value);
    });

    const data = new FormData();

    data.append('ref', values.reference);
    data.append('title', values.intitule);
    data.append('duration', values.duree);
    data.append('assoc_cours', values.associe);
    data.append('themes', selectedThemes);

    data.append('objective', values.object);
    data.append('training_program', values.program);
    data.append('audience', values.prerequis);
    data.append('manager_spec', values.manager ? 1 : 0);
    data.append('forward_catalog', values.miseavant ? 1 : 0);
    data.append('obligation', values.obligation ? 1 : 0);
    data.append('horsAgence', values.horsAgence ? 1 : 0);
    data.append('status', values.etat);
    data.append('comment', values.commentaire);
    data.append('imagefile', values.image);
    data.append('brochure', values.pdf);

    data.append('cost', values.tarif);
    data.append('provider', values.prestataire);
    data.append('skills', values.skills);
    //   data.append("type_training", "presentielle");
    //   data.append("id", params.id);

    const url = `${uri}/training/edit/${params.id}`;
    axios
      .post(url, data)
      .then(() => {
        displayToast('Csuccess', 'Modification effectuée avec succès...');
        setTimeout(() => {
          goTo(RouteEnum.ADMIN_ON_SITE);
        }, 3000);
      })
      .catch(() => {
        displayToast('Cerror', "Quelque chose s'est mal passé, Réessayer");
      });
  };
    // récupérer tous les thémes
  function getThem() {
    axios
      .get(
        `${uri}/thematic?type=${CategoryTypeEnum['Présentiel']}`,
      )
      .then((result) => {
        result?.data?.data.map((theme) => {
          setThemes((options) => [
            ...options,
            { value: theme.id, label: theme.name },
          ]);
        });
      })
      .catch((err) => {
        console.log('err theme', err);
      });
  }

  function getProviders() {
    axios
      .get(`${uri}/provider`)
      .then((result) => {
        setProviders(result?.data?.data);
      })
      .catch((err) => {
        console.log('err prestataire', err);
      });
  }

  function getTrainings() {
    axios.get(`${uri}/training`)
      .then((result) => {
        setTrainings(result?.data?.data);
      })
      .catch((err) => {
        console.log('err trainning', err);
      });
  }

  function getElearnings() {
    axios.get(`${uri}/elearning`)
      .then((result) => {
        setElearnings(result?.data?.data);
      })
      .catch((err) => {
        console.log('err elearning', err);
      });
  }

  const params = useParams();
  useEffect(() => {
    getcompetence();
    getTrainingById(params.id);
    getThem();
    getProviders();
    getElearnings();
    getTrainings();
  }, []);

  return (
    <BaseContainer>
      <div className="lists">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/admin/formation-presentielle">Formations présentielles - Actions de formation</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Modifier une action de formation
            </li>
          </ol>
        </nav>
        <div className="h-100 p-5 text-white bg-dark rounded-3">
          <Hn
            title="Modifier une action de formation"
            level="h4"
            className="h4-size"
            plus={false}
          />
          <Hn
            title="Informations générales"
            level="h5"
            className="py-2 fw-bold"
            plus={false}
          />

          <div className="form-elements">
            <Formik
              initialValues={{
                reference: trainingDetails?.ref || '',
                intitule: trainingDetails?.title ? trainingDetails?.title : '',
                image: trainingDetails?.image,
                associe: trainingDetails?.assoc_cours ? trainingDetails?.assoc_cours : '',
                prerequis: trainingDetails?.audience ? trainingDetails?.audience : '',
                object: trainingDetails?.objective ? trainingDetails?.objective : '',
                program: trainingDetails?.training_program ? trainingDetails?.training_program : '',
                program_file: trainingDetails?.program_file,
                prestataire: trainingDetails?.provider?.id ?? '',
                duree: trainingDetails?.duration || '',
                tarif: trainingDetails?.cost || '',
                etat: trainingDetails?.status ? '1' : '0',
                commentaire: trainingDetails?.comment ? trainingDetails?.comment : '',
                skills: '',
                miseavant: trainingDetails?.forward_catalog,
                manager: trainingDetails?.manager_spec,
                obligation: trainingDetails?.obligation,
                horsAgence: trainingDetails?.nonAgencyCost,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-4">
                    <label className="form-label">Référence*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="ref"
                      name="reference"
                      placeholder="Référence"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reference}
                      disabled
                    />
                    <ErrorMessage name="reference">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-8">
                    <label className="form-label">Intitulé*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="intitule"
                      id="init"
                      placeholder="Intitulé"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.intitule}
                    />
                    <ErrorMessage name="intitule">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">Image*</label>
                    <span
                      className="form-control"
                      onClick={() => fileRef.current.click()}
                    >
                      <img src={uripresent + trainingDetails.image} style={{ width: '50px', height: '30px' }} className="pe-1" />
                      {' '}
                      {fileRef?.current?.value
                        ? fileRef?.current?.value
                        : 'Ajouter une image'}
                    </span>

                    <input
                      ref={fileRef}
                      multiple={false}
                      type="file"
                      name="image"
                      onChange={(event) => {
                        setFieldValue('image', event.currentTarget.files[0]);
                      }}
                      hidden
                    />
                    <ErrorMessage name="image">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                    {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Thème*</label>
                    <Select
                      value={selectedOption}
                      onChange={setSelectedOption}
                      styles={customStyles}
                      options={themes}
                      isMulti
                      noOptionsMessage={() => 'Aucun résultat'}
                    />
                    <ErrorMessage name="themes">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Parcours associé</label>

                    <select

                      className="form-select"
                      name="associe"
                      id="associe"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.associe}
                    >
                      <option value="">Sélectionner dans la liste</option>

                      {trainings.map((training, i) => (
                        <option value={training.id} key={i}>{training.title}</option>
                      ))}

                      {elearnings.map((elearning, i) => (
                        <option value={elearning.id} key={i}>{elearning.intitule}</option>
                      ))}

                    </select>
                    <ErrorMessage name="associe">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Pré-requis et public visé
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.prerequis}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('prerequis', data);
                      }}
                    />
                    <ErrorMessage name="prerequis">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">Objectifs</label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.object}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('object', data);
                      }}
                    />
                    <ErrorMessage name="object">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Programme de formation
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.program}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('program', data);
                      }}
                    />
                    <ErrorMessage name="program">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Programme de formation
                    </label>
                    <span
                      className="form-control"
                      onClick={() => pdfRef.current.click()}
                    >
                      {docpresent + trainingDetails?.programFile
                        ? (
                          <a
                            href={docpresent + trainingDetails?.programFile}
                            className="pe-1"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Télécharger
                          </a>
                        ) : ''}
                      {pdfRef?.current?.value
                        ? pdfRef?.current?.value
                        : 'Ajouter un pdf'}
                    </span>

                    <input
                      ref={pdfRef}
                      multiple={false}
                      type="file"
                      name="pdf"
                      onChange={(event) => {
                        setFieldValue('pdf', event.currentTarget.files[0]);
                      }}
                      hidden
                    />
                    <ErrorMessage name="pdf">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Prestataire</label>
                    <select
                      className="form-select"
                      name="prestataire"
                      id="prestat"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prestataire}
                    >
                      <option>Sélectionner dans la liste</option>
                      {providers.map((provider, i) => (
                        <option
                          value={provider?.id}
                          key={i}
                        >
                          {provider?.contact_surname}
                          {' '}
                          {provider?.contact_name}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage name="prestataire">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Durée théorique (en heure)*
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="duree"
                      id="duree"
                      placeholder="00:00"
                      required="required"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.duree}
                    />
                    <ErrorMessage name="duree">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">Tarif HT</label>
                    <input
                      type="number"
                      step="0.5"
                      className="form-control cost"
                      name="tarif"
                      id="tarif"
                      placeholder="00"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={trainingDetails.cost}
                      value={values.tarif}
                    />
                    <ErrorMessage name="tarif">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Actif</label>
                    <select
                      className="form-select"
                      name="etat"
                      id="etat"
                      aria-label="Default select example"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.etat}
                    >
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                    </select>
                    <ErrorMessage name="etat">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">Commentaire</label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.commentaire}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('commentaire', data);
                      }}
                    />
                    <ErrorMessage name="commentaire">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-4">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="miseavant"
                        type="checkbox"
                        id="check"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values.miseavant
                        }
                        checked={
                          values.miseavant ? 'checked' : ''
                        }
                      />
                      <label className="form-check-label" htmlFor="check">
                        Mettre en avant dans le catalogue
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="horsAgence"
                        type="checkbox"
                        id="check4"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.horsAgence}
                        checked={values.horsAgence ? 'checked' : ''}
                      />
                      <label className="form-check-label" htmlFor="check4">
                        Hors agence 2025
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="manager"
                        type="checkbox"
                        id="check2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.manager}
                        checked={values.manager ? 'checked' : ''}
                      />
                      <label className="form-check-label" htmlFor="check2">
                        Spécifique au manager
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="obligation"
                        type="checkbox"
                        id="check3"
                        onChange={handleChange}
                        value={
                          values.obligation
                        }
                        checked={values.obligation ? 'checked' : ''}
                      />
                      <label className="form-check-label" htmlFor="check3">
                        Participe à l'obligation de formation
                      </label>
                    </div>
                  </div>
                  <Hn
                    title="Compétences associées"
                    level="h5"
                    className="py-2 fw-bold"
                    plus={false}
                  />
                  <div className="col-md-6">
                    <label className="form-label">Référentiel de compétences</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="skills"
                      id="skills"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={trainingDetails?.skills?.id}
                    >
                      <option>Sélectionner dans la liste</option>
                      {competence.map((comp, i) => (
                        <option
                          value={comp.id}
                          key={i}
                        >
                          {comp?.title}

                        </option>
                      ))}
                    </select>
                    <ErrorMessage name="skills">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-12 mt-5">
                    <a
                      href="/admin/formation-presentielle"
                      type="reset"
                      className="btn btn-link text-decoration-none resetBtn"
                    >
                      Annuler
                    </a>
                    <button
                      type="submit"
                      className="btn btn-primary float-end"
                    >
                      Suivant
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
}

export default UpdateFaceTraining;
