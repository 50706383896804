import React, { useEffect, useState } from 'react';

import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import ProfileAttestations from './ProfileAttestations';
import { ProfileBanner } from './ProfileBanner';
import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { UserDto } from '../../../model/dto/UserDto';
import { BaseContainer } from '../../layout/BaseContainer';

export function ProfilePage() {
  const { authData } = useAuthProvider()!;
  const [currentUserId, setCurrentUserId] = useState(authData.id);

  const dsUser = useDataSource<UserDto>({
    resource: ResourceDataProviderEnum.participant,
    singleResult: true,
    noLoadAfterInit: true,
  });

  useEffect(() => {
    dsUser.setParams({ id: currentUserId });
  }, [currentUserId]);

  const handleUserChange = (currentUserIdState: string) => {
    setCurrentUserId(currentUserIdState);
  };

  return (
    <BaseContainer wrapperClassName="profile">
      <h1 className="plus mb-4">Mon Profil</h1>
      <ProfileBanner user={dsUser?.oneData} handleUserChange={handleUserChange} />
      <ProfileAttestations user={dsUser.oneData} />
    </BaseContainer>
  );
}
