import React, { ReactElement, useState } from 'react';

import {
  SimpleColumn,
} from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { CustomFilterDataProviderType } from '../../../../model/dataProvider/CustomFilterDataProviderType';
import { DataTableBulksActions } from '../../../dataTable/DataTableBulksActions';
import { DataTableHeaderActions } from '../../../dataTable/DataTableHeaderActions';
import { DataTableListActions } from '../../../dataTable/DataTableListActions';

export function TrainerDataTable(): ReactElement {
  const [filters, setFilters] = useState<CustomFilterDataProviderType>({});
  const dsTrainers = useDataSource({
    resource: ResourceDataProviderEnum.trainer,
    params: {
      pagination: { page: 1, take: 50 },
    },
  });
  const exportNameFile = 'formateurs.csv';

  return (
    <DataTable
      dataSource={dsTrainers}
      filter={(
        <Filter>
          <InputField name="search" label="Rechercher" onChangeValue={(v) => setFilters({ search: v })} />
        </Filter>
      )}
      actionHeader={(
        <DataTableHeaderActions
          addRoute={RouteEnum.ADMIN_TRAINER_ADD}
          addRouteValue="Ajouter un formateur"
          importResource={ResourceDataProviderEnum.trainerImport}
          exportResource={ResourceDataProviderEnum.trainerExport}
          exportNameFile={exportNameFile}
          exportFilters={filters}
          dataSource={dsTrainers}
        />
      )}
      actionRow={(row) => [
        <DataTableListActions
          key={0}
          row={row}
          dataSource={dsTrainers}
          readRoute={RouteEnum.ADMIN_TRAINER_SHOW}
          deleteResource={ResourceDataProviderEnum.trainer}
          editRoute={RouteEnum.ADMIN_TRAINER_EDIT}
        />,
      ]}
      onChangeCheckedRow={(rows: {id: number|string}[], cleanSelection) => (
        <DataTableBulksActions
          rows={rows}
          dataSource={dsTrainers}
          deleteResource={ResourceDataProviderEnum.trainer}
          exportResource={ResourceDataProviderEnum.trainerExport}
          exportNameFile={exportNameFile}
          cleanSelection={cleanSelection}
        />
      )}
    >
      <SimpleColumn field="firstName" title="Nom" sortable />
      <SimpleColumn field="lastName" title="Prénom" sortable />
      <SimpleColumn field="organize" title="Interne" sortable />
      <SimpleColumn field="tel" title="Téléphone" sortable />
      <SimpleColumn field="email" title="Email" sortable />
    </DataTable>
  );
}
