import React, { ReactElement } from 'react';

import { ProviderDataTable } from './ProviderDataTable';
import { BaseContainer } from '../../../layout/BaseContainer';

export function ProviderListPage(): ReactElement {
  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Formations présentielles</h1>
        <h2 className="mt-4 plus">Prestataires</h2>
      </div>
      <div className="pt-5">
        <ProviderDataTable />
      </div>
    </BaseContainer>
  );
}
