import React, { ReactElement, useEffect, useState } from 'react';

import { SimpleColumn } from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { SelectField } from 'leeloo-react/component/form/field/SelectField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { FilterDataProviderType } from 'leeloo-react/model/dataProvider/FilterDataProviderType';
import { ParametersCrudType } from 'leeloo-react/model/dataProvider/ParametersCrudType';

import { CategoryTypeEnum } from '../../../../enum/CategoryTypeEnum';
import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { CustomFilterDataProviderType } from '../../../../model/dataProvider/CustomFilterDataProviderType';
import { CategoryDto } from '../../../../model/dto/CategoryDto';
import { ListColumn } from '../../../dataTable/column/ListColumn';
import { DataTableBulksActions } from '../../../dataTable/DataTableBulksActions';
import { DataTableHeaderActions } from '../../../dataTable/DataTableHeaderActions';
import { DataTableListActions } from '../../../dataTable/DataTableListActions';

export function ThematicDataTable(): ReactElement {
  const [filters, setFilters] = useState<CustomFilterDataProviderType>({});

  const dsThematics = useDataSource({
    resource: ResourceDataProviderEnum.thematic,
    params: {
      pagination: { page: 1, take: 50 },
    },
  });

  // on va récupérer la liste de mes catégories
  const dsCategories = useDataSource({
    resource: ResourceDataProviderEnum.category,
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (filters?.type) {
      dsCategories.updateFilter(
        { type: filters.type },
      );
    }
  }, [filters?.type]);

  return (
    <DataTable
      dataSource={dsThematics}
      filter={(
        <Filter
          onChangeBeforeFilter={(dataFilter: FilterDataProviderType, finalParams: ParametersCrudType) => {
            if (finalParams.filter && filters.type !== finalParams.filter.type) {
              finalParams.filter.category = null;
            }
            // on renvoie les paramètres en entier
            return finalParams;
          }}
        >
          <InputField name="search" label="Rechercher" onChangeValue={(v) => setFilters({ ...filters, search: v })} />
          <SelectField<CategoryDto, CategoryDto>
            name="type"
            label="Type de catégorie"
            optionsEnum={CategoryTypeEnum}
            onChangeValue={(v) => setFilters({ ...filters, type: v, category: null })}
          />
          {(filters?.type || filters?.category) && (
            <SelectField<any, CategoryDto>
              label="Catégorie"
              name="category"
              externalResource={{
                dataSource: dsCategories,
                labelKey: 'name',
                valueKey: 'id',
              }}
              onChangeValue={(v) => setFilters({ ...filters, category: v })}
            />
          )}
        </Filter>
      )}
      actionHeader={(
        <DataTableHeaderActions
          addRoute={RouteEnum.ADMIN_THEMATIC_ADD}
          addRouteValue="Ajouter une thématique"
          exportFilters={filters}
          dataSource={dsThematics}
        />
      )}
      actionRow={(row) => [
        <DataTableListActions
          key={0}
          row={row}
          dataSource={dsThematics}
          readRoute={RouteEnum.ADMIN_THEMATIC_SHOW}
          deleteResource={ResourceDataProviderEnum.thematic}
          duplicateResource={ResourceDataProviderEnum.thematicDuplicate}
          editRoute={RouteEnum.ADMIN_THEMATIC_EDIT}
        />,
      ]}
      onChangeCheckedRow={(rows: {id: number|string}[], cleanSelection) => (
        <DataTableBulksActions
          rows={rows}
          dataSource={dsThematics}
          deleteResource={ResourceDataProviderEnum.thematic}
          duplicateResource={ResourceDataProviderEnum.thematicDuplicate}
          cleanSelection={cleanSelection}
        />
      )}
    >
      <SimpleColumn field="name" title="Nom" sortable />
      <ListColumn field="categories" fieldColumns="category.name" fieldKey="category.id" title="Catégories" />
      <ListColumn field="lorem" realField="categories" fieldColumns="position" fieldKey="category.id" title="Position" />
      <ListColumn field="ipsum" realField="categories" fieldColumns="category.typeName" fieldKey="category.id" title="Type" />
    </DataTable>
  );
}
