import React, { ReactElement, useEffect } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../../enum/ResourceDataProviderEnum';
import { GlobalStatsType } from '../../../../../model/kpis/GlobalStatsType';
import { KpiType } from '../../../../../model/kpis/KpiType';
import Barchart from '../../../../chart/Barchart';
import { RouterCustomFileLink } from '../../../../router/RouterCustomFileLink';

export function KpiGlobal({ dateRange }: KpiType): ReactElement {
  const dsGlobal = useDataSource<GlobalStatsType>({
    resource: ResourceDataProviderEnum.kpisGlobal,
    singleResult: true,
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (dateRange) {
      const params = {
        uriParameters: {
          startAt: dateRange?.startAt || null,
          endAt: dateRange?.endAt || null,
        },
      };

      dsGlobal.setParams(params);
    }
  }, [dateRange]);

  return (
    <div className="p-3 bg-dark-grey">
      <div className="d-flex justify-content-between">
        <h4>Vision globale</h4>
        <RouterCustomFileLink
          resource={ResourceDataProviderEnum.kpisGlobalExport}
          params={{
            data: {
              startAt: dateRange?.startAt || null,
              endAt: dateRange?.endAt || null,
            },
          }}
          nameFile="dashboard_global.csv"
          className="btn btn-outline-primary square"
        >
          <Icon name="uil uil-export" />
        </RouterCustomFileLink>
      </div>
      <Barchart
        label="Sessions"
        backgroundColor={['#703AFE']}
        labels={['Dispensées', 'Annulées', 'Danger']}
        data={[
          dsGlobal?.oneData?.dispense ?? 0,
          dsGlobal?.oneData?.annule ?? 0,
          dsGlobal?.oneData?.danger ?? 0,
        ]}
      />
    </div>
  );
}
