import React, { ReactElement } from 'react';

import { ELearningDataTable } from './ELearningDataTable';
import { BaseContainer } from '../../../layout/BaseContainer';

export function ELearningListPage(): ReactElement {
  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Formations E-learning</h1>
        <h2 className="mt-4 plus">Actions de formation</h2>
      </div>
      <div className="pt-5">
        <ELearningDataTable />
      </div>
    </BaseContainer>
  );
}
