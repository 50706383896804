import React, { useState } from 'react';

import { PopupTags } from '../popup/PopupTags';
import {BadgeCustomColor} from "leeloo-react/component/display/BadgeCustomColor";

type Tags = string[];

export function FormationTags(props : { className: string, tags: Tags, updateTags?: (newTags: Tags) => void}) {

  const [showModal, setShowModal] = useState<boolean>(false);

  /**
   * Fermeture de la modale
   */
  const closeModal = () => {
    setShowModal(false);
  }

  /**
   * Détermine si on a au moins un tag present
   */
  const hasAtLeastOneTag = () : boolean => props.tags.length > 0 && props.tags[0] !== '';

  /**
   * Détermine si on est en mode edition
   */
  const isInEditionMode = () : boolean => props.updateTags !== undefined;

  /**
   * Détermine si on affiche le label au dessus des tags
   * Il est toujours affiché en mode édition
   * Il est affiché en mode consultation s'il y a au moins un tag
   */
  const displayTagLabel = () : boolean => props.tags && ( isInEditionMode() || hasAtLeastOneTag() )

  return (
    <div className={props.className + " tags"}>
      { displayTagLabel() && <label className="form-label">
        Tags
      </label>}
      { props.updateTags && <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={() => setShowModal(true)}
      >
        Editer
      </button>
      }
      <div>
        {props.tags && props.tags.map((tag : string, index : number) =>
          <BadgeCustomColor
            backgroundColor="#3a4048"
            text={tag}
            key={index}
          />
        )}
      </div>
      {props.tags && props.updateTags &&
        <PopupTags
          tags={props.tags}
          showModal={showModal}
          closeModal={closeModal}
          updateTags={props.updateTags}
        />
      }
    </div>
  )

}
