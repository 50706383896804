import React, { ReactElement } from 'react';

import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ELearningDto } from '../../model/dto/ELearningDto';
import { OnSiteDto } from '../../model/dto/OnSiteDto';
import { ThematicDto } from '../../model/dto/ThematicDto';
import { FormationCard } from '../formation/FormationCard';

type ThematicListProps = {
    thematic: ThematicDto
    resource: string
    statusField: string
    eLearning?: boolean
}

export function ThematicList(props: ThematicListProps): ReactElement {
  // on va récupérer la liste de mes formations dans une thématique donnée
  const dsFormations = useDataSource<ELearningDto|OnSiteDto>({
    resource: props.resource,
    params: {
      filter: {
        thematic: props.thematic.id,
        [`${props.statusField}`]: true,
      },
    },
  });

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dsFormations.total > 0 && (
        <div className="row">
          {dsFormations.dataList.map((formation) => (
            <div key={formation.id} className="col-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mb-4">
              <FormationCard
                formation={formation}
                eLearning={props.eLearning}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

ThematicList.defaultProps = {
  eLearning: true,
};
