import React, { ReactElement, useState } from 'react';

import { EnumColumn } from 'leeloo-react/component/dataTable/column/EnumColumn';
import { SimpleColumn } from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { SelectField } from 'leeloo-react/component/form/field/SelectField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { CategoryTypeEnum } from '../../../../enum/CategoryTypeEnum';
import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { CustomFilterDataProviderType } from '../../../../model/dataProvider/CustomFilterDataProviderType';
import { DataTableBulksActions } from '../../../dataTable/DataTableBulksActions';
import { DataTableHeaderActions } from '../../../dataTable/DataTableHeaderActions';
import { DataTableListActions } from '../../../dataTable/DataTableListActions';

export function CategoryDataTable(): ReactElement {
  const [filters, setFilters] = useState<CustomFilterDataProviderType>({});
  const dsCategories = useDataSource({
    resource: ResourceDataProviderEnum.category,
    params: {
      pagination: { page: 1, take: 50 },
    },
  });

  return (
    <DataTable
      dataSource={dsCategories}
      filter={(
        <Filter>
          <InputField name="search" label="Rechercher" onChangeValue={(v) => setFilters({ search: v })} />
          <SelectField name="type" label="Type" onChangeValue={(v) => setFilters({ type: v })} optionsEnum={CategoryTypeEnum} />
        </Filter>
      )}
      actionHeader={(
        <DataTableHeaderActions
          addRoute={RouteEnum.ADMIN_CATEGORY_ADD}
          addRouteValue="Ajouter une catégorie"
          exportFilters={filters}
          dataSource={dsCategories}
        />
      )}
      actionRow={(row) => [
        <DataTableListActions
          key={0}
          row={row}
          dataSource={dsCategories}
          readRoute={RouteEnum.ADMIN_CATEGORY_SHOW}
          deleteResource={ResourceDataProviderEnum.category}
          editRoute={RouteEnum.ADMIN_CATEGORY_EDIT}
        />,
      ]}
      onChangeCheckedRow={(rows: {id: number|string}[], cleanSelection) => (
        <DataTableBulksActions
          rows={rows}
          dataSource={dsCategories}
          deleteResource={ResourceDataProviderEnum.category}
          cleanSelection={cleanSelection}
        />
      )}
    >
      <SimpleColumn field="name" title="Nom" sortable />
      <SimpleColumn field="categoryOrder" title="Ordre" sortable />
      <EnumColumn enum={CategoryTypeEnum} field="type" title="Type" sortable />
    </DataTable>
  );
}
