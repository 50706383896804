import React from 'react';

import { DataSourceType } from 'leeloo-react/component/hook/useDataSource';

import { TrainingRequestDto } from '../../../model/dto/TrainingRequestDto';
import { DataTableArchiveButton } from '../button/DataTableArchiveButton';
import { DataTableExportButton } from '../button/DataTableExportButton';
import { DataTableUnarchiveButton } from '../button/DataTableUnarchiveButton';
import { DataTableValidateButton } from '../button/DataTableValidateButton';

type Props<T> = {
    rows: (T & TrainingRequestDto)[],
    validateResource?: string,
    archiveResource?: string,
    unarchiveResource?: string,
    exportResource?: string,
    exportNameFile?: string,
    dataSource?: DataSourceType,
    cleanSelection?: () => void,
    data?: {[key in string]: any},
}

export function DataTableTrainingRequestBulksActions(props: Props<any>) {
    let validateButton : '' | JSX.Element | undefined | null = props.validateResource && (
        <DataTableValidateButton resource={props.validateResource} {...props} />
    );
    let archiveButton : '' | JSX.Element | undefined | null = props.archiveResource && (
        <DataTableArchiveButton resource={props.archiveResource} {...props} />
    );
    let unarchiveButton : '' | JSX.Element | undefined | null = props.unarchiveResource && (
        <DataTableUnarchiveButton resource={props.unarchiveResource} {...props} />
    );

    if (props.rows.length === 0) {
        validateButton = null;
        archiveButton = null;
        unarchiveButton = null;
    }

    props.rows.forEach((row) => {
        if (!row.canValidate) {
            validateButton = null;
        }
        if (!row.canArchive) {
            archiveButton = null;
        }
        if (!row.canUnarchive) {
            unarchiveButton = null;
        }
    });

    return (
        <>
            {validateButton}
            {archiveButton}
            {unarchiveButton}
            {props.exportResource && (
                <DataTableExportButton resource={props.exportResource} nameFile={props.exportNameFile ?? ''} {...props} />
            )}
        </>
    );
}

DataTableTrainingRequestBulksActions.defaultProps = {
    dataSource: null,
    validateResource: null,
    archiveResource: null,
    unarchiveResource: null,
    exportResource: null,
    exportNameFile: null,
    cleanSelection: null,
    data: null,
};
