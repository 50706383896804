import React, { useEffect, useState } from 'react';

import { useTrans } from 'leeloo-react/component/hook/useTrans';
import { ParametersCrudType } from 'leeloo-react/model/dataProvider/ParametersCrudType';
import { PropsUseDataProviderType } from 'leeloo-react/model/dataProvider/PropsUseDataProviderType';
import { Spinner } from 'react-bootstrap';

import { useCustomDownloader } from '../hook/useCustomDownloader';

type Props = {
    resource: string,
    params: ParametersCrudType,
    /** Nom du fichier a télécharger avec l'extension exemple : 'mon-fichier.pdf' */
    nameFile: string,
    className?: string,
    children?: any,
}

/**
 * Génère un bouton + la possibilité de télécharger un fichier disponible sur l'API
 * La différence par rapport a un lien classique est pour l'injection de la partie sécurité +
 * la prise en charge de téléchargement en AJAX (loader etc...)
 */
export function RouterCustomFileLink(props: Props & PropsUseDataProviderType) {
  const [isLoad, setIsLoad] = useState(false);
  const { downloadFile } = useCustomDownloader({ ...props });
  const { t } = useTrans();

  useEffect(() => {
    if (isLoad) {
      downloadFile(props.resource, props.nameFile, props.params).then(() => setIsLoad(false));
    }
  }, [isLoad]);

  return (
    <button type="button" onClick={() => setIsLoad(true)} className={props.className}>
      {!isLoad && (
      // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {props.children || t('actions.download')}
        </>
      )}
      {isLoad && (
        <Spinner animation="border" size="sm" className="mr-1" />
      )}
    </button>
  );
}

RouterCustomFileLink.defaultProps = {
  className: '',
  children: null,
};
