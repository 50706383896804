import { ELearningDto } from '../../model/dto/ELearningDto';
import React, { ReactElement } from 'react';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useSelector } from 'react-redux';
import { StateType } from '../../model/store/StateType';
import { OnSiteDto } from '../../model/dto/OnSiteDto';

export function DropdownItem(props: {formation:ELearningDto | OnSiteDto}): ReactElement {

  const { goTo } = useRouter();
  const config = useSelector((s: StateType) => s.config);

  const checkValidity = (formation : ELearningDto | OnSiteDto) => {
    let elearning : boolean = ('intitule' in formation);
    if ( !elearning || config.accessELearning) {
      goTo(`/${elearning ? 'detailformations' : 'detailformation'}/${props.formation.id}`);
    }
  };

  const getLabel = (formation : ELearningDto | OnSiteDto) => {
    return ('intitule' in formation) ? formation.intitule : formation.title;
  }


  return (
    <a href="#" onClick={()=>checkValidity(props.formation)}>{getLabel(props.formation)}</a>
  );

}
