import React, { useState } from 'react';

import { ModalForm } from 'leeloo-react/component/form/ModalForm';
import { useDataProvider } from 'leeloo-react/component/hook/useDataProvider';
import { useToast } from 'leeloo-react/component/hook/useToast';

import { ResourceDataProviderEnum } from '../../enum/ResourceDataProviderEnum';
import { ELearningDto } from '../../model/dto/ELearningDto';
import { RatingDto } from '../../model/dto/RatingDto';
import { RatingModalFooter } from '../rating/RatingModalFooter';

type Props = {
    show: boolean,
    elearning: ELearningDto,
    onHide:() => void,
}

/**
 * Composant pour noter une formation elearning
 */
export function PopupRating(props: Props) {
    const dp = useDataProvider();
    const [rating, setRating] = useState(0);
    const toast = useToast();
    const existingRates = [1, 2, 3, 4, 5];

    /**
     * Prise en compte de la confirmation du vote
     */
    const handleConfirm = () => {
        const data : RatingDto = { rating, elearningId: props.elearning.id };
        dp.create!(
            ResourceDataProviderEnum.eLearningRating,
            { data },
        ).then((result) => {
            if (result?.data.id) {
                toast('Votre note a bien été prise en compte.', 'success');
                props.onHide();
            } else {
                // Cas d'erreur où la modale reste ouverte pour laisser à
                // l'utilisateur la possibilité de retenter un vote
                toast('Votre note n\'a pas pu être prise en compte.', 'error');
            }
        }).catch(() => {
            toast('Votre note n\'a pas pu être prise en compte.', 'error');
            props.onHide();
        });
    };

    /**
     * Définit la couleur d'une étoile
     */
    const getColor = (item:number) => (item <= rating ? 'rgb(255, 188, 11)' : 'rgb(105, 105, 105)');

    return (
        <ModalForm
            show={props.show}
            handleClose={props.onHide}
            initialValue={rating}
            title="Quelle note attribuez-vous à cette formation ?"
            btnValidLabel="Confirmer"
            customFooter={
                (
                    <RatingModalFooter
                        disabled={rating === 0}
                        handleConfirm={handleConfirm}
                    />
                )
            }
        >
            {
                existingRates.map((item) => (
                    <button
                        type="button"
                        className="btn rating-star"
                        key={item}
                        onClick={() => setRating(item)}
                    >
                        <svg
                            stroke={getColor(item)}
                            fill={getColor(item)}
                            viewBox="0 0 24 24"
                            width="40"
                            height="40"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                        </svg>
                    </button>
                ))
            }
        </ModalForm>
    );
}
