import React, { useEffect, useState } from 'react';

import axios from 'axios';
import {
  Formik, Form, Field, FieldArray, ErrorMessage,
} from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { Link, useParams } from 'react-router-dom';

import Add from '../../assets/icons/add.png';
import Close from '../../assets/icons/Close.png';
import Hn from '../../components/common/Hn';
import { BaseContainer } from '../../components/layout/BaseContainer';
import { RouteEnum } from '../../enum/RouteEnum';

export default function Updatecompetence() {
  const params = useParams();
  const [competence, setcompetence] = useState([]);
  const uri = process.env.REACT_APP_BASE_URL;
  const { goTo } = useRouter();
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  // modifier une compétence
  const handleSubmit = (values) => {
    const ix = [];
    values?.subskills?.map((item) => {
      { item?.id ? ix.push(item.name) : ix.push(item); }
    });

    const data = {
      title: values.title,
      subskills: ix,
    };

    axios
      .put(
        `${uri}/skills/edit/${
          values.id}`,
        data,
      )
      .then((result) => {
        goTo(RouteEnum.ADMIN_SKILL);
      })
      .catch((err) => {
        console.log('err training', err);
      });
  };
    // récupérer une compétence par son id
  function gecompetence(id) {
    axios
      .get(
        `${uri}/skills/${params.id}`,
      )
      .then((result) => {
        setcompetence(result?.data);
        console.log('result?.data', result?.data);
      })
      .catch((err) => {
        console.log('err location', err);
      });
  }

  useEffect(() => {
    gecompetence(params.id);
  }, []);

  return (
    <BaseContainer>
      <div className="lists">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={RouteEnum.ADMIN_SKILL}>
                Compétences
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Modifier une compétence
            </li>
          </ol>
        </nav>
        <div className="h-100 p-5 text-white bg-dark rounded-3">
          <Hn
            title="Modifier une compétence"
            level="h4"
            className="h4-size pb-5"
            plus={false}
          />

          <div className="form-elements">
            <Formik
              enableReinitialize
              initialValues={{
                id: competence.id,
                title: competence.title,
                subskills: competence?.subskills,
              }}
              onSubmit={handleSubmit}
              // validationSchema={Yup.object().shape({
              //   subskills: Yup.array(),
              //   title: Yup.string(),
              //   id: Yup.string(),
              // })}
              render={({ values, errors, handleChange }) => (
                <Form>
                  <div className="row">
                    <div className="col-7">
                      <div className="mb-3">
                        <label htmlFor="" className="form-label">
                          Titre de référentiel
                        </label>
                        <Field
                          className="form-control"
                          name="title"
                          placeholder="Titre"
                          onChange={handleChange}
                          defaultvalue={competence.title}
                        />
                        <ErrorMessage
                          className="form-text text-danger"
                          name="title"
                        />
                      </div>
                      <Field
                        className="form-control"
                        name="id"
                        type="hidden"
                        defaultvalue={competence.id}
                      />
                      <Field
                        className="form-control"
                        name="slug"
                        type="hidden"
                        defaultvalue={competence.slug}
                      />
                    </div>
                  </div>
                  <div className="row" />
                  <label htmlFor="" className="form-label">
                    Compétences
                  </label>

                  <FieldArray
                    name="subskills"
                    render={(arrayHelpers) => (
                      <div className="">
                        {values?.subskills?.map((val, index) => (
                          <div className="row" key={index}>
                            <div className="col-8">
                              <div className="mb-3">
                                <Field
                                  className="form-control"
                                  name={`subskills.${index}`}
                                  placeholder="Sous compétence"
                                  onChange={handleChange}
                                  defaultvalue={val.name}
                                  value={val.name}
                                />
                                <ErrorMessage
                                  className="form-text text-danger"
                                  name={`subskills.${index}`}
                                />
                              </div>
                            </div>
                            <div className="col-2 mt-4">
                              <span
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <img
                                  src={Close}
                                  className="pe-1"
                                  style={{
                                    marginTop: '-34px',
                                    marginLeft: '-10px',
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        ))}

                        <span
                          onClick={() => arrayHelpers.insert(values.subskills.length, {
                            subskills: '',
                          })}
                          style={{ color: '#0d6efd' }}
                        >
                          <img src={Add} className="pe-1" />
                          Ajouter une compétence
                        </span>
                        <div className="col-12 pt-5">
                          <Link to={RouteEnum.ADMIN_SKILL} className="btn btn-link">
                            Annuler
                          </Link>
                          <button
                            type="submit"
                            className="btn btn-primary float-end"
                          >
                            Valider
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </Form>
              )}
            />
          </div>
        </div>
      </div>
      {/* lists */}
    </BaseContainer>
  );
}
