import React, { useContext, useEffect } from 'react';

import { SelectField } from 'leeloo-react/component/form/field/SelectField';
import { ContextForm } from 'leeloo-react/component/form/Form';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { CategoryTypeEnum } from '../../../../enum/CategoryTypeEnum';
import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { CategoryDto } from '../../../../model/dto/CategoryDto';
import { ThematicCategoryDto } from '../../../../model/dto/ThematicCategoryDto';

export function ThematicCategoryForm() {
  const { values, onChangeFieldsValues } = useContext(ContextForm);
  const valueForm = values as ThematicCategoryDto;

  // on va récupérer mon total de catégorie dans un type donné
  const dsCategories = useDataSource({
    resource: ResourceDataProviderEnum.category,
    noLoadAfterInit: true,
  });

  // on va récupérer mon total de catégorie dans un type donné
  const dsThematics = useDataSource({
    resource: ResourceDataProviderEnum.thematic,
    params: {
      pagination: { page: 1, take: 1 },
    },
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (valueForm?.category?.type) {
      dsCategories.updateFilter(
        { type: valueForm.category.type },
      );
    }
  }, [valueForm?.category?.type]);

  useEffect(() => {
    if (valueForm?.category?.id) {
      dsThematics.updateFilter(
        { category: valueForm.category.id },
      );
    }
  }, [valueForm?.category?.id]);

  return (
    <div className="row">
      <SelectField<any, CategoryTypeEnum>
        className="col-md-4 mb-3"
        name="category.type"
        label="Type de catégorie"
        isClearable={false}
        optionsEnum={CategoryTypeEnum}
        onChangeValue={(v) => {
          onChangeFieldsValues({ 'category.id': null, position: null, 'category.type': v });
        }}
      />
      {valueForm?.category?.type && (
        <SelectField<any, CategoryDto>
          label="Catégorie"
          name="category.id"
          className="col-md-4 mb-3"
          isClearable={false}
          externalResource={{
            dataSource: dsCategories,
            labelKey: 'name',
            valueKey: 'id',
          }}
          onChangeValue={(v) => {
            onChangeFieldsValues({ 'category.id': v, position: null });
          }}
        />
      )}
      {valueForm?.category?.id && (
        <SelectField<any, number[]>
          className="col-md-4 mb-3"
          name="position"
          label="Position dans la catégorie"
          optionsList={[...Array(dsThematics.total ? dsThematics.total + 1 : 1).keys()].map((i) => {
            const number = i + 1;
            return { label: `${number}`, value: number };
          })}
          isClearable={false}
        />
      )}
    </div>
  );
}
