import React from 'react';

import Table from 'react-bootstrap/Table';

import { InscritADemandeDto } from '../../model/dto/InscritADemandeDto';

type Props = {
    trainingTitle : string,
    inscrits: InscritADemandeDto [],
}

export default function PopupDemandeDejaEnregistree(props : Props) {
    /**
     * Le titre de la demande de formation est non renseigné si le participant est déjà
     *   inscrit à la demande de formation courante
     * @param inscrit
     */
    const getTrainingTitle = (inscrit : InscritADemandeDto) : string => inscrit.trainingRequest.title ?? props.trainingTitle;

    return (
        <div className="col-12">
            <h5>
                Vous ne pouvez pas faire de nouvelle demande pour ce participant
                sur cette formation car une demande est déjà en attente de traitement dans cette région.
            </h5>
            <Table striped hover>
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Formation</th>
                        <th>Région</th>
                        <th>Date de la demande</th>
                    </tr>
                </thead>
                <tbody>
                    {props.inscrits.map((inscrit) => {
                        const createdAt = (new Date(inscrit.trainingRequest.createdAt))?.toLocaleDateString('fr', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        });
                        return (
                            <tr className="text-white textBox"
                                key={inscrit.ContactID}>
                                <td>
                                    {inscrit.contactNom}
                                </td>
                                <td>
                                    {inscrit.contactPrenom}
                                </td>
                                <td>
                                    {getTrainingTitle(inscrit)}
                                </td>
                                <td>
                                    {inscrit.region.name}
                                </td>
                                <td>
                                    {`Le ${createdAt}`}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}
