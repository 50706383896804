import React, { useState } from 'react';

import { SimpleColumn } from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { useRouter } from 'leeloo-react/component/hook/useRouter';

import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { CustomFilterDataProviderType } from '../../../../model/dataProvider/CustomFilterDataProviderType';
import { DataTableShowButton } from '../../../dataTable/button/DataTableShowButton';
import { DataTableBulksActions } from '../../../dataTable/DataTableBulksActions';
import { DataTableHeaderActions } from '../../../dataTable/DataTableHeaderActions';

type LearnerDataTableProps = {
    resource: string,
}

export function LearnerDataTable(props: LearnerDataTableProps) {
  const { currentRouteName } = useRouter();
  const [filters, setFilters] = useState<CustomFilterDataProviderType>({
    search: '',
    search_agency: '',
  });

  const handleFilterChange = (name: string) => (value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const dsLearners = useDataSource({
    resource: props.resource,
    params: {
      pagination: { page: 1, take: 50 },
    },
  });
  const exportNameFile = currentRouteName === RouteEnum.ADMIN_E_LEARNING_LEARNER ? 'apprenants.csv' : 'participants.csv';

  return (
    <DataTable
      dataSource={dsLearners}
      filter={(
        <Filter>
          <InputField name="search" label="Rechercher par nom/prénom" value={filters.search} onChangeValue={handleFilterChange('search')} />
          <InputField name="search_agency" label="Rechercher par agence" value={filters.search_agency} onChangeValue={handleFilterChange('search_agency')} />
        </Filter>
      )}
      actionHeader={(
        <DataTableHeaderActions
          exportResource={currentRouteName === RouteEnum.ADMIN_E_LEARNING_LEARNER ? ResourceDataProviderEnum.learnerExportElearning : ResourceDataProviderEnum.learnerExportTrainings}
          exportNameFile={exportNameFile}
          exportFilters={filters}
        />
      )}
      actionRow={(row) => [
        <DataTableShowButton resource={currentRouteName === RouteEnum.ADMIN_E_LEARNING_LEARNER ? RouteEnum.ADMIN_ELEARNER_SHOW : RouteEnum.ADMIN_LEARNER_SHOW} row={row} variant="outline-primary" key={0} />,
      ]}
      onChangeCheckedRow={(rows: {id: number|string}[], cleanSelection) => (
        <DataTableBulksActions
          rows={rows}
          exportResource={currentRouteName === RouteEnum.ADMIN_E_LEARNING_LEARNER ? ResourceDataProviderEnum.learnerExportElearning : ResourceDataProviderEnum.learnerExportTrainings}
          exportNameFile={exportNameFile}
          cleanSelection={cleanSelection}
        />
      )}
    >
      <SimpleColumn field="contactNom" title="Nom" sortable />
      <SimpleColumn field="contactPrenom" title="Prénom" sortable />
      <SimpleColumn field="mailcontact" title="Email" sortable />
      <SimpleColumn field="agencymain" title="Agent" sortable />
      <SimpleColumn field="agenceEmail" title="Email Agent" sortable />
      <SimpleColumn field="offre" title="Offre" sortable />
      <SimpleColumn field="metier" title="Role" sortable />
    </DataTable>
  );
}
