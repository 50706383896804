import React, { ReactElement } from 'react';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { ELearningDto } from '../../../model/dto/ELearningDto';
import { FormationCard } from '../../formation/FormationCard';
import { useSplide } from '../../hook/useSplide';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { RouteEnum } from '../../../enum/RouteEnum';

export function ELearningProfile(): ReactElement {
  const { defaultConfig } = useSplide();

  // on va récupérer la liste des formations pour mon profil
  const dsFormations = useDataSource<ELearningDto>({
    resource: ResourceDataProviderEnum.eLearningMyProfile,
    params: {
      pagination: { page: 1, take: 10 },
    },
  });

  return (
    <>
      {dsFormations.total > 0 && (
        <section className="mb-5 my-profile">
          <div className="row">
            <div className="col-md-7">
              <h3 className="plus mb-4">Pour votre profil</h3>
            </div>
            <div className="col-md-5">
              {dsFormations.total >=6 && (
                <span className="more-info">
                  <RouterLink routeName={RouteEnum.MY_PROFILE}>
                    Voir tout
                  </RouterLink>
                </span>
              )}
            </div>
          </div>
          <div className="thematic-carousel">
            <Splide options={defaultConfig}>
              {dsFormations.dataList.map((formation) => (
                <SplideSlide key={formation.id}>
                  <FormationCard
                    formation={formation}
                  />
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </section>
      )}
    </>
  );
}
