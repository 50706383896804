import React from 'react';

import {
  ArcElement,
  Chart as ChartJS,
  Color,
  Legend,
  Tooltip,
  ChartOptions,
  ChartDataset, ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

type Props = {
    backgroundColor?: Color[],
    data?: number[],
    datasets?: ChartDataset<'bar', (number | [number, number] | null)[]>[]
    label?: string,
    labels?: string[],
    options?: ChartOptions,
}

export default function Barchart(props: Props) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const data:ChartData<'bar'> = {
    labels: props.labels?.map((item) => item) ?? [''],
    datasets: props.datasets ?? [
      {
        label: props.label,
        data: props.data ?? [],
        backgroundColor: props.backgroundColor,
      },
    ],
  };

  return (
    <Bar data={data} options={props.options} />
  );
}

Barchart.defaultProps = {
  backgroundColor: null,
  data: null,
  datasets: null,
  label: null,
  labels: null,
  options: null,
};
