import React from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { DataSourceType } from 'leeloo-react/component/hook/useDataSource';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { Dropdown } from 'react-bootstrap';

import { useDtAction } from '../hook/useDtAction';

type Props<T> = {
    row: T & {id: any},
    dataSource?: DataSourceType,
    deleteResource?: string,
    duplicateResource?: string,
    editRoute?: string,
    readRoute?: string,
}

export function DataTableListActions(props: Props<any>) {
  const { goTo } = useRouter();
  const { duplicateRow, deleteRow } = useDtAction();

  return (
    <Dropdown key={0}>
      <Dropdown.Toggle variant="outline-primary">
        Action
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props.readRoute && (
          <Dropdown.Item
            onClick={() => goTo(props.readRoute ?? '', { id: props.row.id })}
          >
            <Icon name="uil uil-eye" additionalClasses="me-1" />
            Détails
          </Dropdown.Item>
        )}
        {props.editRoute && (
          <Dropdown.Item
            onClick={() => goTo(props.editRoute ?? '', { id: props.row.id })}
          >
            <Icon name="edit" additionalClasses="me-1" />
            Editer
          </Dropdown.Item>
        )}
        {props.duplicateResource && props.dataSource && (
          <Dropdown.Item onClick={() => duplicateRow({
            row: props.row,
            resource: props.duplicateResource ?? '',
            dataSource: props.dataSource,
          })}
          >
            <Icon name="uil uil-layers" additionalClasses="me-1" />
            Dupliquer
          </Dropdown.Item>
        )}
        {props.deleteResource && props.dataSource && (
          <Dropdown.Item onClick={() => deleteRow({
            row: props.row,
            resource: props.deleteResource ?? '',
            dataSource: props.dataSource,
          })}
          >
            <span className="text-danger">
              <Icon name="delete" additionalClasses="me-1" />
              Supprimer
            </span>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

DataTableListActions.defaultProps = {
  dataSource: null,
  deleteResource: null,
  duplicateResource: null,
  editRoute: null,
  readRoute: null,
};
