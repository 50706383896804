import React, { ReactElement } from 'react';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';

import { RouteEnum } from '../../enum/RouteEnum';
import { ELearningDto } from '../../model/dto/ELearningDto';
import { OnSiteDto } from '../../model/dto/OnSiteDto';
import { ThematicDto } from '../../model/dto/ThematicDto';
import { FormationCard } from '../formation/FormationCard';
import { useSplide } from '../hook/useSplide';

type ThematicCarouselProps = {
    thematic: ThematicDto
    resource: string
    statusField: string
    eLearning?: boolean
}

export function ThematicCarousel(props: ThematicCarouselProps): ReactElement {
  const { defaultConfig } = useSplide();

  // on va récupérer la liste de mes formations dans une thématique donnée
  const dsFormations = useDataSource<ELearningDto|OnSiteDto>({
    resource: props.resource,
    params: {
      filter: {
        thematic: props.thematic.id,
        [`${props.statusField}`]: true,
      },
      pagination: { page: 1, take: 12 },
    },
  });

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dsFormations.total > 0 && (
        <div className="thematic-carousel with-title">
          <div className="title-wrapper">
            <div className="title">{props.thematic.name}</div>
            {dsFormations.total >= 6 && (
              <RouterLink routeName={RouteEnum.THEMATIC_LIST} params={{ category: props.eLearning ? 'elearning' : 'training', id: props.thematic.id }} className="d-none d-md-block">
                Voir tout
              </RouterLink>
            )}
          </div>
          <Splide options={defaultConfig}>
            {dsFormations.dataList.map((formation) => (
              <SplideSlide key={formation.id}>
                <FormationCard
                  formation={formation}
                  eLearning={props.eLearning}
                />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      )}
    </>
  );
}

ThematicCarousel.defaultProps = {
  eLearning: true,
};
