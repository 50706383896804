import React from 'react';

import { ColumnType } from 'leeloo-react/model/dataTable/ColumnType';

type Props<T> = ColumnType<T> & {
    fieldColumns: string|string[],
    fieldKey?: string,
    realField?: string,
}

const resolvePath = (path: string | string[], obj: any, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

export function ListColumn<T>(props: Props<T>) {
  const mapThrough = props.realField && props.row ? (props.row as any)[props.realField] : props.value;

  return (
    <ul className="list-unstyled mb-0">
      {mapThrough && mapThrough.map((v:any) => (
        <li key={props.fieldKey ? resolvePath(props.fieldKey, v) : Math.random()}>
          {resolvePath(props.fieldColumns, v)}
        </li>
      ))}
    </ul>
  );
}

ListColumn.defaultProps = {
  fieldKey: null,
  realField: null,
};
