import React from 'react';

import {
  ArcElement,
  Chart as ChartJS,
  ChartDataset,
  ChartOptions,
  Color,
  Legend,
  Tooltip,
} from 'chart.js';

import Barchart from './Barchart';

type Props = {
    backgroundColor?: Color[],
    data?: number[],
    datasets?: ChartDataset<'bar', (number | [number, number] | null)[]>[]
    label?: string,
    labels?: string[],
    options?: ChartOptions,
}

export default function HorizontalBarchart(props: Props) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = { ...props.options, indexAxis: 'y' as const };

  return (
    <Barchart {...props} options={options} />
  );
}

HorizontalBarchart.defaultProps = {
  backgroundColor: null,
  data: null,
  datasets: null,
  label: null,
  labels: null,
  options: null,
};
