import * as Yup from 'yup';

import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useToast } from 'leeloo-react/component/hook/useToast';
import { ToastContainer } from 'react-toastify';

import Add from '../../../../../assets/icons/add.png';
import Hn from '../../../../../components/common/Hn';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';

import 'react-toastify/dist/ReactToastify.css';

const validationSchema = Yup.object().shape({});

function UpdateSettings() {
  const [settings, setSettings] = useState([]);
  const fileRef = useRef();
  const { authData } = useAuthProvider();
  const toast = useToast();

  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  const uriSettings = process.env.REACT_APP_PIC_SETTINGS_IMG;

  const uri = process.env.REACT_APP_BASE_URL;

  function getSettings() {
    axios
      .get(`${uri}/settings`)
      .then((result) => {
        setSettings(result?.data);
      })
      .catch((err) => {
        console.log('err settings', err);
      });
  }

  const handleSubmit = (values) => {
    const data = new FormData();
    data.append('search_image', values.image);

    axios
      .post(`${uri}/settings/edit`, data)
      .then((result) => {
        getSettings();
        toast('Modification effectuée', 'success');
      })

      .catch((err) => {
        console.log('err setting', err);
      });
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <BaseContainer>
      <ToastContainer />
      <div className="lists-title">
        <h1>Paramètres</h1>
      </div>
      <div className="pt-5">
        <div className="form-elements">
          <Formik
            initialValues={{
              image: settings?.search_image,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              /* and other goodies */
            }) => (
              <div
                className="h-100 p-5 text-white bg-dark rounded-3"
              >
                <Hn
                  title="Modification de l'image de la recherche"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
                <form
                  className="row g-3"
                  onSubmit={handleSubmit}
                >
                  <div className="col-md-4">
                    <label
                      htmlFor="image"
                      className="form-label"
                    >
                      Image
                    </label>
                    <span
                      className="form-control"
                      onClick={() => fileRef.current.click()}
                    >
                      {!settings.search_image ? (
                        <img src={Add} className="pe-1" />
                      ) : (
                        <img
                          src={settings && settings.search_image ? uriSettings + settings.search_image : null}
                          className="pe-1"
                          style={{
                            width: '50px',
                            height: '30px',
                          }}
                        />
                      )}
                      {' '}
                      {fileRef?.current?.value
                        ? fileRef?.current?.value
                        : (settings && settings.search_image)
                          ? settings.search_image
                          : 'Ajouter une image'}
                    </span>
                    <input
                      ref={fileRef}
                      multiple={false}
                      type="file"
                      name="image"
                      onChange={(event) => {
                        setFieldValue('image', event.currentTarget.files[0]);
                      }}
                      hidden
                      accept=".png,.jpg,.gif"
                    />
                    <p className="formatImg">
                      Format : jpg,
                      png, gif / 10 mo max
                    </p>
                    <ErrorMessage name="image">
                      {(msg) => (
                        <div
                          className="text-danger"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 mt-5">
                    <button
                      type="submit"
                      className="btn btn-primary float-end"
                    >
                      Sauvegarder
                    </button>

                  </div>
                </form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </BaseContainer>
  );
}

export default UpdateSettings;
