import { useState } from 'react';
import { ResourceDataProviderEnum } from '../enum/ResourceDataProviderEnum';
import { useDataProvider } from 'leeloo-react/component/hook/useDataProvider';
import { ELearningDto } from '../model/dto/ELearningDto';

/**
 * Gestion de l'ouverture et fermeture des modales de notation des formations elearning
 * @param myCallback : callback  déclenchée à la fermeture de la modale de notation
 */
export function useRatingModal(myCallback = () => {} ) {
  const [ratingModal, setRatingModal] = useState(false);
  const dp = useDataProvider();

  /**
   * Affichage la modale de notation des formations après vérification
   */
  const showRatingModal = (elearning : ELearningDto) => {
    if (dp.getOne) {
      dp.getOne(
        ResourceDataProviderEnum.eLearningCanRate,
        { id: elearning.id }
      )
        .then((result) => {
          if (result.data) {
            // Déclenche l'ouverture de la modale de notation des formations
            setRatingModal(true);
          } else {
            myCallback();
          }
        })
        .catch(() => {
          myCallback();
        });
    }
  }

  /**
   * Masque la modale de notation des formations
   */
  const hideRatingModal = () => {
    setRatingModal(false);
    myCallback();
  }

  return {ratingModal, hideRatingModal, showRatingModal };
}
