import React from 'react';

import { SimpleContainer } from '../../layout/SimpleContainer';

export function MaintenancePage() {
  return (
    <SimpleContainer>
      <div className="row justify-content-center align-self-center">
        <div className="col-sm-6 col-lg-5">
          <div className="card">
            <div className="card-head">
              <h1 className="text-center p-3">Le site est actuellement en maintenance</h1>
            </div>
            <div className="card-footer mb-3">
              <div className="text-center p-3">Veuillez réessayer ultérieurement</div>
            </div>
          </div>
        </div>
      </div>
    </SimpleContainer>
  );
}
