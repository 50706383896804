import axios from 'axios';

const uri = process.env.REACT_APP_BASE_URL;
const headers = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

// const GetData = (async (url) => {
//   try {
//     await axios.get(`${uri}/${url}`, headers)
//     .then(response => {
//       console.log(response.data);
//     })
//     .catch((error) => {
//       console.log(error.response.data);
//     });
//   } catch (err) {
//     console.log("error catch");
//   }

// });

// const GetData = async (url) => {
//   try {

//     await axios.get(`${uri}/${url}`, headers)
//     .then((response) => {
//       console.log(response.data);
//       //return response;
//     })
//     .catch((error) => {
//       console.log(error.response.data);
//       //return error.response;
//     });

//   } catch (err) {
//     console.log("error");
//   }
// }

async function get(url) {
  await axios.get(`${uri}/${url}`, headers)
    .then((response) => response)
    .catch((error) => {
      console.log(error.response.data);
      return error.response;
    });
}
function axiosGet(url) {
  new Promise((resolve, reject) => {
    axios
      .get(`${uri}/${url}`, headers)
      .then((response) => {
        resolve(response?.data);
        return response?.data;
      })
      .catch((error) => {
        reject(error);
        return error;
      });
  });
}

const create = async (url, data) => await axios
  .post(`${uri}/${url}`, data, headers);
const put = async (url, data) => await axios
  .post(`${uri}/${url}`, data, headers);

export default { create, get, put };
