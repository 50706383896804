import * as Yup from 'yup';

import React, { useEffect, useRef, useState } from 'react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { ProgressBar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';

import Hn from '../../../../../components/common/Hn';
import { useEditor } from '../../../../../components/hook/useEditor';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';

import 'react-toastify/dist/ReactToastify.css';
import { CategoryTypeEnum } from '../../../../../enum/CategoryTypeEnum';

import { FormationTags } from '../../../../../components/admin/FormationTags';

const validationSchema = Yup.object().shape({
  reference: Yup.string().required('La référence est obligatoire'),
  intitule: Yup.string().required("L'intitulé de formation est obligatoire"),
  parcours_associe: Yup.string().nullable(),
  prerequis: Yup.string().nullable(),
  object: Yup.string().nullable(),
  program: Yup.string().nullable(),
  validation: Yup.string().required('Le taux de validation est obligatoire'),

  duree: Yup.number().required('La durée de la formation est obligatoire'),
  commentaire: Yup.string().nullable(),
});

function UpdateElearning() {
  const fileRef = useRef();
  const pdfRef = useRef();
  const [elearning, setElearning] = useState([]);
  const [themes, setThemes] = useState([]);
  const { id } = useParams();
  const [elearnings, setElearnings] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const managerRef = useRef();
  const misEnAvantRf = useRef();
  const ObligationDeFormationRef = useRef();
  const { editorConfiguration } = useEditor();
  const navigate = useNavigate();
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  const uri = process.env.REACT_APP_BASE_URL;
  const uriElarning = process.env.REACT_APP_PIC_ELEARNING_IMG;
  const docElarning = process.env.REACT_APP_PIC_ELEARNING_FILE;

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [selectedOption, setSelectedOption] = useState([]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: '16px',
      fontWeight: state.isSelected ? '600' : '400',
      backgroundColor: '#3A4048',
      color: 'white',
      border: '1px solid #006FFF',
      borderRadius: 'none',
    }),
    dropdownIndicator: (base) => ({
      color: '#006FFF',
      marginRight: '10px',
    }),
    menu: (base) => ({
      backgroundColor: '#212730',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#1E2E45' : '#212730',
      fontWeight: state.isSelected ? '600' : '400',
      borderBottom: '1px solid #3A4048',
      color: 'white',
      margin: '5px 14px 5px 10px',
    }),
    multiValue: (base, state) => ({
      ...base,
      color: 'white',
      backgroundColor: '#1E2E45',
      fontWeight: state.isSelected ? '600' : '400',
      margin: '6px 10px 5px 10px',
      padding: '5px 14px 5px 10px',
    }),
    multiValueLabel: (base) => ({
      color: 'white',
    }),
    multiValueRemove: (base) => ({
      color: '#006FFF',
      fontSize: '16px',
      height: '20px',
      width: '20px',
    }),
  };

  // Récupérer la liste des formations elearning
  function getElearnings() {
    axios
      .get(`${uri}/elearning`)
      .then((result) => {
        setElearnings(result?.data?.data);
      })
      .catch((err) => {
        console.log('err location', err);
      });
  }

  // Récupérer le détail de la formation elearning récupérée par (id)
  function getElearning() {
    axios
      .get(`${uri}/elearning/${id}`)
      .then((response) => {
        setElearning(response?.data);

        setSelectedOption(response.data.themes.map((theme) => ({
          value: theme.theme.id,
          label: theme.theme.name,
        })));

        if (response?.data?.mis_en_avant == 1) {
          misEnAvantRf.current.checked = true;
        } else {
          misEnAvantRf.current.checked = false;
        }

        if (response?.data?.manager == 1) {
          managerRef.current.checked = true;
        } else {
          managerRef.current.checked = false;
        }

        if (response?.data?.obligation_de_formation == 1) {
          ObligationDeFormationRef.current.checked = true;
        } else {
          ObligationDeFormationRef.current.checked = false;
        }
      })
      .catch((error) => {
        // TODO 404 page
        console.log('Erreur', error.response.data);
        return error.response;
      });
  }

  // Récupérer la liste des formations présentielles

  function getTrainings() {
    axios
      .get(`${uri}/training`)
      .then((result) => {
        setTrainings(result?.data?.data);
      })
      .catch((err) => {
        console.log('err location', err);
      });
  }

  // lister toutes les thématiques
  function getThem() {
    axios.get(`${uri}/thematic?type=${CategoryTypeEnum['e-learning']}`)
      .then((result) => {
        result?.data?.data.map((theme) => {
          setThemes((options) => [
            ...options,
            { value: theme.id, label: theme.name },
          ]);
        });
      })
      .catch((err) => {
        console.log('err theme', err);
      });
  }

  // Changer la valeur d'un checkbox

  const handleChangecheckbox = (e) => {
    if (e.target.checked) {
      e.target.value = 1;
    } else {
      e.target.value = 0;
    }
  };

  const handleSubmit = (values) => {
    const headersFiles = {
      onUploadProgress(progressEvent) {
        const { loaded, total } = progressEvent;

        const percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setUploadPercentage(percent);
        }
      },
    };

    const selectedThemes = [];
    selectedOption.map((op) => {
      selectedThemes.push(op.value);
    });

    const data = new FormData();
    data.append('ref', values.reference);
    data.append('intitule', values.intitule);
    data.append('objectifs', values.object);

    data.append('programme_de_formation', values.program);
    data.append('prerequis', values.prerequis);
    data.append('parcours_associe', values.parcours_associe);

    data.append('mis_en_avant', 0);
    data.append('manager', 0);
    data.append('obligation_de_formation', 0);

    if (misEnAvantRf.current.checked) {
      data.append('mis_en_avant', 1);
    }
    if (managerRef.current.checked) {
      data.append('manager', 1);
    }
    if (ObligationDeFormationRef.current.checked) {
      data.append('obligation_de_formation', 1);
    }

    data.append('actif', values.etat === '1' ? 1 : 0);
    data.append('themes', selectedThemes);
    data.append('validation', values.validation);

    data.append('commentaire', values.commentaire);
    data.append('image', values.image);

    data.append('duree', values.duree);
    values.tags.map((tag,index) => {
      data.append('tags[]', tag);
    })


    if (typeof values.pdf === 'undefined') {
      data.append('fichier_programme', values.fichier_programme);
    } else {
      data.append('fichier_programme', values.pdf);
    }

    axios
      .post(`${uri}/elearning/edit/${id}`, data, headersFiles)
      .then((result) => {
        setUploadPercentage(100);

        setTimeout(() => {
          setUploadPercentage(0);
        }, 1000);

        navigate(`/admin/creer-chapitre-e-learning/${result?.data?.id}`);
      })

      .catch((err) => {
        console.log('err training', err);
      });
  };

  useEffect(() => {
    getElearning();
    getElearnings();
    getTrainings();
    getThem();
  }, []);

  /**
   * Met à jour les tags à la fermeture de la modale d'édition de tags
   * @param newTags
   */
  const updateTags = (newTags) => {
    setElearning((elearning)=>({...elearning,tags:newTags}))
  }

  return (
    <BaseContainer>
      <ToastContainer />
      <div className="row">
        <div className="container lists pe-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/formation-e-learning">Formations e-learning - Actions de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Modifier une action de formation
              </li>
            </ol>
          </nav>
          <div className="p-5 text-white bg-dark rounded-3">
            <Hn
              title="Modifier une action de formation"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <Hn
              title="Étape 1 : Informations générales"
              level="p"
              className="py-2 fw-bold"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  reference: elearning?.ref || '',
                  intitule: elearning?.intitule || '',
                  image: elearning?.image,
                  theme: [],
                  parcours_associe: elearning?.parcours_associe,
                  prerequis: elearning?.prerequis ? elearning?.prerequis : '',
                  object: elearning?.objectifs ? elearning?.objectifs : '',
                  program: elearning?.programme_de_formation ? elearning?.programme_de_formation : '',
                  fichier_programme: elearning?.fichier_programme,
                  duree: elearning?.duree || '',
                  etat: elearning?.actif ? '1' : '0',
                  commentaire: elearning?.commentaire ? elearning?.commentaire : '',
                  miseavant: 0,
                  manager: '0',
                  validation: elearning?.validation || 0,
                  obligation: 0,
                  tags: elearning?.tags
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-4">
                      <label htmlFor="ref" className="form-label">
                        Référence*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ref"
                        name="reference"
                        placeholder="Référence"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reference}
                      />
                      <ErrorMessage name="reference">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="init" className="form-label">
                        Intitulé*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="intitule"
                        id="init"
                        placeholder="Intitulé"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.intitule}
                      />
                      <ErrorMessage name="intitule">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <FormationTags className="col-md-4" tags={elearning.tags} updateTags={updateTags}></FormationTags>
                    <div className="col-md-4">
                      <label htmlFor="image" className="form-label">
                        Image
                      </label>
                      <span
                        className="form-control"
                        onClick={() => fileRef.current.click()}
                      >
                        <img
                          src={uriElarning + elearning.image}
                          className="pe-1"
                          style={{ width: '50px', height: '30px' }}
                        />
                        {' '}
                        {fileRef?.current?.value
                          ? fileRef?.current?.value
                          : 'Ajouter une image'}
                      </span>

                      <input
                        ref={fileRef}
                        multiple={false}
                        type="file"
                        name="image"
                        onChange={(event) => {
                          setFieldValue('image', event.currentTarget.files[0]);
                        }}
                        hidden
                        accept=".png,.jpg,.gif"
                      />
                      <ErrorMessage name="image">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                      {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="theme" className="form-label">
                        Thème*
                      </label>
                      <Select
                        value={selectedOption}
                        onChange={setSelectedOption}
                        styles={customStyles}
                        options={themes}
                        isMulti
                        noOptionsMessage={() => 'Aucun résultat'}
                      />
                      <ErrorMessage name="themes">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="parcours_associe" className="form-label">
                        Parcours associé
                      </label>

                      <select
                        className="form-select"
                        name="parcours_associe"
                        id="parcours_associe"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.parcours_associe}
                      >
                        <option defaultValue>Sélectionner dans la liste</option>

                        {elearnings.map((learning, i) => (
                          <option value={learning.id} key={i}>
                            {learning.intitule}
                          </option>
                        ))}

                        {trainings.map((train, i) => (
                          <option value={train.id} key={i}>
                            {train.title}
                          </option>
                        ))}
                      </select>

                      <ErrorMessage name="parcours_associe">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12">
                      <label htmlFor="pre" className="form-label">
                        Pré-requis et public visé
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={values.prerequis}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue('prerequis', data);
                        }}
                      />
                      <ErrorMessage name="prerequis">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label htmlFor="object" className="form-label">
                        Objectifs
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={values.object}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue('object', data);
                        }}
                      />
                      <ErrorMessage name="object">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label htmlFor="preg" className="form-label">
                        Programme de la formation
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={values.program}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue('program', data);
                        }}
                      />
                      <ErrorMessage name="program">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="prog" className="form-label">
                        Programme de la formation
                      </label>
                      <span
                        className="form-control"
                        onClick={() => pdfRef.current.click()}
                      >
                        {elearning?.fichier_programme !== null
                                                    && elearning?.fichier_programme != 'null' && (
                                                    <a
                            href={docElarning + elearning?.fichier_programme}
                            className="pe-1"
                          >
                            Télécharger
                          </a>
                        )}

                        {pdfRef?.current?.value
                          ? pdfRef?.current?.value
                          : 'Ajouter un pdf'}
                      </span>

                      <input
                        ref={pdfRef}
                        multiple={false}
                        type="file"
                        name="pdf"
                        onChange={(event) => {
                          setFieldValue('pdf', event.currentTarget.files[0]);
                        }}
                        hidden
                        accept=".pdf"
                      />
                      <ErrorMessage name="pdf">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>

                      {values.pdf && uploadPercentage > 0 && (
                        <div className="row mt-3">
                          <div className="col pt-1">
                            <ProgressBar
                              now={uploadPercentage}
                              striped
                              label={`${uploadPercentage}%`}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="duree" className="form-label">
                        Durée théorique (en minutes)*
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="duree"
                        id="duree"
                        placeholder="60"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duree}
                      />
                      <ErrorMessage name="duree">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="actif" className="form-label">
                        Actif
                      </label>
                      <select
                        className="form-select"
                        name="etat"
                        id="actif"
                        aria-label="Default select example"
                        value={values.etat}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="1">Oui</option>
                        <option value="0">Non</option>
                      </select>
                      <ErrorMessage name="etat">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label htmlFor="comment" className="form-label">
                        Commentaire
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={values.commentaire}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue('commentaire', data);
                        }}
                      />
                      <ErrorMessage name="commentaire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="validation" className="form-label">
                        Validation
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="validation"
                        id="validation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.validation}
                      />
                      <ErrorMessage name="validation">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4" />
                    <div className="col-md-4" />

                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          ref={misEnAvantRf}
                          name="miseavant"
                          type="checkbox"
                          id="miseavant"
                          onChange={(e) => handleChangecheckbox(e)}
                        />
                        <label className="form-check-label" htmlFor="miseavant">
                          Mettre en avant dans le catalogue
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          ref={managerRef}
                          name="manager"
                          type="checkbox"
                          id="manager"
                          onChange={(e) => handleChangecheckbox(e)}
                        />
                        <label className="form-check-label" htmlFor="manager">
                          Spécifique au manager
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          ref={ObligationDeFormationRef}
                          name="obligation"
                          type="checkbox"
                          id="obligation"
                          onChange={(e) => handleChangecheckbox(e)}
                        />
                        <label className="form-check-label" htmlFor="obligation">
                          Participe à l'obligation de formation
                        </label>
                      </div>
                    </div>

                    <div className="col-12 mt-5">
                      <a
                        type="reset"
                        className="btn btn-link text-decoration-none"
                        href="/admin/formation-e-learning"
                      >
                        Annuler
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                      >
                        Suivant
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </BaseContainer>
  );
}

export default UpdateElearning;
