import React, { useMemo } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useTrans } from 'leeloo-react/component/hook/useTrans';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import Collapse from 'react-bootstrap/Collapse';

import { MenuType } from './MenuLink';

type Props = {
    classNameLiGroup?: string,
    classNameUl?: string,
    classNameLi?: string,
    classNameLink?: string,
    isActive?: boolean,
    onShow?: () => any,
    onHide?: () => any
}

/**
 * Représente un élément du menu du router
 */
export function MenuItem(props: MenuType & Props) {
  const { t } = useTrans();
  const { currentRouteName } = useRouter();

  const isContainSubRoute = useMemo(() => props.routes.length > 1, [props.routes]);

  const classNameLi = useMemo(() => {
    const r = (props.classNameLiGroup) ? [props.classNameLiGroup] : [];
    return r.join(' ');
  }, [props.classNameLiGroup, isContainSubRoute]);

  const activeParentRoute = useMemo(() => (props.routes.some((r) => r.routeUrl === currentRouteName)), []);

  return (
    <li className={classNameLi} key={props.group.label}>
      {!isContainSubRoute ? (
        <RouterLink className={props.classNameLink} icon={props.group.icon} routeName={props.routes[0].routeUrl}><span>{t(props.routes[0].label)}</span></RouterLink>
      ) : (
        <>
          <div
            role="menuitem"
            tabIndex={0}
            className={`${props.classNameLink} ${(activeParentRoute) ? 'active' : ''}`}
            onClick={props.isActive ? props.onHide : props.onShow}
            onKeyDown={props.isActive ? props.onHide : props.onShow}
            aria-expanded={props.isActive}
          >
            {props.icon && <Icon name={props.icon} additionalClasses="me-1" />}
            <span>{t(props.group.label)}</span>
          </div>
          <Collapse in={props.isActive}>
            <ul className={props.classNameUl}>
              {props.routes.map((rc) => (
                <li role="menuitem" className={props.classNameLi} key={rc.routeUrl}>
                  <RouterLink className={`sub-link ${props.classNameLink}`} icon={rc.icon} routeName={rc.routeUrl}><span>{t(rc.label)}</span></RouterLink>
                </li>
              ))}
            </ul>
          </Collapse>
        </>
      )}
    </li>
  );
}

MenuItem.defaultProps = {
  classNameLiGroup: null,
  classNameUl: null,
  classNameLi: null,
  classNameLink: null,
  isActive: false,
  onShow: null,
  onHide: null,
};
