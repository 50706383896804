import React, { ReactElement } from 'react';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { OnSiteDto } from '../../../model/dto/OnSiteDto';
import { FormationCard } from '../../formation/FormationCard';
import { useSplide } from '../../hook/useSplide';

export function OnSiteMostViewed(): ReactElement {
  const { defaultConfig } = useSplide();
  // on va récupérer la liste de mes formations les plus vues
  const dsFormations = useDataSource<OnSiteDto>({
    resource: ResourceDataProviderEnum.trainingMostViewed,
  });

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dsFormations.total > 0 && (
        <section className="thematic-carousel-wrapper mb-5">
          <h3 className="plus mb-4">Top 10 des formations</h3>
          <div className="thematic-carousel top-10">
            <Splide options={defaultConfig}>
              {dsFormations.dataList.map((formation, index) => (
                <SplideSlide key={formation.id}>
                  <FormationCard
                    formation={formation}
                    eLearning={false}
                    number={index + 1}
                  />
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </section>
      )}
    </>
  );
}
