import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { ErrorMessage, Field, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import Hn from '../../../../components/common/Hn';
import { BaseContainer } from '../../../../components/layout/BaseContainer';
import { RouteEnum } from '../../../../enum/RouteEnum';
import 'react-toastify/dist/ReactToastify.css';
import './provider.css';

const countryList = require('./data.json');

const validationSchema = Yup.object().shape({
  nom: Yup.string().required('Champ obligatoire.'),
  ref: Yup.string().required('Champ obligatoire.'),
  email_contact: Yup.string().email('Adresse email invalide.'),
});

function CreatePrestataire() {
  let toastId = null;
  const [displayexiste, setDisplayexiste] = useState(false);
  const [options, setOptions] = useState([]);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const uri = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: '16px',
      fontWeight: state.isSelected ? '600' : '400',
      backgroundColor: '#3A4048',
      color: 'white',
      border: '1px solid #006FFF',
      borderRadius: 'none',
    }),
    dropdownIndicator: (base) => ({
      color: '#006FFF',
      marginRight: '10px',
    }),
    menu: (base) => ({
      backgroundColor: '#212730',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#1E2E45' : '#212730',
      fontWeight: state.isSelected ? '600' : '400',
      borderBottom: '1px solid #3A4048',
      color: 'white',
      margin: '5px 14px 5px 10px',
    }),
    multiValue: (base, state) => ({
      ...base,
      color: 'white',
      backgroundColor: '#1E2E45',
      fontWeight: state.isSelected ? '600' : '400',
      margin: '6px 10px 5px 10px',
      padding: '5px 14px 5px 10px',
    }),
    multiValueLabel: (base) => ({
      color: 'white',
    }),
    multiValueRemove: (base) => ({
      color: '#006FFF',
      fontSize: '16px',
      height: '20px',
      width: '20px',
    }),
  };

  // créer un nouveau prestataire
  const handleSubmit = (values) => {
    const myProvider = {
      ref: values.ref,
      name: values.nom,
      topic_interv: values.theme,
      address: values.adresse,
      zip: values.codeP,
      city: values.ville,
      country: values.pays,
      website: values.site,
      contact_name: values.nom_contact,
      contact_surname: values.prenom_contact,
      tel: values.tel_contact,
      email: values.email_contact,
      comment: values.commentraire_contact,
    };

    axios
      .post(`${uri}/provider/new`, myProvider)
      .then((result) => {
        if (result.data === 'ref_exist') {
          displayToast('Cerror', 'Référence existe dans notre système');
        } else {
          displayToast(
            'Csuccess',
            'Ajout effectué avec succès, Redirection...',
          );
          setTimeout(() => {
            navigate(RouteEnum.ADMIN_PROVIDER);
          }, 3000);
        }
      })
      .catch((err) => {
        if (err.response.data === 'ref_exist') {
          setDisplayexiste(true);
          displayToast('Cerror', 'Référence existe dans notre système');
        }
      });
  };

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: 'my_toast',
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log('Toast already active');
    }
  }
  // récupérer les thémes d'intervention du prestataire
  function getThem() {
    axios
      .get(`${uri}/thematic`)
      .then((result) => {
        result?.data?.data.map((theme) => {
          setOptions((options) => [
            ...options,
            { value: theme.id, label: theme.name },
          ]);
        });
      })
      .catch((err) => {
        console.log('err theme', err);
      });
  }

  useEffect(() => {
    getThem();
  }, []);

  return (
    <BaseContainer>
      <div className="lists p-5">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={RouteEnum.ADMIN_PROVIDER}>
                Formations présentielles - Prestataires
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Ajouter un prestataire
            </li>
          </ol>
        </nav>
        <div className="p-5 text-white bg-dark rounded-3 me-2">
          <Hn
            title="Ajouter un prestataire"
            level="h4"
            className="h4-size pb-5"
            plus={false}
          />

          <div className="form-elements">
            <Formik
              initialValues={{
                ref: '',
                nom: '',
                theme: [],
                adresse: '',
                codeP: '',
                ville: '',
                pays: '',
                site: '',
                nom_contact: '',
                prenom_contact: '',
                tel_contact: '',
                email_contact: '',
                commentraire_contact: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit} className="row g-3">
                  <div className="col-md-4">
                    <label htmlFor="init" className="form-label">
                      Réference*
                    </label>
                    <input
                      type="text"
                      name="ref"
                      className="form-control"
                      id="init"
                      placeholder="Référence"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ref}
                    />
                    {displayexiste && (
                      <div className="text-danger ref_exist">
                        Référence existe déjà
                      </div>
                    )}
                    <ErrorMessage name="ref">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="init" className="form-label">
                      Nom*
                    </label>
                    <input
                      type="text"
                      name="nom"
                      className="form-control"
                      id="init"
                      placeholder="Nom"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nom}
                    />
                    <ErrorMessage name="nom">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4" />

                  <div className="col-md-8 ">
                    <label htmlFor="init" className="form-label">
                      Thèmes d’interventions*
                    </label>

                    <Select
                      // defaultValue={selectedOption}
                      styles={customStyles}
                      onChange={(value) => setFieldValue('theme', value)}
                      className="themeSelect"
                      options={options}
                      isMulti
                      name="[theme]"
                      noOptionsMessage={() => 'Aucun résultat'}
                    />

                    <ErrorMessage name="theme">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="image" className="form-label">
                      Adresse
                    </label>
                    <input
                      type="text"
                      name="adresse"
                      className="form-control"
                      id="init"
                      placeholder="Numéro et nom de voie"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.adresse}
                    />
                    <ErrorMessage name="image">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                    {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="init" className="form-label">
                      Code postal
                    </label>
                    <input
                      type="number"
                      name="codeP"
                      className="form-control"
                      id="init"
                      placeholder="00 000"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.codeP}
                    />
                    <ErrorMessage name="codeP">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="ville" className="form-label">
                      Ville
                    </label>
                    <input
                      type="text"
                      name="ville"
                      className="form-control"
                      id="ville"
                      placeholder="Ville"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.ville}
                    />
                    <ErrorMessage name="ville">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="actif" className="form-label">
                      Pays
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="pays"
                      id="pays"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pays}
                    >
                      <option>pays</option>
                      {Object.keys(countryList).map((element) => (
                        <option
                          key={element}
                          value={countryList[element].label}
                        >
                          {countryList[element].label}
                        </option>
                      ))}
                    </select>

                    <ErrorMessage name="pays">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="site" className="form-label">
                      Site internet
                    </label>
                    <input
                      type="text"
                      name="site"
                      className="form-control"
                      id="site"
                      placeholder="Site Internet"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.site}
                    />
                    <ErrorMessage name="site">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="nom_contact" className="form-label">
                      Nom du contact
                    </label>
                    <input
                      type="text"
                      name="nom_contact"
                      className="form-control"
                      id="nom_contact"
                      placeholder="Nom"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.nom_contact}
                    />
                    <ErrorMessage name="nom_contact">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label
                      htmlFor="prenom_contact"
                      className="form-label"
                    >
                      Prénom du contact
                    </label>
                    <input
                      type="text"
                      name="prenom_contact"
                      className="form-control"
                      id="nom_contact"
                      placeholder="Prénom"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.prenom_contact}
                    />
                    <ErrorMessage name="prenom_contact">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="tel_contact" className="form-label">
                      Téléphone du contact
                    </label>
                    <input
                      type="number"
                      name="tel_contact"
                      className="form-control"
                      id="tel_contact"
                      placeholder="06 00 00 000"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.tel_contact}
                    />
                    <ErrorMessage name="tel_contact">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="email_contact" className="form-label">
                      Email du contact
                    </label>
                    <Field
                      type="email"
                      name="email_contact"
                      className="form-control"
                      id="email_contact"
                      placeholder="Email@email.fr"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.email_contact}
                    />

                    {errors.email_contact && touched.email_contact ? (
                      <div className="text-danger">
                        {errors.email_contact}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <label
                      htmlFor="commentraire_contact"
                      className="form-label"
                    >
                      Commentaire
                    </label>
                    <textarea
                      className="form-control"
                      name="commentraire_contact"
                      id="pre"
                      rows="3"
                      placeholder="Ajouter un commentaire"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.commentraire_contact}
                    />
                    <ErrorMessage name="commentraire_contact">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12 pt-5">
                    <a href="/admin/prestataires" type="reset" className="btn btn-link resetBtn">
                      Annuler
                    </a>
                    <button
                      type="submit"
                      className="btn btn-primary float-end"
                      // disabled={isSubmitting}
                      // onClick={handleClick}
                    >
                      Valider
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {/* lists */}
    </BaseContainer>
  );
}

export default CreatePrestataire;
