import React, { ReactElement } from 'react';

import { Rating } from 'react-simple-star-rating';

import { ELearningDto } from '../../model/dto/ELearningDto';
import { OnSiteDto } from '../../model/dto/OnSiteDto';

type RatingSummaryProps = {
    formation: ELearningDto|OnSiteDto,
}

/**
 * Affichage de la notation d'une formation sous forme d'etoiles
 * @param props
 * @constructor
 */
export function RatingSummary(props: RatingSummaryProps): ReactElement {
    return (
        <Rating
            initialValue={props.formation?.rating}
            readonly
            allowFraction
            titleSeparator="sur"
            size={25}
        />
    );
}
