import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import {
    useDataSource,
} from 'leeloo-react/component/hook/useDataSource';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { useParams } from 'react-router-dom';

import Hn from '../../components/common/Hn';
import { BaseContainer } from '../../components/layout/BaseContainer';
import { ParticipantDatatable } from '../../components/pages/admin/participant/ParticipantDataTable';
import { ResourceDataProviderEnum } from '../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../enum/RouteEnum';

import './webinar.css';

function DetailWebinar() {
  const [webinarOne, setWebinarOne] = useState([]);
  const params = useParams();
  const uri = process.env.REACT_APP_BASE_URL;
  const { authData } = useAuthProvider();

  const dsParticipants = useDataSource({
    resource: ResourceDataProviderEnum.participantWebinar,
    params: {
      filter: { idEntity: params.id },
      pagination: { page: 1, take: 50 },
    },
  });

  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  // récupérer un webinaire choisi par son id
  function getWebinarById() {
    axios.get(`${uri}/webinars/${params.id}`)
      .then((result) => {
        setWebinarOne(result?.data);
      })
      .catch((err) => {
        console.log('err webinars', err);
      });
  }

  useEffect(() => {
    getWebinarById();
  }, []);

  return (
    <BaseContainer>
      <div className="lists">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <RouterLink routeName={RouteEnum.ADMIN_WEBINAR}>
                Webinaires
              </RouterLink>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {webinarOne?.title}
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-md-6">
            <Hn
              title={webinarOne?.title}
              level="h4"
              className="h4-size pb-5"
              plus
            />
          </div>
          <div className="col-md-6">
            <RouterLink routeName={RouteEnum.ADMIN_WEBINAR_EDIT} params={{ id: webinarOne?.id }} className="btn btn-primary float-end">
              Modifier
            </RouterLink>
          </div>
        </div>
        {webinarOne.youtubeId && webinarOne.youtubeId !== '' && (
          <div className=" p-5 text-white bg-dark rounded-3">
            <div className="editor col-md-12 ">
              <iframe
                src={`https://www.youtube.com/embed/${webinarOne.youtubeId}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen=""
                width="1120"
                height="630"
                frameBorder="0"
              />
            </div>
          </div>
        )}
        <div>
          <Hn
            title="Participants"
            level="h4"
            className="h4-size pb-5 pt-5"
            plus={false}
          />
          {webinarOne.id && (
            <ParticipantDatatable
              dsParticipants={dsParticipants}
              resourcePresence={ResourceDataProviderEnum.webinarPresence}
              idEntity={webinarOne.id}
              addRoute={RouteEnum.ADMIN_WEBINAR_PARTICIPANT_MANAGE}
            />
          )}
        </div>
      </div>

    </BaseContainer>
  );
}

export default DetailWebinar;
