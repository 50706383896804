import React, { useEffect, useState } from 'react';

import {
  faCircleCheck,
  faCircleXmark,
  faDisplay,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import dateFormat from 'dateformat';
import { Icon } from 'leeloo-react/component/display/Icon';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { RouterFileLink } from 'leeloo-react/component/router/RouterFileLink';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { useParams } from 'react-router-dom';

import Hn from '../../../../../components/common/Hn';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';
import {
  ResourceDataProviderEnum,
} from '../../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../../enum/RouteEnum';
import { formatStringToSeo } from '../../../../../helpers/formatStringToSeo';
import './apprenant.css';

export default function InfoApprenant() {
  const params = useParams();
  const uri = process.env.REACT_APP_BASE_URL;
  const [apprenant, setApprenant] = useState([]);
  const [attestations, setAttestations] = useState([]);
  const { currentRouteName } = useRouter();
  const router = useRouter();
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  // récupérer un apprenant par son id
  function getApprenant(id) {
    axios
      .get(`${uri}/participant/${id}`)
      .then((result) => {
        setApprenant(result?.data);
      })
      .catch((err) => {
        console.log('err apprenant', err);
      });
  }

  // rechercher une action de formation
  function findInTable(e) {
    if (e.target.value.length > 0) {
      const filtreTexte = (arr, requete) => arr.filter(
        (el) => el?.title.toLowerCase().indexOf(requete.toLowerCase()) !== -1,
      );

      setAttestations(filtreTexte(attestations, e.target.value));
    } else {
      getAttestations(params.id);
    }
  }

  // récupérer les attestations de l'apprenant choisi
  function getAttestations(userData) {
    let types = [];
    if (params.category) {
      types = [
        params.category,
      ];
    } else {
      types = [
        'webinars',
        'trainings',
        'elearnings',
      ];
    }

    const promises = types.map((type) => axios
      .get(`${uri}/watershed/get_user_data/${userData}/${type}`)
      .then((response) => response.data));

    Promise.allSettled(promises)
      .then((result) => {
        const values = [];
        result.forEach((res) => { values.push(...res.value); });
        setAttestations(values);
      });
  }

  useEffect(() => {
    getApprenant(params.id);
    getAttestations(params.id);
  }, []);

  return (
    <BaseContainer>
      <nav aria-label="breadcrumb pb-3">
        <ol className="breadcrumb mt-0">
          <li className="breadcrumb-item">
            <RouterLink routeName={currentRouteName === RouteEnum.ADMIN_ELEARNER_SHOW ? RouteEnum.ADMIN_E_LEARNING_LEARNER : RouteEnum.ADMIN_ON_SITE_PARTICIPANT}>
              {currentRouteName === RouteEnum.ADMIN_ELEARNER_SHOW ? 'Formation e-learning - Apprenants' : 'Formation presentielle - Participants'}
            </RouterLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {`${apprenant?.contactPrenom} ${apprenant?.contactNom}`}
          </li>
        </ol>
      </nav>
      <div className="lists">
        <Hn
          title={`${apprenant?.contactPrenom} ${apprenant?.contactNom}`}
          level="h4"
          className="h4-size"
          plus
        />
        <span className="look-like-link resetBtn mb-5" onClick={() => router.goBack()}>Retour</span>
        <div className="p-5 text-white bg-dark rounded-3">
          <Hn
            title="Informations générales"
            level="h4"
            className="h4-size pb-5"
            plus={false}
          />
          <div className="form-elements">
            <div className="row">
              <div className="col-4">
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Apprenant
                  </label>
                  <br />
                  <span>
                    {`${apprenant?.contactPrenom} ${apprenant?.contactNom}`}
                  </span>
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Email
                  </label>
                  <br />
                  <span>{apprenant?.mailcontact}</span>
                </div>
              </div>
              <div className="col-4">
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Métier
                  </label>
                  <br />
                  <span>{apprenant?.metier}</span>
                </div>
              </div>
            </div>
            <div className="">
              <div className="row">
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Agent immobilier
                    </label>
                    <br />
                    <span>{apprenant?.agencymain}</span>
                    <br />
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Email agent immobilier
                    </label>
                    <br />
                    <span>{apprenant?.agenceEmail}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Nom de l'agence de rattachement
                    </label>
                    <br />
                    <span>{apprenant?.agenceImmobilier}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Adresse de l’agence
                    </label>
                    <br />
                    <span>{apprenant?.agenceAddress}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Code postal
                    </label>
                    <br />
                    <span>{apprenant?.codePostal}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Ville
                    </label>
                    <br />
                    <span>{apprenant?.ville}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Code GT
                    </label>
                    <br />
                    <span>{apprenant?.code_gt}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Offre de formation
                    </label>
                    <br />
                    <span>{apprenant?.offre}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Validation des CGU
                    </label>
                    <br />
                    <span>{apprenant?.cgu ? 'Oui' : 'Non'}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Date d'entrée
                    </label>
                    <br />
                    <span>{apprenant?.dateentree ? dateFormat(apprenant?.dateentree, 'dd-mm-yyyy') : '-'}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Date de sortie
                    </label>
                    <br />
                    <span>{apprenant?.datesortie ? dateFormat(apprenant?.datesortie, 'dd-mm-yyyy') : '-'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lists-filter my-5">
          <div className="row">
            <div className="col-xl-4 col-lg-1" />
            <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
              <div className="flexgy">
                <div className="col-md-4  mr-1  px-0">
                  <div className="input-group">
                    <input
                      className="form-control height43"
                      type="search"
                      placeholder="Recherche"
                      onChange={findInTable}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {attestations?.length > 0 ? (
          <table className="table table-dark table-hover">
            <thead>
              <tr>
                <th>Formation</th>
                <th>Modalité</th>
                <th>Date de début</th>
                <th>Date de fin</th>
                <th>Taux de réussite</th>
                <th>Validation</th>
                <th>Convention</th>
                <th>Attestation</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {attestations.map((item, index) => (
                <tr className="td" key={index}>
                  <td>{item.title}</td>
                  <td>
                    <FontAwesomeIcon icon={faDisplay} />
                  </td>
                  <td>{item.startDate && !isNaN(new Date()) ? (new Date(item.startDate)).toLocaleDateString('fr') : item.startDate}</td>
                  <td>{item.endDate && !isNaN(new Date()) ? (new Date(item.endDate)).toLocaleDateString('fr') : item.endDate}</td>
                  <td>
                    {item.score}
                    %
                  </td>
                  <td>
                    {item.passed ? (
                      <span className="text-success">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="fa-lg"
                        />
                      </span>
                    ) : (
                      <span className="text-danger">
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </span>
                    )}
                  </td>
                  <td>
                    {item?.type && item.type === 'trainings' && (
                      <RouterFileLink
                        resource={ResourceDataProviderEnum.resourceConventionFileDownload}
                        params={{ id: item?.id }}
                        nameFile={`Convention-${formatStringToSeo(item.title ?? '')}.pdf`}
                        className="btn btn-outline-primary"
                      >
                        <Icon name="uil uil-download-alt" />
                      </RouterFileLink>
                    )}
                  </td>
                  <td>
                    {item?.type && item.type === 'elearnings' && item.passed && (
                      <RouterFileLink
                        resource={router.generatePath(ResourceDataProviderEnum.resourceCertificateFileDownload, ResourceDataProviderEnum.resourceCertificateFileDownload, { contact: apprenant.id })}
                        params={{ id: item?.id }}
                        nameFile={`Attestation-${formatStringToSeo(item.title ?? '')}.pdf`}
                        className="btn btn-outline-primary"
                      >
                        <Icon name="uil uil-download-alt" />
                      </RouterFileLink>
                    )}
                  </td>
                  <td>
                    {item?.type && item.type === 'elearnings' && (
                      <RouterLink
                        routeName={RouteEnum.E_LEARNING_UPDATE_SCORE}
                        params={{ id: item.id, user: apprenant.id }}
                        nameFile={`Attestation-${formatStringToSeo(item.title ?? '')}.pdf`}
                        className="btn btn-outline-primary"
                      >
                        <Icon name="uil uil-edit" />
                      </RouterLink>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Hn
            title="Aucune attestation trouvée"
            level="h5"
            plus={false}
            className="text-center mt-5"
          />
        )}
      </div>
    </BaseContainer>
  );
}
