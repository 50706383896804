import React, { ReactElement, useState } from 'react';

import { SimpleColumn } from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { CustomFilterDataProviderType } from '../../../../model/dataProvider/CustomFilterDataProviderType';
import { LengthColumn } from '../../../dataTable/column/LengthColumn';
import { DataTableBulksActions } from '../../../dataTable/DataTableBulksActions';
import { DataTableHeaderActions } from '../../../dataTable/DataTableHeaderActions';
import { DataTableListActions } from '../../../dataTable/DataTableListActions';

export function SkillDataTable(): ReactElement {
  const [filters, setFilters] = useState<CustomFilterDataProviderType>({});
  const dsSkills = useDataSource({
    resource: ResourceDataProviderEnum.skill,
    params: {
      pagination: { page: 1, take: 50 },
    },
  });
  const exportNameFile = 'competences.csv';

  return (
    <DataTable
      dataSource={dsSkills}
      filter={(
        <Filter>
          <InputField name="search" label="Rechercher" onChangeValue={(v) => setFilters({ search: v })} />
        </Filter>
      )}
      actionHeader={(
        <DataTableHeaderActions
          addRoute={RouteEnum.ADMIN_SKILL_ADD}
          addRouteValue="Ajouter une compétence"
          importResource={ResourceDataProviderEnum.skillImport}
          exportResource={ResourceDataProviderEnum.skillExport}
          exportNameFile={exportNameFile}
          exportFilters={filters}
          dataSource={dsSkills}
        />
      )}
      actionRow={(row) => [
        <DataTableListActions
          key={0}
          row={row}
          dataSource={dsSkills}
          readRoute={RouteEnum.ADMIN_SKILL_SHOW}
          deleteResource={ResourceDataProviderEnum.skill}
          duplicateResource={ResourceDataProviderEnum.skillDuplicate}
          editRoute={RouteEnum.ADMIN_SKILL_EDIT}
        />,
      ]}
      onChangeCheckedRow={(rows: {id: number|string}[], cleanSelection) => (
        <DataTableBulksActions
          rows={rows}
          dataSource={dsSkills}
          deleteResource={ResourceDataProviderEnum.skill}
          duplicateResource={ResourceDataProviderEnum.skillDuplicate}
          exportResource={ResourceDataProviderEnum.skillExport}
          exportNameFile={exportNameFile}
          cleanSelection={cleanSelection}
        />
      )}
    >
      <SimpleColumn field="title" title="Titre du référentiel" sortable />
      <LengthColumn field="subskills" title="Nombre de Compétences" />
    </DataTable>
  );
}
