import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { MultiSelect } from 'react-multi-select-component';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Hn from '../../../../../components/common/Hn';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';
import { RouteEnum } from '../../../../../enum/RouteEnum';

import './competences.css';

function CompetencesElearning() {
  const [competences, setCompetences] = useState([]);
  const [elearningSkils, setSkils] = useState([]);
  const { id } = useParams();
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  const uri = process.env.REACT_APP_BASE_URL;

  // Reprendre la liste des compétences

  function getCompetences() {
    axios
      .get(`${uri}/skills`)
      .then((result) => {
        result.data?.data.forEach((element) => {
          competences.push({ label: element.title, value: element.id });

          if (elearningSkils.indexOf(element.id) != -1) {
            selected.push({ label: element.title, value: element.id });
          }
        });
      })
      .catch((err) => {
        console.log('err formateurs', err);
      });
  }
  // récupérer le détail d'une formation elearning et ses compétences
  function getSkils() {
    axios
      .get(`${uri}/elearning/${id}/get_skills`)
      .then((result) => {
        setSelected(result?.data);
      })
      .catch((err) => {
        console.log('err formateurs', err);
      });
  }

  useEffect(() => {
    getSkils();
    getCompetences();
  }, []);

  // Ajouter une compétence à la formation elearning qui est récupérée par (id)
  // Continuez avec l'exécution du gestionnaire de soumission

  const handleSubmit = () => {
    const data = new FormData();

    selected.forEach((item) => {
      data.append('competences[]', item.value);
    });

    axios
      .post(
        `${uri}/elearning/${id}/set_skills`,
        data,
      )
      .then(() => {
        navigate('/admin/formation-e-learning');
      })
      .catch((err) => {
        console.log('err training', err);
      });
  };

  return (
    <BaseContainer>
      <div className="lists">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={RouteEnum.ADMIN_E_LEARNING}>
                Formations Elearning - Actions de formation
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Créer une action de formation
            </li>
          </ol>
        </nav>
        <div className="h-100 p-5 bg-dark rounded-3">
          <Hn
            title="Créer une action de formation"
            level="h4"
            className="h4-size"
            plus={false}
          />
          <Hn
            title="Etape 3 : Compétences associées"
            level="p"
            className="py-2 fw-bold"
            plus={false}
          />
          <div className="form-elements mt-2">
            <div className="form-aria p-4">
              <Formik
                initialValues={{
                  competences: '',
                }}
                onSubmit={handleSubmit}
              >
                {({
                  handleSubmit,
                }) => (
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-10">
                      <label className="form-label">
                        Référentiel de compétences
                      </label>
                      <MultiSelect
                        options={competences}
                        value={selected}
                        name="competences"
                        onChange={setSelected}
                        labelledBy="Choisir"
                        disableSearch
                        className="themeSelect"
                      />
                    </div>

                    <div className="col-12 mt-5">
                      <a
                        href={
                          `/admin/creer-chapitre-e-learning/${id}`
                        }
                        className="btn btn-link text-decoration-none"
                      >
                        Retour
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                      >
                        Valider
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
}

export default CompetencesElearning;
