import React, { useEffect, useState } from 'react';

import axios from 'axios';
import dateFormat from 'dateformat';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { RouterFileLink } from 'leeloo-react/component/router/RouterFileLink';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { useParams } from 'react-router-dom';

import Hn from '../../../../../components/common/Hn';
import Icon from '../../../../../components/common/Icon';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';
import {
  ParticipantDatatable,
} from '../../../../../components/pages/admin/participant/ParticipantDataTable';
import {
  ResourceDataProviderEnum,
} from '../../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../../enum/RouteEnum';
import '../session.css';
import { FormationTags } from '../../../../../components/admin/FormationTags';

function ShowSessions() {
  const uri = process.env.REACT_APP_BASE_URL;
  const params = useParams();
  const [session, setSession] = useState([]);
  const [etat, setEtat] = useState('');
  const [comment, setComment] = useState('');
  const etats = ['À venir', 'En cours', 'Terminee'];
  const { goTo } = useRouter();
  const { authData } = useAuthProvider();
  const router = useRouter();

  const dsParticipants = useDataSource({
    resource: ResourceDataProviderEnum.participantSession,
    params: {
      filter: { idEntity: params.id },
      pagination: { page: 1, take: 50 },
    },
  });

  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  // modifier une session sur les seuls champs status/comment dans session
  function updateSession() {
    const data = { comment, condition: etat };

    axios
      .put(`${uri}/session/edit/comment/${params.id}`, data)
      .catch((err) => {
        console.log('err ', err);
      });
  }

  // récupérer les sessions
  function getUnitySession() {
    axios
      .get(`${uri}/session/${params.id}`)
      .then((result) => {
        setSession(result?.data);
      })
      .catch((err) => {
        console.log('err ', err);
      });
  }

  useEffect(() => {
    getUnitySession();
  }, []);

  return (
    <BaseContainer>
      <div className="banner-provider container-fluid session1">
        <div className="row pe-2">
          <div className=" container lists pe-4">
            <nav aria-label="breadcrumb pb-3">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <RouterLink routeName={RouteEnum.ADMIN_SESSION}>
                    Formations présentielles - session de formation
                  </RouterLink>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                >
                  {session?.ref}
                </li>
              </ol>
            </nav>
            <div className="mb-3 d-flex justify-content-end">
              {session?.lessons?.length > 0 ? (
                <RouterFileLink
                  resource={router.generatePath(ResourceDataProviderEnum.sessionDownloadAttendance, ResourceDataProviderEnum.sessionDownloadAttendance, { id: session.id })}
                  params={{}}
                  className="btn btn-primary mt-4 mr-50"
                  nameFile={`Emargement-${session.id}.pdf`}
                >
                  Télécharger la feuille d'émargement
                </RouterFileLink>
              ) : null }
              <button type="button" className="btn btn-primary mt-4" onClick={() => goTo(RouteEnum.ADMIN_SESSION_EDIT, { id: session.id })}>
                Modifier
              </button>
            </div>
            <div className="row">
              <div className="col-md-3 col-xs-12 ">
                <div className="cardSession">
                  <h5 className="card-title title16">Unités de formations</h5>
                  <p className="card-text title23">{session?.lessons?.length}</p>
                </div>
              </div>
              <div className="col-md-3 col-xs-12 ">
                <div className="cardSession">
                  <h5 className="card-title title16">Coût</h5>
                  <p className="card-text title23">{session?.training?.cost}</p>
                </div>
              </div>
              <div className="col-md-3 col-xs-12 ">
                <div className="cardSession">
                  <h5 className="card-title title16">Etat de la session</h5>
                  <p className="card-text title23">{session?.conditions}</p>
                </div>
              </div>
              <div className="col-md-3 col-xs-12 ">
                <div className="cardSession">
                  <h5 className="card-title title16">Participants inscrits</h5>
                  <p className="card-text title23">
                    {dsParticipants.total}
                  </p>
                </div>
              </div>
            </div>

            {/* lists-filter */}
            <div className="row mt-30 mb-135">
              <div className="col-12">
                <div className="h-100 p-5 text-white bg-dark rounded-3">
                  <Hn
                    title="Informations générales"
                    level="h4"
                    className="h4-size pb-5"
                    plus={false}
                  />
                  <div className="form-elements">
                    <div className="row g-3">
                      <div className="col-md-4">
                        <label htmlFor="ref" className="form-label title16">
                          Référence
                        </label>
                        <p>{session?.ref}</p>
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="nom" className="form-label title16">
                          Date de Début
                        </label>
                        <p>
                          {' '}
                          {dateFormat(session?.dateStart, 'dd/mm/yyyy')}
                        </p>
                      </div>
                      <div className="col-md-2">
                        <label htmlFor="denomi" className="form-label title16">
                          Date de fin
                        </label>
                        <p>{dateFormat(session?.dateEnd, 'dd/mm/yyyy')}</p>
                      </div>
                      <FormationTags className="col-md-4" tags={session.tags}></FormationTags>
                      <div className="col-md-4">
                        <label htmlFor="denomi" className="form-label title16">
                          Lieux de formation
                        </label>
                        {session?.location !== null && (
                          <p>
                            {`${session?.location?.address}, ${session?.location?.zip} ${session?.location?.town}`}
                          </p>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="codepos" className="form-label">
                          Formateurs
                        </label>
                        <p>{session?.former?.organize}</p>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="codepos" className="form-label" />
                        <p>
                          {`${session?.former?.firstName
                          } ${
                            session?.former?.lastName}`}
                        </p>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="codepos" className="form-label">
                          Etat
                        </label>
                        <select
                          className="form-control select"
                          name="etat"
                          onChange={(event) => setEtat(event.target.value)}
                          value={etat || session?.conditions}
                        >
                          {etats.map((item, index) => (
                            <option value={item} key={index}>{item}</option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-12 mt-2">
                        <label htmlFor="ville" className="form-label">
                          Commentaire
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Ajouter un commentaire"
                          name="comment"
                          onChange={(event) => setComment(event.target.value)}
                          defaultValue={session.comment}
                          value={comment}
                        />
                      </div>
                      <div className="col-md-12 mt-2">
                        <button
                          className="btn btn-primary float-right"
                          onClick={updateSession}
                        >
                          valider
                        </button>
                      </div>

                      <div className="row">
                        <Hn
                          title="Unités de formation"
                          level="h4"
                          className="h4-size pb-5 pt-5"
                          plus={false}
                        />

                        {session?.lessons?.map((lesson, index) => (
                          <div className="col-md-6" key={index}>
                            <div className="cardUnit text-white grecard mb-3 p-3">
                              <div className="card-body grecard">
                                <h5 className="card-title">
                                  {lesson?.name}
                                </h5>
                                <div className="pt-3">
                                  <Icon
                                    name="localisation"
                                    data={lesson?.location ? `${lesson.location.address ?? ''}, ${lesson.location.zip ?? ''} ${lesson.location.town ?? ''}` : 'Non renseigné'}
                                  />
                                  <ul className="list-inline ps-0">
                                    <li className="list-inline-item">
                                      <Icon
                                        name="Calendar"
                                        data={dateFormat(lesson?.date, 'dd/mm/yyyy')}
                                      />
                                    </li>
                                    <li className="list-inline-item">
                                      <Icon
                                        name="Clock"
                                        data={`${lesson?.hourStartMorning}h/${lesson?.hourEndMorning}h et ${lesson?.hourStartmidi}h/${lesson?.hourEndMidi}`}
                                      />
                                    </li>
                                    <li className="list-inline-item">
                                      <Icon
                                        name="profil"
                                        data={`${lesson?.former?.organize} : ${lesson?.former?.firstName} ${lesson?.former?.lastName}`}
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div>
                        <Hn
                          title="Participants"
                          level="h4"
                          className="h4-size pb-5 pt-5"
                          plus={false}
                        />
                        {session.id && (
                          <ParticipantDatatable
                            dsParticipants={dsParticipants}
                            resourcePresence={ResourceDataProviderEnum.sessionPresence}
                            idEntity={session.id}
                            addRoute={RouteEnum.ADMIN_ON_SITE_PARTICIPANT_MANAGE}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
}

export default ShowSessions;
