/**
 * Expose les différents roles possible du client
 */
export default {
  ROLE_SUPER_ADMIN: 'SUPER_ADMIN',
  ROLE_AGENT_IMMOBILIER: 'AGENT_IMMOBILIER',
  ROLE_ASSOCIE: 'ASSOCIE',
  ROLE_RESPONSABLE_TRANSACTION: 'RESPONSABLE_TRANSACTION',
  ROLE_GESTIONNAIRE: 'GESTIONNAIRE',
  ROLE_CONSEILLER_VENTE: 'CONSEILLER_VENTE',
  ROLE_CONSEILLER_LOCATION: 'CONSEILLER_LOCATION',
  ROLE_AGENT_COMMERCIAL: 'AGENT_COMMERCIAL',
  ROLE_ASSISTANT_COMMERCIAL: 'ASSISTANT_COMMERCIAL',
  ROLE_PROSPECT: 'PROSPECT',
  ROLE_ALTERNANT: 'ALTERNANT',
  ROLE_STAGIAIRE: 'STAGIAIRE',
};
