import React, { ReactElement } from 'react';

import { UserDataTable } from './UserDataTable';
import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { BaseContainer } from '../../../layout/BaseContainer';

export function User(): ReactElement {
    return (
        <BaseContainer>
            <div className="lists-title">
                <h1>Utilisateurs</h1>
            </div>
            <div className="pt-5">
                <UserDataTable resource={ResourceDataProviderEnum.user} />
            </div>
        </BaseContainer>
    );
}
