import React, { useState } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useModal } from 'leeloo-react/component/hook/useModal';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useToast } from 'leeloo-react/component/hook/useToast';
import { Spinner } from 'react-bootstrap';

import { RouteEnum } from '../../../enum/RouteEnum';
import { AuthReducerType } from '../../../model/authProvider/AuthReducerType';

/**
 * Affiche et gère la sortie de la fonctionnalité "connecté en tant que"
 */
export function LogoutAsLink({ className = '' }) {
    const [isLoad, setIsLoad] = useState(false);
    const authProvider = useAuthProvider();
    const { confirm } = useModal();
    const toast = useToast();
    const { goTo } = useRouter();

    // Si le autoConnectToken n'est pas présent, la fonctionnalité sera désactivée
    const autoConnectToken : string|undefined = (authProvider?.authData as AuthReducerType)?.autoConnectToken;

    /**
     * Ferme la connexion "en tant que" et revient sur la connexion précédente
     */
    const logoutAs = async () => {
        setIsLoad(true);
        if (authProvider) {
            const response = await confirm('Êtes-vous sur de vouloir quitter le mode "Connecté en tant que" ?');
            if (response) {
                await authProvider.login({ autoLoginData: autoConnectToken })
                    .then((dataAuth) => {
                        if (dataAuth) {
                            authProvider.successLogin(dataAuth).then(
                                () => goTo(RouteEnum.ADMIN_USERS, { }),
                            );
                            toast('Sortie du mode "Connecté en tant que" effectuée', 'success');
                        } else {
                            toast('Une erreur est survenue lors de la sortie du mode "Connecté en tant que"', 'error');
                            setIsLoad(false);
                        }
                    }, () => {
                        // Cas typique d'une session expirée
                        toast(
                            'Une erreur est survenue lors de la sortie du mode "Connecté en tant que".'
                            + ' Votre session administrateur a probablement expiré.',
                            'error',
                        );
                        setIsLoad(false);
                    });
            } else {
                setIsLoad(false);
            }
        }
    };

    return (
        <button
            type="button"
            className={className || 'nav-link logout cursor-pointer logout-as'}
            onClick={logoutAs}
            disabled={!autoConnectToken}
        >
            {!isLoad ? (
                <>
                    <Icon name="uil uil-lock-alt" />
                    Quitter le mode &ldquo;Connecté en tant que&ldquo;
                </>
            ) : (
                <Spinner animation="border" size="sm" className="mr-1" />
            )}
        </button>
    );
}
