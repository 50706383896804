import * as Yup from 'yup';

import React, { useEffect, useRef, useState } from 'react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import Add from '../../../../../assets/icons/add.png';
import Hn from '../../../../../components/common/Hn';
import { useEditor } from '../../../../../components/hook/useEditor';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';
import './createFaceTraining.css';
import { CategoryTypeEnum } from '../../../../../enum/CategoryTypeEnum';

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
];
const validationSchema = Yup.object().shape({
  reference: Yup.string().required('Champ obligatoire.'),
  intitule: Yup.string().required('Champ obligatoire.'),
  image: Yup.mixed()
    .test(
      'fileFormat',
      'Format non supporté.',
      (value) => value && SUPPORTED_FORMATS.includes(value.type),
    )
    .nullable()
    .required('Champ obligatoire choissisez une image.'),
});

function CreateFaceTraining() {
  const fileRef = useRef();
  const pdfRef = useRef();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [providers, setProviders] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [elearnings, setElearnings] = useState([]);
  const [themes, setThemes] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const { editorConfiguration } = useEditor();
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  const uri = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: '16px',
      fontWeight: state.isSelected ? '600' : '400',
      backgroundColor: '#3A4048',
      color: 'white',
      border: '1px solid #006FFF',
      borderRadius: 'none',
    }),
    dropdownIndicator: (base) => ({
      color: '#006FFF',
      marginRight: '10px',
    }),
    menu: (base) => ({
      backgroundColor: '#212730',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#1E2E45' : '#212730',
      fontWeight: state.isSelected ? '600' : '400',
      borderBottom: '1px solid #3A4048',
      color: 'white',
      margin: '5px 14px 5px 10px',
    }),
    multiValue: (base, state) => ({
      ...base,
      color: 'white',
      backgroundColor: '#1E2E45',
      fontWeight: state.isSelected ? '600' : '400',
      margin: '6px 10px 5px 10px',
      padding: '5px 14px 5px 10px',
    }),
    multiValueLabel: (base) => ({
      color: 'white',
    }),
    multiValueRemove: (base) => ({
      color: '#006FFF',
      fontSize: '16px',
      height: '20px',
      width: '20px',
    }),
  };

  // récupérer la liste des formations présentielles
  function getTrainings() {
    axios.get(`${uri}/training`)
      .then((result) => {
        setTrainings(result?.data?.data);
      })
      .catch((err) => {
        console.log('err trainning', err);
      });
  }

  // récupérer la liste des formations elearning
  function getElearnings() {
    axios.get(`${uri}/elearning`)
      .then((result) => {
        setElearnings(result?.data?.data);
      })
      .catch((err) => {
        console.log('err elearning', err);
      });
  }

  // récupérer la liste de tous les prestataires
  function getProviders() {
    axios
      .get(`${uri}/provider`)
      .then((result) => {
        setProviders(result?.data?.data);
      })
      .catch((err) => {
        console.log('err prestataire', err);
      });
  }

  // créer une nouvelle formation présentielle
  const handleSubmit = (values) => {
    const headersFiles = {
      onUploadProgress(progressEvent) {
        const { loaded, total } = progressEvent;

        const percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setUploadPercentage(percent);
        }
      },
    };

    const selectedThemes = [];
    selectedOption.map((op) => {
      selectedThemes.push(op.value);
    });

    const data = new FormData();
    data.append('ref', values.reference);
    data.append('title', values.intitule);
    data.append('objective', values.object);
    data.append('training_program', values.program);
    data.append('audience', values.prerequis);
    data.append('assoc_cours', values.associe);
    data.append('manager_spec', values.manager);
    data.append('forward_catalog', values.miseavant);
    data.append('obligation', values.obligation);
    data.append('horsAgence', values.horsAgence);
    data.append('status', values.etat);
    data.append('themes', selectedThemes);
    data.append('type', '1');
    data.append('comment', values.commentaire);
    data.append('imagefile', values.image);
    data.append('brochure', values.pdf);
    // data.append('duration', values.duree);

    const initialVar = values.duree.split(':');
    const hourtominute = parseInt(initialVar[0], 10) * 60;
    const finalvar = parseInt(hourtominute, 10) + parseInt(initialVar[1], 10);
    data.append('duration', finalvar);

    data.append('cost', values.tarif);
    data.append('provider', values.prestataire);

    axios.post(`${uri}/training/new`, data, headersFiles)
      .then((result) => {
        setUploadPercentage(100);
        setUploadPercentage(100);

        navigate(`/admin/create-session-formation/${result.data.id}`);
        console.log(result);
      })
      .catch((err) => {
        console.log('err training', err);
      });
  };

  // récupérer la liste des thémes des formations présentielles
  function getThem() {
    axios
      .get(`${uri}/thematic?type=${CategoryTypeEnum['Présentiel']}`)
      .then((result) => {
        result?.data?.data.map((theme) => {
          setThemes((options) => [
            ...options,
            { value: theme.id, label: theme.name },
          ]);
        });
      })
      .catch((err) => {
        console.log('err theme', err);
      });
  }

  useEffect(() => {
    getProviders();
    getThem();
    getElearnings();
    getTrainings();
  }, []);

  return (
    <BaseContainer>
      <div className="lists">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/admin/formation-presentielle">Formations présentielles - Action de formation</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Créer une action de formation
            </li>
          </ol>
        </nav>
        <div className="p-5 text-white bg-dark rounded-3">
          <Hn
            title="Créer une action de formation"
            level="h4"
            className="h4-size"
            plus={false}
          />
          <Hn
            title="Étape 1 : Informations générales"
            level="p"
            className="py-2 fw-bold"
            plus={false}
          />

          <div className="form-elements">
            <Formik
              initialValues={{
                reference: '',
                intitule: '',
                image: null,
                associe: '',
                prerequis: '',
                object: '',
                program: '',
                pdf: null,
                prestataire: '',
                duree: '00:00',
                tarif: '',
                etat: '0',
                commentaire: '',
                miseavant: '',
                horsAgence: '',
                manager: '',
                obligation: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-4">
                    <label className="form-label">
                      Référence*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ref"
                      name="reference"
                      placeholder="Référence"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reference}
                    />
                    <ErrorMessage name="reference">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-8">
                    <label className="form-label">
                      Intitulé*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="intitule"
                      id="init"
                      placeholder="Intitulé"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.intitule}
                    />
                    <ErrorMessage name="intitule">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Image*
                    </label>
                    <span
                      className="form-control"
                      onClick={() => fileRef.current.click()}
                    >

                      <img src={Add} className="pe-1" />
                      {' '}
                      {fileRef?.current?.value ? fileRef?.current?.value : 'Ajouter une image'}
                    </span>
                    <p className="formatImg">Format : jpg, png / 10 mo max / Résolution conseillée : xxx</p>
                    <input
                      ref={fileRef}
                      multiple={false}
                      type="file"
                      name="image"
                      onChange={(event) => {
                        setFieldValue('image', event.currentTarget.files[0]);
                      }}
                      hidden
                      accept=".png,.jpg,.gif"
                    />
                    <ErrorMessage name="image">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Thème*
                    </label>
                    <Select
                      styles={customStyles}
                      onChange={setSelectedOption}
                      className="themeSelect"
                      options={themes}
                      isMulti
                      noOptionsMessage={() => 'Aucun résultat'}
                    />
                    <ErrorMessage name="themes">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Parcours associé
                    </label>
                    <select
                      className="form-select"
                      name="associe"
                      id="associe"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.associe}
                    >
                      <option value="">Sélectionner dans la liste</option>

                      {trainings.map((training, i) => (
                        <option value={training.title} key={i}>{training.title}</option>
                      ))}

                      {elearnings.map((elearning, i) => (
                        <option value={elearning.id} key={i}>{elearning.intitule}</option>
                      ))}

                    </select>
                    <ErrorMessage name="associe">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Pré-requis et public visé
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.prerequis}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('prerequis', data);
                      }}
                    />
                    <ErrorMessage name="prerequis">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Objectifs
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.object}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('object', data);
                      }}
                    />
                    <ErrorMessage name="object">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Programme de la formation
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.program}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('program', data);
                      }}
                    />
                    <ErrorMessage name="program">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Programme de la formation
                    </label>
                    <span
                      className="form-control"
                      onClick={() => pdfRef.current.click()}
                    >
                      <img src={Add} className="pe-1" />
                      {' '}
                      {pdfRef?.current?.value ? pdfRef?.current?.value : 'Ajouter un pdf'}
                    </span>

                    <input
                      ref={pdfRef}
                      multiple={false}
                      type="file"
                      name="pdf"
                      onChange={(event) => {
                        setFieldValue('pdf', event.currentTarget.files[0]);
                      }}
                      hidden
                      accept=".pdf"
                    />
                    <ErrorMessage name="pdf">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                    {values.pdf && uploadPercentage > 0 && (
                      <div className="row mt-3">
                        <div className="col pt-1">
                          <ProgressBar
                            now={uploadPercentage}
                            striped
                            label={`${uploadPercentage}%`}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Prestataire
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="prestataire"
                      id="prestat"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prestataire}
                    >
                      <option defaultValue>Sélectionner dans la liste</option>
                      {providers.map((provider, i) => (
                        <option value={provider.id} key={i}>
                          {provider?.name}
                          {' '}
                          |
                          {provider?.contact_name}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage name="prestataire">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Durée théorique (en heure)*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="duree"
                      id="duree"
                      placeholder="00:00"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.duree}
                      pattern="\d\d:\d\d"
                      required="required"
                    />
                    <ErrorMessage name="duree">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Tarif HT
                    </label>
                    <input
                      type="number"
                      step="0.5"
                      className="form-control cost"
                      name="tarif"
                      id="tarif"
                      placeholder="00"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tarif}
                    />
                    <ErrorMessage name="tarif">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Actif
                    </label>
                    <select
                      className="form-select"
                      name="etat"
                      aria-label="Default select example"
                      id="actif"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.etat}
                    >
                      <option value="1">
                        Oui
                      </option>
                      <option value="0">Non</option>
                    </select>
                    <ErrorMessage name="etat">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Commentaire
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.commentaire}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('commentaire', data);
                      }}
                    />
                    <ErrorMessage name="commentaire">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4 col-xs-12">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="miseavant"
                        type="checkbox"
                        id="check"
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.miseavant}
                      />
                      <label className="form-check-label" htmlFor="check">
                        Mettre en avant dans le catalogue
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="horsAgence"
                        type="checkbox"
                        id="check"
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.horsAgence}
                      />
                      <label className="form-check-label" htmlFor="check">
                        Hors agence 2025
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="manager"
                        type="checkbox"
                        id="check2"
                        value={values.manager}
                      />
                      <label className="form-check-label" htmlFor="check2">
                        Spécifique au manager
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="obligation"
                        type="checkbox"
                        id="check3"
                        value={values.obligation}
                      />
                      <label className="form-check-label" htmlFor="check3">
                        Participe à l'obligation de formation
                      </label>
                    </div>
                  </div>

                  <div className="col-12 mt-5">
                    <a
                      href="/admin/formation-presentielle"
                      className="btn btn-link text-decoration-none resetBtn"
                    >
                      Annuler
                    </a>
                    <button
                      type="submit"
                      className="btn btn-primary float-end"
                    >
                      Suivant
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {/* lists */}
    </BaseContainer>
  );
}

export default CreateFaceTraining;
