import React, { ReactElement } from 'react';

import { OnSiteDataTable } from './OnSiteDataTable';
import { BaseContainer } from '../../../layout/BaseContainer';

export function OnSiteListPage(): ReactElement {
  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Formations présentielles</h1>
        <h2 className="mt-4 plus">Actions de formation</h2>
      </div>
      <div className="pt-5">
        <OnSiteDataTable />
      </div>
    </BaseContainer>
  );
}
