import React, {
  ReactElement, useEffect, useState,
} from 'react';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import moment from 'moment';

import { KpiELearning } from './kpis/KpiELearning';
import { KpiGlobal } from './kpis/KpiGlobal';
import { KpiLearner } from './kpis/KpiLearner';
import { KpiSector } from './kpis/KpiSector';
import { KpiThematique } from './kpis/KpiThematique';
import { KpiTrained } from './kpis/KpiTrained';
import { BaseContainer } from '../../../layout/BaseContainer';

// Typage préconisé par la librairie react-daterange-picker
type DateRange = Date | null | [Date | null, Date | null];

export function DashboardAdminPage(): ReactElement {
  const [valueDateRange, setValueDateRange] = useState<DateRange>([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  ]);
  const [dateRange, setDateRange] = useState<{}>();

  useEffect(() => {
    if (valueDateRange && Array.isArray(valueDateRange)) {
      setDateRange({
        startAt: moment(valueDateRange[0])
          .format('Y-MM-DD'),
        endAt: moment(valueDateRange[1])
          .format('Y-MM-DD'),
      });
    }
  }, [valueDateRange]);

  return (
    <BaseContainer wrapperClassName="dashboard">
      <div className="lists-title mb-5">
        <div className="row">
          <div className="col-md-8">
            <h1>Tableau de bord</h1>
          </div>
          <div className="col-md-4 pt-3">
            <DateRangePicker
              onChange={setValueDateRange}
              value={valueDateRange}
              locale="fr-FR"
              className="form-control"
              format="dd/MM/y"
              clearIcon={null}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <h3 className="plus">Formations présentielles</h3>
        </div>
      </div>
      {/* <div className="row"> */}
      {/*    <div className="col-md-6"> */}
      {/*        <div className="p-3 bg-dark-grey text-center"> */}
      {/*            <h4>Montant total facturés</h4> */}
      {/*            <p>3 245 € HT</p> */}
      {/*        </div> */}
      {/*    </div> */}
      {/* </div> */}
      <div className="row mt-4">
        <div className="col-md-6">
          <KpiGlobal dateRange={dateRange} />
        </div>
        <div className="col-md-6">
          <KpiThematique dateRange={dateRange} />
        </div>
      </div>
      <KpiSector dateRange={dateRange} />
      <div className="row mt-4">
        <div className="col-md-6">
          <KpiTrained dateRange={dateRange} />
        </div>
        <div className="col-md-6">
          <KpiLearner dateRange={dateRange} />
        </div>
      </div>
      <h3 className="plus my-5">Formations E-Learning</h3>
      <KpiELearning dateRange={dateRange} />
    </BaseContainer>
  );
}
