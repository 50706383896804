import React, { useState } from 'react';

import { useDataProvider } from 'leeloo-react/component/hook/useDataProvider';

import { DataTableDefaultButton } from './DataTableDefaultButton';
import { DataTableButtonType } from '../../../model/dataTable/DataTableButtonType';

export function DataTableUnarchiveButton<T>(props: DataTableButtonType<T>) {
    const [isLoad, setIsLoad] = useState(false);
    const dp = useDataProvider();

    const fetchPromise = () => dp.update!(props.resource, { id: props.row?.id });
    const fetchPromises = () => (props.rows as []).map((row: T & {id: any}) => dp.update!(props.resource, { id: row.id }));

    return (
        <DataTableDefaultButton
            {...props}
            isLoad={isLoad}
            onClick={() => setIsLoad(true)}
            label="Désarchiver"
            icon="uil uil-file-import"
            fetchPromise={fetchPromise}
            fetchPromises={fetchPromises}
            handleLoad={setIsLoad}
            messageFetchOne="Confirmez-vous le désarchivage de cet élément ?"
            messageFetchMultiples="Confirmez-vous le désarchivage de ces éléments ?"
            messageSuccess="Désarchivage effectué avec succès"
        />
    );
}

DataTableUnarchiveButton.defaultProps = {
    variant: 'primary',
};
