import React from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { ButtonVariant } from 'react-bootstrap/types';

type Props<T> = {
    variant?: ButtonVariant,
    pictoOnly?: boolean,
    row: T & {id: any},
    resource: string,
}

export function DataTableShowButton<T>(props: Props<T>) {
  return (
    <RouterLink routeName={props.resource} params={{ id: props.row.id }} className={`btn btn-${props.variant}`}>
      <Icon name="uil uil-eye" additionalClasses={!props.pictoOnly ? 'me-1' : ''} />
      {!props.pictoOnly ? 'Détail' : ''}
    </RouterLink>
  );
}

DataTableShowButton.defaultProps = {
  variant: 'primary',
  pictoOnly: false,
};
