import React, { ReactElement } from 'react';

import { TrainerDataTable } from './TrainerDataTable';
import { BaseContainer } from '../../../layout/BaseContainer';

export function TrainerListPage(): ReactElement {
  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Formations présentielles</h1>
        <h2 className="mt-4 plus">Formateurs</h2>
      </div>
      <div className="pt-5">
        <TrainerDataTable />
      </div>
    </BaseContainer>
  );
}
