import React, { useContext, useEffect, useMemo } from 'react';

import { SubmitBtn } from 'leeloo-react/component/form/action/SubmitBtn';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { SelectField } from 'leeloo-react/component/form/field/SelectField';
import { ContextForm } from 'leeloo-react/component/form/Form';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { CategoryTypeEnum } from '../../../../enum/CategoryTypeEnum';
import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { CategoryDto } from '../../../../model/dto/CategoryDto';

export function CategoryForm() {
  const { values, onChangeFieldsValues } = useContext(ContextForm);
  const valueForm = values as CategoryDto;
  const oldCategory = useMemo(() => valueForm?.type, []);

  // on va récupérer mon total de catégorie dans un type donné
  const dsCategories = useDataSource({
    resource: ResourceDataProviderEnum.category,
    params: {
      pagination: { page: 1, take: 1 },
    },
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (valueForm?.type) {
      dsCategories.updateFilter(
        { type: valueForm.type },
      );
    }
  }, [valueForm?.type]);

  return (
    <>
      <div className="row">
        <InputField<CategoryDto>
          className="col-md-8 mb-3"
          label="Nom de la catégorie"
          name="name"
        />
        <div className="w-100" />
        <SelectField<CategoryDto, CategoryDto>
          className="col-md-4 mb-3"
          name="type"
          label="Type de catégorie"
          isClearable={false}
          optionsEnum={CategoryTypeEnum}
          onChangeValue={(v) => {
            onChangeFieldsValues({ categoryOrder: null, type: v });
          }}
        />
        {valueForm.type && !dsCategories.isLoading && (
          <SelectField<CategoryDto, number[]>
            className="col-md-4 mb-3"
            name="categoryOrder"
            label="Position de la catégorie"
            optionsList={[...Array(dsCategories.total ? dsCategories.total + (valueForm.id && valueForm.type === oldCategory ? 0 : 1) : 1).keys()].map((i) => {
              const number = i + 1;
              return { label: `${number}`, value: number };
            })}
            isClearable={false}
          />
        )}
      </div>
      <div className="d-flex justify-content-end">
        <SubmitBtn className="btn btn-primary" label="Sauvegarder" />
      </div>
    </>
  );
}
