import * as Yup from 'yup';

import React, {
  Fragment, useEffect, useState,
} from 'react';

import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { Accordion, Dropdown } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import DeleteChapitre from './DeleteChapitre';
import DeleteUniteDeFormation from './DeleteUniteDeFormation';
import EditChapitre from './EditChapitre';
import EditUniteDeFormation from './EditUniteDeFormation';
import Add from '../../../../../assets/icons/add.png';
import Option from '../../../../../assets/icons/options.svg';
import Play from '../../../../../assets/icons/Play.png';
import Hn from '../../../../../components/common/Hn';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';
import { RouteEnum } from '../../../../../enum/RouteEnum';

import './createUnite.css';

const validationSchema = Yup.object().shape({
  titre: Yup.string().required('Le titre est obligatoire'),
});

function CreateChapitreElearning() {
  const [chapitres, setChapitres] = useState([]);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return 'danger';
    } if (value) {
      return 'info';
    }
    return 'warning';
  };
  const { id } = useParams();
  const uri = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getChapitres();
  }, []);

  // Récupérer le détail d'une formation elearning par (id) et ses chapitres
  function getChapitres() {
    axios
      .get(`${uri}/elearning/${id}/chapitres`)
      .then((result) => {
        setChapitres(result?.data);
      })
      .catch((err) => {
        console.log('err sessions', err);
      });
  }

  const navigate = useNavigate();

  // Créer un chapitre
  // Continuez avec l'exécution du gestionnaire de soumission

  const handleSubmit = (values) => {
    const data = new FormData();
    data.append('titre', values.titre);
    data.append('elearning', id);

    axios
      .post(`${uri}/chapitre/new`, data)
      .then((result) => {
        navigate(`/admin/create-unite-de-formation/${result?.data?.id}`);
      })
      .catch((err) => {
        console.log('err training', err);
      });
  };

  return (
    <BaseContainer>
      <div className="lists">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={RouteEnum.ADMIN_E_LEARNING}>
                Formations Elearning - Actions de formation
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Créer une action de formation
            </li>
          </ol>
        </nav>
        <div className="h-100 p-5 text-white bg-black rounded-3">
          <Hn
            title="Créer une action de formation"
            level="h4"
            className="h4-size"
            plus={false}
          />
          <Hn
            title="Etape 2 : Ajout de contenu"
            level="p"
            className="py-2 fw-bold"
            plus={false}
          />

          <div className="form-elements mt-2">
            <div className="form-aria p-4">
              <Formik
                initialValues={{
                  titre: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <Hn
                      title="Chapitre"
                      level="p"
                      className="py-2 fw-bold"
                      plus={false}
                    />
                    <div className="col-md-10">
                      <label className="form-label">Titre</label>
                      <input
                        status={onHandelStatus(
                          touched.ref,
                          errors.ref,
                          values.ref,
                        )}
                        type="text"
                        className="form-control"
                        id="titre"
                        name="titre"
                        placeholder="Ajouter un titre"
                        onChange={handleChange('titre')}
                        value={values.titre}
                        onBlur={handleBlur('titre')}
                      />
                      <ErrorMessage name="titre">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-2 pt-4">
                      <button
                        type="submit"
                        className="btn btn-outline-primary float-end"
                      >
                        Ajouter
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            <Accordion defaultActiveKey="0">
              {chapitres.map((chap, i) => (
                <Accordion.Item eventKey={i} key={chap.id} className="position-relative">
                  <Dropdown align="end" className="dropright">
                    <Dropdown.Toggle variant="secondary" className="without-caret">
                      <img src={Option} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu variant="dark">
                      <Dropdown.Item href={`/admin/create-unite-de-formation/${chap?.id}`}>
                        <img src={Add} className="pe-1" alt="" />
                        Unité
                      </Dropdown.Item>
                      <EditChapitre chapitreToBeEdited={chap} />
                      <DeleteChapitre chapitreToBeDeleted={chap} />
                    </Dropdown.Menu>
                  </Dropdown>
                  <Accordion.Header>
                    <span className="header-text">{chap.titre}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    {chap?.unites?.map((unit) => (
                      <Fragment key={unit.id}>
                        <div className="row g-3">
                          <div className="col-md-11">
                            <Hn
                              title={unit.titre}
                              level="p"
                              className="py-2 fw-bold"
                              plus={false}
                            />
                            <p>
                              <img src={Play} className="pe-1" />
                              {unit.document}
                            </p>
                          </div>
                          <div className="col-md-1 d-flex justify-content-around align-bottom align-self-end mb-3">
                            <EditUniteDeFormation uniteToBeEdited={unit} />
                            <DeleteUniteDeFormation uniteToBeDeleted={unit} />
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>

            <div className="col-12 mt-5">
              <a
                href={`/admin/add-skills-elearning/${id}`}
                className="btn btn-primary float-end"
              >
                Suivant
              </a>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
}

export default CreateChapitreElearning;
