import React, { useEffect } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useModal } from 'leeloo-react/component/hook/useModal';
import { useToast } from 'leeloo-react/component/hook/useToast';
import {
  ResponseHttpType,
} from 'leeloo-react/src/model/dataProvider/ResponseHttpType';
import { Button, Spinner } from 'react-bootstrap';

import {
  DataTableButtonType,
} from '../../../model/dataTable/DataTableButtonType';

type Props = {
    isLoad: boolean,
    fetchPromise?: () => Promise<void>|Promise<ResponseHttpType>
    fetchPromises?: () => Promise<void>[]|Promise<ResponseHttpType>[],
    handleLoad?: (newValue: boolean) => void,
    messageFetchOne?: string,
    messageFetchMultiples?: string,
    messageSuccess?: string,
}

export function DataTableDefaultButton<T>(props: DataTableButtonType<T> & Props) {
  const { confirm } = useModal();
  const toast = useToast();

  const handleLoad = (newLoadState: boolean) => {
    if (props.handleLoad != null) {
      props.handleLoad(newLoadState);
    }
  };

  useEffect(() => {
    if (props.isLoad) {
      if (props.rows && props.fetchPromises != null) {
        fetchMultiples();
      }
      if (props.row) {
        fetchOne();
      }
    }
  }, [props.isLoad]);

  const fetchOne = async () => {
    const response = await confirm(props.messageFetchOne ?? 'Confirmez-vous l\'action sur cet élément ?');
    if (response && props.fetchPromise != null) {
      return props.fetchPromise().then(() => {
        isSuccess();
      });
    }
    handleLoad(false);
    return null;
  };

  const fetchMultiples = async () => {
    const response = await confirm(props.messageFetchMultiples ?? 'Confirmez-vous l\'action sur ces éléments ?');
    if (response && props.fetchPromises != null) {
      return Promise.all(props.fetchPromises()).then(() => {
        isSuccess();
      });
    }
    handleLoad(false);
    return null;
  };

  const isSuccess = () => {
    handleLoad(false);

    if (props.cleanSelection) {
      props.cleanSelection();
    }

    if (props.onClick && props.row) {
      props.onClick(props.row);
    }

    if (props.dataSource) {
      props.dataSource.refreshData();
    }

    if (props.messageSuccess) {
      toast(props.messageSuccess, 'success');
    }
  };

  return (
    <Button
      variant={props.variant}
      type="button"
      size={props.size}
      onClick={() => (props.handleLoad ? props.handleLoad(true) : null)}
      className={props.pictoOnly ? 'square' : ''}
    >
      {!props.isLoad ? (
        <>
          {props.icon && (
            <Icon
              name={`uil uil-${props.icon}`}
              additionalClasses={!props.pictoOnly ? 'me-1' : ''}
            />
          )}
          {!props.pictoOnly && props.label ? props.label : ''}
        </>
      ) : (
        <Spinner animation="border" size="sm" />
      )}
    </Button>
  );
}

DataTableDefaultButton.defaultProps = {
  fetchPromise: null,
  fetchPromises: null,
  handleLoad: null,
  messageFetchOne: null,
  messageFetchMultiples: null,
  messageSuccess: null,
};
