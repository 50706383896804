import React, { ReactElement } from 'react';

import { RatingSummary } from './RatingSummary';
import { ELearningDto } from '../../model/dto/ELearningDto';
import { OnSiteDto } from '../../model/dto/OnSiteDto';

type RatingDisplayProps = {
    formation: ELearningDto|OnSiteDto,
}

const countLabel = (item: { rating: number, count: number }) : string => `
    ${item.rating} étoile${item.rating < 2 ? '' : 's'}:
    ${item.count} vote${item.count <2 ? '' : 's'}
`;

/**
 * Affichage de la notation d'une formation sous forme d'etoiles avec les details si disponibles
 * @param props
 * @constructor
 */
export function RatingDisplay(props: RatingDisplayProps): ReactElement {
    return (
        <div id="rating-div" className="p-3">
            <div className="row pt-3">
                <h5>Notation</h5>
                <div className="col-lg-8 col-md-7 col-xs-6 pt-3 text-white">
                    <RatingSummary formation={props.formation} />
                    {props.formation.aggregatedRatings && (
                        <div className="rating-details">
                            {props.formation.aggregatedRatings?.map((item) => (
                                <div key={item.rating}>
                                    {countLabel(item)}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
