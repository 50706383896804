import React, { ReactElement } from 'react';

import { LeelooSpa } from 'leeloo-react/LeelooSpa';

import { JwtAuthProvider } from './authProvider/JwtAuthProvider';
import { LoginForm } from './components/pages/auth/LoginForm';
import { RouteConfig } from './config/RouteConfig';
import { StoreConfig } from './config/StoreConfig';
import { CustomDataProvider } from './dataProvider/CustomDataProvider';
import { RouteEnum } from './enum/RouteEnum';
import './scss/main.scss';

export default function App(): ReactElement {
  const sentryConfig: any = (process.env.REACT_APP_DEV_MODE) ? null : {
    dsn: '',
    environment: process.env.REACT_APP_SENTRY_ENV,
  };

  return (
    <LeelooSpa
      apiUrl={process.env.REACT_APP_API_URL!}
      dataProvider={CustomDataProvider}
      routeConfig={RouteConfig}
      storeConfig={StoreConfig}
      authProvider={JwtAuthProvider}
      defaultPrivateRoute={RouteEnum.LOADED}
      loginForm={LoginForm}
      sentryConfig={sentryConfig}
      componentConfig={{ dataTable: { paginationRowPerPage: [50, 100] } }}
      disabledRedirectIfDisconnect
    />
  );
}
