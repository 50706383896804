import React, { ReactElement, useEffect } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { KpiType } from '../../../../model/kpis/KpiType';
import { ThemeStatsType } from '../../../../model/kpis/ThemeStatsType';
import Barchart from '../../../chart/Barchart';
import { RouterCustomFileLink } from '../../../router/RouterCustomFileLink';

type KpiThematiqueELearningByCollaboratorProps = {
  dateRange: KpiType['dateRange'];
  agency: string;
  collaborator: string;
}

export function KpiThematiqueELearningByCollaborator({ dateRange, agency, collaborator }: KpiThematiqueELearningByCollaboratorProps): ReactElement {
  const dsThematics = useDataSource<ThemeStatsType[]>({
    resource: ResourceDataProviderEnum.kpisManagerELearningThematicsByCollaborator,
    singleResult: true,
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (dateRange || agency || collaborator) {
      const params = {
        uriParameters: {
          startAt: dateRange?.startAt || null,
          endAt: dateRange?.endAt || null,
          agency: agency || null,
          collaborator: collaborator || null,
        },
      };

      dsThematics.setParams(params);
    }
  }, [dateRange, agency, collaborator]);

  return (
    <div className="p-3 bg-dark-grey mb-4">
      <div className="d-flex justify-content-between">
        <h4>Formations E-Learning</h4>
        <RouterCustomFileLink
          resource={ResourceDataProviderEnum.kpisManagerELearningThematicsByCollaboratorExport}
          params={{
            data: {
              startAt: dateRange?.startAt || null,
              endAt: dateRange?.endAt || null,
              agency: agency || null,
              collaborator: collaborator || null,
            },
          }}
          nameFile="dashboard_theme.csv"
          className="btn btn-outline-primary square"
        >
          <Icon name="uil uil-export" />
        </RouterCustomFileLink>
      </div>
      <Barchart
        label="Formations"
        backgroundColor={['#9500FF']}
        labels={dsThematics?.oneData?.map((value) => value.name) ?? []}
        data={dsThematics?.oneData?.map((value) => value.nbrFormation) ?? []}
      />
    </div>
  );
}
