import React, { ReactElement } from 'react';

import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { OnSiteDto } from '../../../model/dto/OnSiteDto';
import Banner from '../../banner/Banner';

export function OnSiteBanner(): ReactElement {
  const dsHighlight = useDataSource<OnSiteDto>({
    resource: ResourceDataProviderEnum.training,
    params: {
      filter: { forward_catalog: true, status: true },
    },
  });

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dsHighlight.total > 0 && (
        <div className="pb-3">
          <Banner
            data={dsHighlight.dataList}
            url="detailformation"
            from="presentiel"
          />
        </div>
      )}
    </>
  );
}
