import React, { ReactElement, useEffect } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../../enum/ResourceDataProviderEnum';
import { KpiType } from '../../../../../model/kpis/KpiType';
import { ThemeStatsType } from '../../../../../model/kpis/ThemeStatsType';
import Barchart from '../../../../chart/Barchart';
import { RouterCustomFileLink } from '../../../../router/RouterCustomFileLink';

export function KpiThematique({ dateRange }: KpiType): ReactElement {
  const dsThematics = useDataSource<ThemeStatsType[]>({
    resource: ResourceDataProviderEnum.kpisThematics,
    singleResult: true,
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (dateRange) {
      const params = {
        uriParameters: {
          startAt: dateRange?.startAt || null,
          endAt: dateRange?.endAt || null,
        },
      };

      dsThematics.setParams(params);
    }
  }, [dateRange]);

  return (
    <div className="p-3 bg-dark-grey">
      <div className="d-flex justify-content-between">
        <h4>Par thématique</h4>
        <RouterCustomFileLink
          resource={ResourceDataProviderEnum.kpisThematicsExport}
          params={{
            data: {
              startAt: dateRange?.startAt || null,
              endAt: dateRange?.endAt || null,
            },
          }}
          nameFile="dashboard_theme.csv"
          className="btn btn-outline-primary square"
        >
          <Icon name="uil uil-export" />
        </RouterCustomFileLink>
      </div>
      <Barchart
        label="Formation"
        backgroundColor={['#9500FF']}
        labels={dsThematics?.oneData?.map((value) => value.name) ?? []}
        data={dsThematics?.oneData?.map((value) => value.nbrFormation) ?? []}
      />
    </div>
  );
}
