import React, { useContext } from 'react';

import { SubmitBtn } from 'leeloo-react/component/form/action/SubmitBtn';
import { CollectionFormField } from 'leeloo-react/component/form/field/CollectionFormField';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { ContextForm } from 'leeloo-react/component/form/Form';
import { FormControl } from 'react-bootstrap';

import { ThematicCategoryForm } from './ThematicCategoryForm';
import { ThematicDto } from '../../../../model/dto/ThematicDto';

export function ThematicForm() {
  const {
    errors,
  } = useContext(ContextForm);

  return (
    <>
      <div className="row">
        <InputField<ThematicDto>
          className="col-md-4 mb-3"
          label="Référence"
          name="ref"
        />
        <InputField<ThematicDto>
          className="col-md-8 mb-3"
          label="Nom de la thématique"
          name="name"
        />

        <CollectionFormField<ThematicDto>
          className="sub"
          name="categories"
          label="Catégories"
        >
          <ThematicCategoryForm />
        </CollectionFormField>
        {errors && Object.hasOwn(errors, 'categories') && (
          <FormControl.Feedback type="invalid" className="d-block">
            Champ obligatoire.
          </FormControl.Feedback>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <SubmitBtn className="btn btn-primary" label="Sauvegarder" />
      </div>
    </>
  );
}
