import React, { ReactElement } from 'react';

import { LearnerDataTable } from './LearnerDataTable';
import {
  ResourceDataProviderEnum,
} from '../../../../enum/ResourceDataProviderEnum';
import { BaseContainer } from '../../../layout/BaseContainer';

export function LearnerELearningListPage(): ReactElement {
  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Formations E-learning</h1>
        <h2 className="mt-4 plus">Apprenants</h2>
      </div>
      <div className="pt-5">
        <LearnerDataTable resource={ResourceDataProviderEnum.learner} />
      </div>
    </BaseContainer>
  );
}
