import { ReducerStorageEnum } from 'leeloo-react/enum/store/ReducerStorageEnum';
import { StoreConfigType } from 'leeloo-react/model/store/StoreConfigType';

import { ReducerEnum } from '../enum/store/ReducerEnum';

/**
 * Configuration simple des reducers ici
 * Les CRUD seront automatiquement disponible et utilisable avec le hook useWriteRedux
 */
export const StoreConfig: StoreConfigType = {
  persistBlackList: [],
  reducers: [
    {
      name: ReducerEnum.agency,
      initialState: {},
      storage: ReducerStorageEnum.cookieSession,
    }, {
      name: ReducerEnum.config,
      initialState: {
        displayFront: true,
      },
      storage: ReducerStorageEnum.cookieSession,
    }],
};
