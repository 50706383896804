import React from 'react';

import { SimpleColumn } from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Icon } from 'leeloo-react/component/display/Icon';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import Badge from 'react-bootstrap/Badge';
import { useParams } from 'react-router-dom';

import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { ConcurrentSession, UserSessionDto } from '../../../../model/dto/UserSessionDto';
import { DataTableCreateButton } from '../../../dataTable/button/DataTableCreateButton';
import { DataTableDeleteButton } from '../../../dataTable/button/DataTableDeleteButton';
import { LengthColumn } from '../../../dataTable/column/LengthColumn';
import { DataTableBulksActions } from '../../../dataTable/DataTableBulksActions';

type ParticipantAddDataTableProps = {
    resource: string,
    idEntity?: string,
}

type Row = {
    id: number|string
}

export function ParticipantAddDataTable(props: ParticipantAddDataTableProps) {
  const { idEntity } = useParams();
  const dsParticipants = useDataSource({
    resource: ResourceDataProviderEnum.participant,
    params: {
      filter: { idEntity: props.idEntity ?? idEntity },
      pagination: { page: 1, take: 50 },
    },
  });

  const getRowIds = (rows:Row[]) : (number|string)[] => rows.map((row: Row) => row.id);

  /**
   * Décide si le bouton d'ajout de participants via les cases à cocher doit être présent ou non
   * @param rows
   */
  const getCreateRessourceForBulkActions = (rows:Row[]) : string|undefined => ((dsParticipants.dataList as UserSessionDto[]).some(
    (item :UserSessionDto) : boolean => getRowIds(rows).includes(item.id) && (!item.inSession && !item.inConcurrentSession),
  ) ? props.resource : undefined);

  /**
   * Récupère le message à afficher au survol du badge (infobulle)
   * @param row
   */
  const getBadgeTitle = (row : UserSessionDto) : string => {
    const participant : UserSessionDto | undefined = (dsParticipants.dataList as UserSessionDto[]).find(
      (item :UserSessionDto) : boolean => row.id == item.id,
    );
    const concurrentSession : ConcurrentSession | undefined = (participant && participant.concurrentSessions)
      ? participant.concurrentSessions.find(() => true)
      : undefined;

    if (!concurrentSession) return '';

    const startDate = (new Date(concurrentSession.dateStart))?.toLocaleDateString('fr', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const endDate = (new Date(concurrentSession.dateEnd))?.toLocaleDateString('fr', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    return `Ce participant est déjà inscrit à la session de formation ${concurrentSession.title ?? ''} du ${startDate} au ${endDate}`;
  };

  /**
   * Détermine les boutons qui doivent apparaitre dans la colonne actions du tableau
   * @param row
   */
  const getActionRow = (row: UserSessionDto) => {
    if (!row.inSession && !row.inWebinar) {
      // Cas d'un participant non-inscrit à la session ou au webinaire
      return row.inConcurrentSession
        ? [
          <h2>
            <Badge title={getBadgeTitle(row)} bg="secondary">
              <Icon name="uil uil-info-circle" />
            </Badge>
          </h2>,
        ]
        : [<DataTableCreateButton key={0} row={row} dataSource={dsParticipants} resource={props.resource} data={{ idEntity: props.idEntity ?? idEntity }} variant="outline-primary" />];
    }

    // Cas d'un participant déjà inscrit à la session ou au webinaire
    return [<DataTableDeleteButton key={0} row={row} dataSource={dsParticipants} resource={props.resource} data={{ idEntity: props.idEntity ?? idEntity }} />];
  };

  return (
    <DataTable
      dataSource={dsParticipants}
      filter={(
        <Filter>
          <InputField name="search" label="Rechercher" />
        </Filter>
      )}
      actionRow={getActionRow}
      onChangeCheckedRow={(rows: Row[], cleanSelection) => (
        <DataTableBulksActions
          rows={rows}
          dataSource={dsParticipants}
          cleanSelection={cleanSelection}
          createResource={getCreateRessourceForBulkActions(rows)}
          deleteResource={props.resource}
          data={{ idEntity: props.idEntity ?? idEntity }}
        />
      )}
    >
      <SimpleColumn field="contactPrenom" title="Nom" sortable />
      <SimpleColumn field="contactNom" title="Prénom" sortable />
      <SimpleColumn field="mailcontact" title="Email" sortable />
      <SimpleColumn field="agencymain" title="Agent" sortable />
      <LengthColumn field="agenceEmail" title="Email agent" sortable />
      <SimpleColumn field="offre" title="Offre" sortable />
      <SimpleColumn field="metier" title="Role" sortable />
      <SimpleColumn field="presence" title="Présence" sortable />
    </DataTable>
  );
}

ParticipantAddDataTable.defaultProps = {
  idEntity: null,
};
