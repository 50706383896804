import React, { ReactElement } from 'react';

import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { Breadcrumb } from 'react-bootstrap';

import { CategoryTypeEnum } from '../../../../enum/CategoryTypeEnum';
import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { CategoryDto } from '../../../../model/dto/CategoryDto';
import { BaseContainer } from '../../../layout/BaseContainer';

export function CategoryPage(): ReactElement {
  const { currentParamsRoute, goTo } = useRouter();

  // on va récupérer mon total de catégorie dans un type donné
  const dsCategory = useDataSource<CategoryDto>({
    resource: ResourceDataProviderEnum.category,
    singleResult: true,
    params: {
      id: currentParamsRoute.id,
    },
  });

  return (
    <BaseContainer>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => goTo(RouteEnum.ADMIN_THEMATIC)}>
          Thématiques
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          Détail catégorie
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="pt-5">
        <div className="headupdate">
          <h1 className="plus mb-4">{dsCategory.oneData?.name}</h1>
          <RouterLink routeName={RouteEnum.ADMIN_CATEGORY_EDIT} params={{ id: currentParamsRoute.id }} className="btn btn-primary float-end me-3">
            Modifier
          </RouterLink>
        </div>
        <div className="h-100 p-5 text-white bg-dark rounded-3 me-3">
          <h4 className="mb-5">
            Informations générales
          </h4>
          <div className="form-elements">
            <div className="row">
              <div className="col-4 mb-3">
                <span className="d-block fw-bold">Type de ma catégorie</span>
                {CategoryTypeEnum[dsCategory.oneData?.type ?? 1]}
              </div>
              <div className="col-8 mb-3">
                <span className="d-block fw-bold">Nom de la catégorie</span>
                {dsCategory.oneData?.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseContainer>
  );
}
