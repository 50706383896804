import React, { ReactElement } from 'react';

import { SimpleContainer } from '../../layout/SimpleContainer';

/**
 * Surchagre du visuel du login de Leeloo
 * @param props
 * @returns
 */
export function LoginForm(): ReactElement {
  return (
    <SimpleContainer>
      <div className="row justify-content-center align-self-center">
        <div className="col-sm-6 col-lg-5">
          <div className="card">
            <div className="card-head">
              <h1 className="text-center p-3">Connexion</h1>
            </div>
            <div className="card-footer mb-3">
              <a href={`${process.env.REACT_APP_API_URL!}/connect/azure`} className="btn btn-primary w-100 mb-2">
                Se connecter
              </a>
            </div>
          </div>
        </div>
      </div>
    </SimpleContainer>
  );
}
