import { Button } from 'react-bootstrap';
import React, { useState } from 'react';

import {ModalForm} from 'leeloo-react/component/form/ModalForm';
import {InputField} from 'leeloo-react/component/form/field/InputField';
import { SubmitBtn } from 'leeloo-react/component/form/action/SubmitBtn';
import { Icon } from 'leeloo-react/component/display/Icon';

type Tags = string[];

export function PopupTags(props : {tags: Tags, showModal: boolean, closeModal: () => void, updateTags: (newTags: Tags) => void}) {
  const [tags, setTags] = useState<Tags>(props.tags && props.tags.length>0 ? props.tags : ['']);

  /**
   * Dans le cas ou il y a un seul tag, il peut être vide (voir spec)
   * C'est ainsi qu'on peut supprimer les tags sur une formation
   * Sinon, aucun tag ne doit être vide
   */
  const areTagsValid = () => {
    return tags.length ===1 || !tags.reduce((a,v) => (v.length===0) || a, false )
  }
  const [tagsValid, setTagsValid] = useState(areTagsValid());
  const [submitted, setSubmitted] = useState(false);

  /**
   * Suppression d'un tag
   * @param index
   */
  const removeTag = (index:number) => () => {
    if(tags.length>1){
      tags.splice(index,1);
      setAndValidateTags()
    }
  }

  /**
   * Edition d'un tag
   * @param index
   */
  const changeTag = (index:number) => (value:string) =>  {
    tags[index] = value;
    setAndValidateTags()
  }

  /**
   * Ajout d'un tag
   * @param index
   */
  const addTag = (index:number) => () => {
    tags.splice(index+1,0,'');
    setAndValidateTags()
  }

  /**
   * Mise à jour de la variable qui decide si le bouton Valider est cliquable
   *   et si on doit ajouter des alertes
   */
  const setAndValidateTags = () =>{
    setTags([...tags])
    setTagsValid(areTagsValid());
    setSubmitted(false);
  }

  return (
    <ModalForm<{tags:Tags}>
      show={props.showModal}
      size="lg"
      initialValue={{tags : tags}}
      onSubmit={async (data :{tags : Tags}) => {
        setSubmitted(true);
        if (tagsValid) {
          props.updateTags(data.tags)
          props.closeModal()
        }
      }}
      handleClose={props.closeModal}
      noCloseAfterSubmit
      title="Editer les tags de la formation"
      customFooter={
        <>
          {!tagsValid && submitted && <span className="invalid-form-alert">Un champ est vide, veuillez le renseigner ou le supprimer</span>}
          <SubmitBtn className={"btn btn-primary"}>
            Valider
          </SubmitBtn>
        </>}
    >
      { tags.map( (tag : string, index : number) => (
        <div className={"row mt-1"} key={index}>
          <InputField
            value={tag}
            onChangeValue={changeTag(index)}
            className={'col-md-10'}
            placeholder="Saisir un nouveau tag..."
          />

          <div className="col-md-1">
            <Button title="Ajouter un tag" type="button" className={"btn btn-primary"} onClick={addTag(index)}>
              <Icon name="uil uil-plus"/></Button>
          </div>
          <div className="col-md-1">
            {tags.length >1 && <Button title="Supprimer le tag" type="button" className={"btn btn-primary"} onClick={removeTag(index)}>
              <Icon name="uil uil-trash" />
            </Button>}
          </div>

        </div>
      ))}
    </ModalForm>
  )
}
