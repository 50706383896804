import * as Yup from 'yup';

import React, { useEffect, useRef, useState } from 'react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import Add from '../../assets/icons/add.png';
import Hn from '../../components/common/Hn';
import { useEditor } from '../../components/hook/useEditor';
import { BaseContainer } from '../../components/layout/BaseContainer';
import { RouteEnum } from '../../enum/RouteEnum';
import { getYoutubeIdHelper } from '../../helpers/getYoutubeIdHelper';

import './webinar.css';

const validationSchema = Yup.object().shape({
});
function CreateWebinair() {
  const pdfRef = useRef();
  const [providers, setProviders] = useState([]);
  const [formateurs, setFormateurs] = useState([]);
  const [themes, setThemes] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const { editorConfiguration } = useEditor();
  const { goTo } = useRouter();
  const { authData } = useAuthProvider();

  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const uri = process.env.REACT_APP_BASE_URL;

  // récupérer les formateurs avec leurs types(interne ou externe)
  function getFormer(type) {
    let url = '/former';
    if (type) {
      url = `/former/organize/${type}`;
    }
    axios
      .get(uri + url)
      .then((result) => {
        if (type) {
          setFormateurs(result?.data);
        } else {
          setFormateurs(result?.data?.data);
        }
      })
      .catch((err) => {
        console.log('err formateurs', err);
      });
  }

  // créer un nouveau webinaire
  const handleSubmit = (values) => {
    const fr = [];
    values.former.map((op) => {
      fr.push(op.value);
    });

    const selectedThemes = [];
    selectedOption.map((op) => {
      selectedThemes.push(op.value);
    });

    const data = new FormData();
    data.append('ref', values.reference);
    data.append('title', values.intitule);
    data.append('themes', selectedThemes);
    data.append('date', values.date);
    data.append('hour', values.heure);
    fr.forEach((former) => data.append('former[]', former));
    data.append('audience', values.prerequis);
    data.append('objects', values.objectifs);
    data.append('training_program', values.program);
    data.append('programFile', values.pdf);
    data.append('provider', values.prestataire);
    data.append('duration', values.duree);
    data.append('capacity_min', values.min);
    data.append('capacity_max', values.max);
    data.append('cost', values.tarif);
    data.append('status', values.actif);
    data.append('comment', values.commentaire);
    data.append('forward_catalog', values.miseavant);
    data.append('manager_spec', values.manager);
    data.append('obligation', values.obligation);
    data.append('youtubeId', getYoutubeIdHelper(values.youtubeId));

    axios.post(`${uri}/webinars/new`, data)
      .then((result) => {
        setTimeout(() => {
          goTo(RouteEnum.ADMIN_WEBINAR_SHOW, { id: result?.data?.id });
        }, 1500);
      })
      .catch((err) => {
        console.log('err webinar', err);
      });
  };

  // récupérer les prestataires
  function getProviders() {
    axios
      .get(`${uri}/provider`)
      .then((result) => {
        setProviders(result?.data?.data);
      })
      .catch((err) => {
        console.log('err prestataire', err);
      });
  }
  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: '16px',
      fontWeight: state.isSelected ? '600' : '400',
      backgroundColor: '#3A4048',
      color: 'white',
      border: 'none',
      borderRadius: 'none',
    }),
    dropdownIndicator: (base) => ({
      color: '#006FFF',
      marginRight: '10px',
    }),
    menu: (base) => ({
      backgroundColor: '#212730',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? '#1E2E45' : '#212730',
      fontWeight: state.isSelected ? '600' : '400',
      borderBottom: '1px solid #3A4048',
      color: 'white',
      margin: '5px 14px 5px 10px',
    }),
    multiValue: (base, state) => ({
      ...base,
      color: 'white',
      backgroundColor: '#1E2E45',
      fontWeight: state.isSelected ? '600' : '400',
      margin: '6px 10px 5px 10px',
      padding: '5px 14px 5px 10px',
    }),
    multiValueLabel: (base) => ({
      color: 'white',
    }),
    multiValueRemove: (base) => ({
      color: '#006FFF',
      fontSize: '16px',
      height: '20px',
      width: '20px',
    }),
  };
    // récupérer les thémes
  function getThem() {
    axios
      .get(`${uri}/thematic`)
      .then((result) => {
        result?.data?.data.map((theme) => {
          setThemes((options) => [
            ...options,
            { value: theme.id, label: theme.name },
          ]);
        });
      })
      .catch((err) => {
        console.log('err theme', err);
      });
  }

  // si on choisit un type d'un formateur ca nous donne automatiquement sa liste des noms/prénoms de ce type choisi
  function ChangeList(value) {
    getFormer(value);
  }
  useEffect(() => {
    getProviders();
    getFormer();
    getThem();
  }, []);

  return (
    <BaseContainer>
      <div className="lists">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={RouteEnum.ADMIN_WEBINAR}>
                Webinar
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Créer un webinar
            </li>
          </ol>
        </nav>
        <div className=" p-5 text-white bg-dark rounded-3 me-3">
          <Hn
            title="Créer un webinar"
            level="h4"
            className="h4-size"
            plus={false}
          />
          <Hn
            title="Étape 1 : Informations générales"
            level="p"
            className="py-2 fw-bold"
            plus={false}
          />

          <div className="form-elements">
            <Formik
              initialValues={{
                reference: '',
                intitule: '',
                date: '',
                heure: '',
                former: [],
                prerequis: '',
                objectifs: '',
                program: '',
                pdf: null,
                object: '',
                prestataire: '',
                duree: '',
                min: 0,
                max: 0,
                tarif: 0,
                actif: '0',
                commentaire: '',
                miseavant: 0,
                manager: 0,
                obligation: 0,
                youtubeId: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-4">
                    <label className="form-label">
                      Référence*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ref"
                      name="reference"
                      placeholder="Référence"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.reference}
                    />
                    <ErrorMessage name="reference">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Intitulé*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="intitule"
                      id="init"
                      placeholder="Intitulé"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.intitule}
                    />
                    <ErrorMessage name="intitule">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label" htmlFor="youtubeId">
                      ID youtube
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="youtubeId"
                      name="youtubeId"
                      placeholder="https://www.youtube.com/embed/fiSlH3rApJA"
                      onChange={handleChange('youtubeId')}
                      value={values.youtubeId}
                      onBlur={handleBlur('youtubeId')}
                    />
                    <ErrorMessage name="youtubeId">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Thème*
                    </label>
                    <Select
                      styles={customStyles}
                      onChange={setSelectedOption}
                      className="themeSelect"
                      options={themes}
                      isMulti
                      noOptionsMessage={() => 'Aucun résultat'}
                    />
                    <ErrorMessage name="themes">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Date</label>
                    <input type="date" className="form-control" name="date" id="date-d" value={values.date} placeholder="Date" onChange={handleChange} />

                    <ErrorMessage name="date">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">

                    <label className="form-label"> Heure</label>
                    <input type="time" className="form-control date-hour" name="heure" id="heure" value={values.heure} placeholder="Date de début" onChange={handleChange} />

                    <ErrorMessage name="heure">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Formateur
                    </label>

                    <select
                      className="form-select"
                      aria-label="Default select example"
                      defaultValue="sélectinner dans la liste"
                      onChange={(event) => ChangeList(event.target.value)}
                    >

                      <option value="">sélectionner dans la liste</option>
                      <option value="interne">Interne</option>
                      <option value="externe">Externe</option>
                    </select>

                    <ErrorMessage name="tarif">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label py-2"> </label>
                    <Select
                      styles={customStyles}
                      onChange={(value) => setFieldValue('former', value)}
                      className="themeSelect"
                      options={
                        formateurs.map((formateur) => ({
                          label: formateur.lastName,
                          value: formateur.id,
                        }))
                      }
                      isMulti
                      placeholder="Sélectionner un ou des formateur(s)"
                      name="fr"
                    />
                    <ErrorMessage name="fr">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-12">
                    <label className="form-label">
                      Pré-requis et public visé
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.prerequis}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('prerequis', data);
                      }}
                    />
                    <ErrorMessage name="prerequis">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Objectifs
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.objectifs}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('objectifs', data);
                      }}
                    />
                    <ErrorMessage name="object">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Programme de formation
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.program}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('program', data);
                      }}
                    />
                    <ErrorMessage name="program">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">
                      Programme de formation
                    </label>
                    <span
                      className="form-control"
                      onClick={() => pdfRef.current.click()}
                    >
                      <img src={Add} className="pe-1" />
                      {' '}
                      {pdfRef?.current?.value ? pdfRef?.current?.value : 'Ajouter un fichier'}
                    </span>

                    <input
                      ref={pdfRef}
                      multiple={false}
                      type="file"
                      name="pdf"
                      onChange={(event) => {
                        setFieldValue('pdf', event.currentTarget.files[0]);
                      }}
                      hidden
                      accept=".pdf"
                    />
                    <ErrorMessage name="pdf">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Prestataire
                    </label>
                    <select
                      className="form-select"
                      name="prestataire"
                      id="prestat"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prestataire}
                    >
                      <option value="">Sélectionner dans la liste</option>
                      {providers.map((provider, i) => (
                        <option value={provider.id} key={i}>
                          {provider?.contact_name}
                          {' '}
                          |
                          {provider?.contact_surname}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage name="prestataire">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Durée théorique (en heure)*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="duree"
                      id="duree"
                      placeholder="00:00"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.duree}
                      // pattern="\d\d:\d\d"
                    />
                    <ErrorMessage name="duree">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">Capacité d'accueil minimale</label>
                    <input type="number" className="form-control" id="capacity-min" value={values.min} placeholder="00" onChange={handleChange} name="min" />
                    <ErrorMessage name="min">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4">
                    <label className="form-label">Capacité d’accueil maximale</label>
                    <input type="number" className="form-control" id="capacity-max" value={values.max} placeholder="00" onChange={handleChange} name="max" />
                    <ErrorMessage name="max">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Tarif
                    </label>
                    <input
                      type="number"
                      step="0.5"
                      className="form-control cost"
                      name="tarif"
                      id="tarif"
                      placeholder="00"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tarif}
                    />
                    <ErrorMessage name="tarif">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">
                      Actif
                    </label>
                    <select
                      className="form-select"
                      name="actif"
                      id="actif"
                      onChange={handleChange('actif')}
                      onBlur={handleBlur}
                      value={values.actif}
                    >
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                    </select>
                    <ErrorMessage name="actif">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-12">
                    <label className="form-label">
                      Commentaire
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={values.commentaire}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('commentaire', data);
                      }}
                    />
                    <ErrorMessage name="commentaire">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="col-md-4 col-xs-12">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="miseavant"
                        type="checkbox"
                        id="check"
                        onClick={(e) => { setFieldValue('miseavant', e.target.checked); }}
                        value={values.miseavant}
                      />
                      <label className="form-check-label" htmlFor="check">
                        Mettre en avant dans le catalogue
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="manager"
                        type="checkbox"
                        id="check2"
                        onClick={(e) => { setFieldValue('manager', e.target.checked); }}
                        value={values.manager}
                      />
                      <label className="form-check-label" htmlFor="check2">
                        Spécifique au manager
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="form-check">
                      <input
                        className="form-check-input square-check"
                        name="obligation"
                        type="checkbox"
                        id="check3"
                        onClick={(e) => { setFieldValue('obligation', e.target.checked); }}
                        value={values.obligation}
                      />
                      <label className="form-check-label" htmlFor="check3">
                        Participe à l'obligation de formation
                      </label>
                    </div>
                  </div>

                  <div className="col-12 mt-5">
                    <Link to={RouteEnum.ADMIN_WEBINAR} className="btn btn-link text-decoration-none resetBtn">
                      Annuler
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-primary float-end"
                    >
                      Suivant
                    </button>

                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {/* lists */}
    </BaseContainer>
  );
}

export default CreateWebinair;
