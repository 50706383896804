import React, { ReactElement } from 'react';

import { LearnerDataTable } from './LearnerDataTable';
import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { BaseContainer } from '../../../layout/BaseContainer';

export function LearnerOnSiteListPage(): ReactElement {
  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Formations présentielles</h1>
        <h2 className="mt-4 plus">Participants</h2>
      </div>
      <div className="pt-5">
        <LearnerDataTable resource={ResourceDataProviderEnum.participant} />
      </div>
    </BaseContainer>
  );
}
