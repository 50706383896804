import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // on va mettre une valeur négartive afin de couvrir les éventuels margin/paddings
    window.scrollTo(-20, 0);
  }, [pathname]);

  return null;
}
