import React from 'react';

import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useParams } from 'react-router-dom';

import { ParticipantAddDataTable } from './ParticipantAddDataTable';
import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { BaseContainer } from '../../../layout/BaseContainer';

export function ParticipantWebinarManagePage() {
  const { idEntity } = useParams();
  const { goTo } = useRouter();

  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Webinars</h1>
        <h2 className="mt-4 plus">Ajouter un participant</h2>
      </div>
      <div className="pt-5">
        <ParticipantAddDataTable resource={ResourceDataProviderEnum.participantWebinar} />
      </div>
      <div className="d-flex justify-content-end my-5">
        <button type="button" className="btn btn-primary" onClick={() => goTo(RouteEnum.ADMIN_WEBINAR_SHOW, { id: idEntity })}>
          Terminer
        </button>
      </div>
    </BaseContainer>
  );
}
