import React, { useEffect, useState } from 'react';

import { useMenu } from 'leeloo-react/component/hook/useMenu';
import { GroupRouteType } from 'leeloo-react/model/GroupRouteType';
import { RouteType } from 'leeloo-react/model/RouteType';

import { MenuItem } from './MenuItem';

type Props = {
    classNameUlGroup?: string,
    classNameLiGroup?: string,
    classNameUl?: string,
    classNameLi?: string,
    classNameLink?: string,
    prependRoute?: any,
    appendRoute?: any,
    /** Setter un menu customisé directement, peut servir pour la gestion de multi menu, ne pas prendre en compte donc le paramètre menuConfig qui est plus général */
    customMenu?: GroupRouteType[],
    setActiveIndex?: any,
    activeIndex?: number|null,
}

export type MenuType = {
    group: GroupRouteType,
    routes: RouteType[],
    icon: string|undefined
};

/**
 * Génère un rendu d'un menu
 */
export function MenuLink(props: Props) {
  const { loadMenu } = useMenu();
  const [routesMenu, setRoutesMenu] = useState<MenuType[]>([]);

  useEffect(() => {
    // construction des routes disponible
    setRoutesMenu(loadMenu(props.customMenu));
  }, [props]);

  return (
    <ul className={props.classNameUlGroup}>
      {props.prependRoute}
      {/* eslint-disable-next-line react/no-array-index-key */}
      {routesMenu.map((m, index) => <MenuItem key={index} {...m} {...props} isActive={props.activeIndex === index} onShow={() => props.setActiveIndex(index)} onHide={() => props.setActiveIndex(null)} />)}
      {props.appendRoute}
    </ul>
  );
}

MenuLink.defaultProps = {
  classNameUlGroup: null,
  classNameLiGroup: null,
  classNameUl: null,
  classNameLi: null,
  classNameLink: null,
  prependRoute: null,
  appendRoute: null,
  customMenu: [],
  setActiveIndex: null,
  activeIndex: null,
};
