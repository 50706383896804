import React, { ReactElement, useState } from 'react';

import { SimpleColumn } from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { CustomFilterDataProviderType } from '../../../../model/dataProvider/CustomFilterDataProviderType';
import { DataTableBulksActions } from '../../../dataTable/DataTableBulksActions';
import { DataTableHeaderActions } from '../../../dataTable/DataTableHeaderActions';
import { DataTableListActions } from '../../../dataTable/DataTableListActions';

export function TrainingPlaceDataTable(): ReactElement {
  const [filters, setFilters] = useState<CustomFilterDataProviderType>({});
  const dsTrainingPlace = useDataSource({
    resource: ResourceDataProviderEnum.trainingPlace,
    params: {
      pagination: { page: 1, take: 50 },
    },
  });
  const exportNameFile = 'lieux_formation.csv';

  return (
    <DataTable
      dataSource={dsTrainingPlace}
      filter={(
        <Filter>
          <InputField name="search" label="Rechercher" onChangeValue={(v) => setFilters({ search: v })} />
        </Filter>
      )}
      actionHeader={(
        <DataTableHeaderActions
          addRoute={RouteEnum.ADMIN_TRAINING_PLACE_ADD}
          addRouteValue="Ajouter un lieu de formation"
          importResource={ResourceDataProviderEnum.trainingPlaceImport}
          exportResource={ResourceDataProviderEnum.trainingPlaceExport}
          exportNameFile={exportNameFile}
          exportFilters={filters}
          dataSource={dsTrainingPlace}
        />
      )}
      actionRow={(row) => [
        <DataTableListActions
          key={0}
          row={row}
          dataSource={dsTrainingPlace}
          readRoute={RouteEnum.ADMIN_TRAINING_PLACE_SHOW}
          deleteResource={ResourceDataProviderEnum.trainingPlace}
          duplicateResource={ResourceDataProviderEnum.trainingPlaceDuplicate}
          editRoute={RouteEnum.ADMIN_TRAINING_PLACE_EDIT}
        />,
      ]}
      onChangeCheckedRow={(rows: {id: number|string}[], cleanSelection) => (
        <DataTableBulksActions
          rows={rows}
          dataSource={dsTrainingPlace}
          deleteResource={ResourceDataProviderEnum.trainingPlace}
          duplicateResource={ResourceDataProviderEnum.trainingPlaceDuplicate}
          exportResource={ResourceDataProviderEnum.trainingPlaceExport}
          exportNameFile={exportNameFile}
          cleanSelection={cleanSelection}
        />
      )}
    >
      <SimpleColumn field="name" title="Nom" sortable />
      <SimpleColumn field="town" title="Ville" sortable />
      <SimpleColumn field="zip" title="Code Postal" sortable />
      <SimpleColumn field="address" title="Adresse" sortable />
      <SimpleColumn field="capacity" title="Capacité" sortable />
      <SimpleColumn field="rating" title="Note" sortable />
    </DataTable>
  );
}
