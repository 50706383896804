import React, { ReactElement } from 'react';

import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../enum/RouteEnum';
import { ThematicAbstractPage } from './ThematicAbstractPage';

export function ThematicStartedPage(): ReactElement {

  return (
    <ThematicAbstractPage
      resource={ResourceDataProviderEnum.eLearningStarted}
      parent={{title: 'Formations e-learning', route : RouteEnum.E_LEARNING}}
      title ='Formations en cours'
      flip
    ></ThematicAbstractPage>
  );
}
