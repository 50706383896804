import { object } from 'yup';

import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { Link, useParams } from 'react-router-dom';

import Hn from '../../../../../components/common/Hn';
import { BaseContainer } from '../../../../../components/layout/BaseContainer';
import { RouteEnum } from '../../../../../enum/RouteEnum';

import './createFaceTraining.css';

function CreateFaceSession2() {
  const params = useParams();
  const [skills, setSkills] = useState([]);
  const { goTo } = useRouter();
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };

  const uri = process.env.REACT_APP_BASE_URL;
  const validationSchema = object().shape({
  });
    // récupérer les compétences
  function getSkills() {
    axios
      .get(`${uri}/skills`)
      .then((result) => {
        setSkills(result?.data?.data);
      })
      .catch((err) => {
        console.log('err skills', err);
      });
  }

  useEffect(() => {
    getSkills();
  }, []);

  // modifier une compétence
  const handleSubmit = (values) => {
    axios
      .put(`${uri}/training/edit/skills/${params.id}`, values)
      .then(() => {
        goTo(RouteEnum.ADMIN_ON_SITE_SHOW, { id: params.id });
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  return (
    <BaseContainer>
      <div className="lists">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={RouteEnum.ADMIN_SESSION}>
                Formations présentielles - session de formation
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Créer une action de formation
            </li>
          </ol>
        </nav>
        <div className="h-100 p-5 text-white bg-dark rounded-3">
          <Hn
            title="Créer une action de formation"
            level="h4"
            className="h4-size"
            plus={false}
          />
          <Hn
            title="Etape 3: Compétences associés"
            level="h5"
            className="h4-size"
            plus={false}
          />
          <div className="form-elements mt-2">
            <div className="mt-30">
              <Formik
                initialValues={{
                  skill: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  /* and other goodies */
                }) => (
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-6">
                      <label className="form-label">
                        Référentiel de compétences
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        id="skill"
                        value={values.skill}
                        onChange={handleChange('skill')}
                        onBlur={handleBlur('skill')}
                        errors={errors.skill}
                      >
                        <option defaultValue>Sélectionner un référentiel</option>
                        {skills.map((skill, i) => (
                          <option value={skill.id} key={i}>
                            {skill?.title}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage name="skill">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12 mt-5">
                      <RouterLink routeName={RouteEnum.ADMIN_SESSION} className="btn btn-link text-decoration-none resetBtn">
                        Annuler
                      </RouterLink>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                      >
                        Suivant
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>

          </div>
        </div>
      </div>
    </BaseContainer>
  );
}

export default CreateFaceSession2;
