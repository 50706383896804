import {
  ArcElement, Chart as ChartJS, Legend, Tooltip,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import './styles.css';

export default function Chart2({ title, invalidated, validated }) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const options = {
    cutoutPercentage: 100,
    borderWidth: 0,
    cutout: 35
    //width: 100,
    //height: 100
  };

  const data = {
    datasets: [
      {
        data: [validated, invalidated],
        backgroundColor: ['rgb(80, 170, 80)', 'rgba(80, 170, 80, 0.1)'],
        labels: ['On track', 'Remaining'],
        labelSuffix: '%',
        pointStyle: 'circle',
      },
    ],
  };

  return (
    <div className="chartContainer">
      <Doughnut data={data} options={options} />
      <div className="chartInner">
        <div className="chartStatus">{title}</div>
      </div>
    </div>
  );
}
