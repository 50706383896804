import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';

import './popup.css';
import 'react-toastify/dist/ReactToastify.css';
import Hn from '../common/Hn';
import PopupDemandeEnregistree from "./PopupDemandeEnregistree";
import {useDataProvider} from "leeloo-react/component/hook/useDataProvider";
import {ResourceDataProviderEnum} from "../../enum/ResourceDataProviderEnum";
import {useToast} from "leeloo-react/component/hook/useToast";
import { ToastContainer } from 'react-toastify';

export default function PopupDemande({
    data,
    handleModalShow,
    handleModalClose,
    uri,
}) {
    let toastId = null;
    const [recap, setRecap] = useState(false);
    const dpCollaborators = useDataProvider();
    const dpRegions = useDataProvider();
    const [inscrits, setInscrits] = useState([]);
    const [collaborators, setCollaborators] = useState(null);
    const [selectedCollaborateur, setSelectedCollaborateur] = useState(null);
    const [inscriptionCollaborateur, setInscriptionCollaborateur] = useState([]);
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [inscriptionRegion, setInscriptionRegion] = useState([]);
    const { authData } = useAuthProvider();
    const toast = useToast();

    const customStyles = {
        control: (base, state) => ({
            ...base,
            fontSize: '16px',
            fontWeight: state.isSelected ? '600' : '400',
            backgroundColor: '#3A4048',
            color: 'white',
            border: 'none',
            borderRadius: 'none',
        }),
        dropdownIndicator: (base) => ({
            color: '#006FFF',
            marginRight: '10px',
        }),
        menu: (base) => ({
            backgroundColor: '#212730',
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#1E2E45' : '#212730',
            fontWeight: state.isSelected ? '600' : '400',
            borderBottom: '1px solid #3A4048',
            color: 'white',
            margin: '5px 14px 5px 10px',
        }),
        singleValue: (base, state) => ({
            ...base,
            color: 'white',
            backgroundColor: '#1E2E45',
            fontWeight: state.isSelected ? '600' : '400',
            margin: '6px 10px 5px 10px',
            padding: '5px 14px 5px 10px',
        }),
        multiValue: (base, state) => ({
            ...base,
            color: 'white',
            backgroundColor: '#1E2E45',
            fontWeight: state.isSelected ? '600' : '400',
            margin: '6px 10px 5px 10px',
            padding: '5px 14px 5px 10px',
        }),
        multiValueLabel: (base) => ({
            color: 'white',
        }),
        multiValueRemove: (base) => ({
            color: '#006FFF',
            fontSize: '16px',
            height: '20px',
            width: '20px',
        }),
        input: (base, state) => ({
            ...base,
            color: 'white',
        }),
    };

    // Récupération de la liste des collaborateurs lié à l'utilisateur
    function getCollaborators() {
        const resourceId = authData.id;
        dpCollaborators.getList(ResourceDataProviderEnum.participantTrainingRequest, { id: resourceId })
            .then((response) => {
                setCollaborators(response.data);
            });
    }

    useEffect(() => {
        getCollaborators();
    }, []);

    // Récupération de la liste des régions de France
    function getRegions() {
        dpRegions.getList(ResourceDataProviderEnum.regionsList, {})
            .then(async (response) => {
                setRegions(response.data);
            });
    }

    useEffect(() => {
        getRegions();
    }, []);

    const handleSubmit = () => {
        const opCollaborateur = [];
        selectedCollaborateur.map((opc) => {
            opCollaborateur.push(opc.value);
        });

        const opRegion = [];
        selectedRegion.map((opr) => {
            opRegion.push(opr.value);
        });

        const dataSend = {
            formation: data.id,
            participants: opCollaborateur,
            regions: opRegion,
        };

        axios
            .post(`${uri}/besoins-formation/inscription`, dataSend)
            .then((result) => {
                if (result && result.data && result.data.participant_already_registered_in_another_request) {
                    setInscrits(result.data.participant_already_registered_in_another_request);
                } else {
                    setInscrits([]);
                }
                setRecap(true);
                setInscriptionCollaborateur(collaborators.filter((el) => result.data.participants.includes(el.id)));
                setInscriptionRegion(regions.filter((el) => result.data.regions.includes(el.id)));
            })
            .catch(() => {
                toast('Une erreur s\'est produite', 'error');
            });
    };

    const handleClose = () => {
        resetModal();
        handleModalClose();
    };

    const resetModal = () => {
        setRecap(false);
        setInscrits([]);
        setSelectedCollaborateur(null);
        setSelectedRegion(null);
        setInscriptionCollaborateur([]);
        setInscriptionRegion([]);
    };

    return (
        <Modal
            show={handleModalShow}
            onHide={handleClose}
            size="lg"
            className="special-width-popup popup"
            centered
            contentClassName="bg-black"
        >
            <Modal.Header closeButton>
                <Modal.Title className="p-3 nopad">
                    {!recap
                        ? (
                            <>
                                <Hn title={data.title} level="h3" plus={false} />
                                {' '}
                                {data.cat}
                            </>
                        )
                        : (
                            inscriptionCollaborateur.length > 0
                                ? (<h3>Félicitations !</h3>)
                                : (inscrits.length > 0 && (<h3>Demande déjà en cours de traitement</h3>))
                        )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ToastContainer />
                <div className="row px-3 nopad ">
                    {!recap
                        ? (
                            <>
                                <div className="col-md">
                                    <Hn title="Participants" level="h5" plus={false} className="label-popup" />
                                    <Select
                                        styles={customStyles}
                                        onChange={setSelectedCollaborateur}
                                        className="themeSelect"
                                        options={collaborators ? collaborators.map((collaborator) => ({
                                            value: collaborator.id,
                                            label: collaborator.fullname,
                                        })) : {}}
                                        isMulti
                                        placeholder="Sélectionner un ou des participant(s)"
                                        noOptionsMessage={() => 'Aucun résultat'}
                                    />
                                </div>
                                <div className="col-md">
                                    <Hn
                                        title="Régions"
                                        level="h5"
                                        plus={false}
                                        className="label-popup"
                                    />
                                    <Select
                                        styles={customStyles}
                                        onChange={setSelectedRegion}
                                        className="themeSelect"
                                        options={regions ? regions.map((region) => ({
                                            value: region.id,
                                            label: region.name,
                                        })) : {}}
                                        isMulti
                                        placeholder="Sélectionner une ou plusieurs régions"
                                        noOptionsMessage={() => 'Aucun résultat'}
                                    />
                                </div>
                            </>
                        )
                        : (
                            <PopupDemandeEnregistree
                                collaborators={inscriptionCollaborateur}
                                regions={inscriptionRegion}
                                trainingTitle={data.title}
                                sessionLabel={selectedRegion.label}
                                dejaInscrits={inscrits}
                            />
                        )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {!recap
                    ? (
                        <Button
                            variant="primary"
                            onClick={handleSubmit}
                        >
                            Valider
                        </Button>
                    )
                    : (
                        <Button
                            variant="primary"
                            onClick={handleClose}
                        >
                            Fermer
                        </Button>
                    )}
            </Modal.Footer>
        </Modal>
    );
}
