import React, { ReactElement } from 'react';

type RatingModalFooterProps = {
    disabled: boolean,
    handleConfirm:() => void,
}

/**
 * Bouton de confirmation pour customiser la modale de notation de formation
 */
export function RatingModalFooter(props: RatingModalFooterProps): ReactElement {
    return (
        <button type="button" className="btn btn-primary" disabled={props.disabled} onClick={props.handleConfirm}>
            Confirmer
        </button>
    );
}
