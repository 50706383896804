import { useDataProvider } from 'leeloo-react/component/hook/useDataProvider';
import { DataSourceType } from 'leeloo-react/component/hook/useDataSource';
import { useModal } from 'leeloo-react/component/hook/useModal';
import { useToast } from 'leeloo-react/component/hook/useToast';

type Props<T> = {
    row: T & {id: any},
    resource: string,
    dataSource?: DataSourceType,
    /** Action a effectuer lors du clic sur l'élement, la ligne courante ainsi que la valeur est setté */
    onClick?: (row: T & {id: any}) => void,
    data?: { },
}

export function useDtAction() {
  const dp = useDataProvider();
  const toast = useToast();
  const { confirm } = useModal();

  const deleteRow = async (props: Props<any>) => {
    const response = await confirm('Confirmez-vous la suppression de cet élément ?');
    if (response) {
      if (props.onClick) {
        props.onClick(props.row);
      }
            dp.delete!(props.resource, { id: props.row.id }).then(() => {
              if (props.dataSource) {
                props.dataSource.refreshData();
              }
              toast('Suppression effectuée avec succès', 'success');
            });
    }
  };

  const duplicateRow = async (props: Props<any>) => {
    const response = await confirm('Confirmez-vous la duplication de cet élément ?');
    if (response) {
      if (props.onClick) {
        props.onClick(props.row);
      }
            dp.create!(props.resource, { id: props.row.id }).then(() => {
              if (props.dataSource) {
                props.dataSource.refreshData();
              }

              toast('Duplication effectuée avec succès', 'success');
            });
    }
  };

  const updateStatus = (props: Props<any>) => {
        dp.update!(props.resource, { id: props.row.id, data: props.data });
  };

  const validateStatus = async (props: Props<any>) => {
    const response = await confirm('Confirmez-vous la validation de cet élément ?');
    if (response) {
      if ((props.row.canValidate)
        && props.row.previousStatus !== 'Validée') {
        await dp.update!(props.resource, {
          id: props.row.id,
        });

        if (props.dataSource) {
          props.dataSource.refreshData();
        }

        toast('Validation effectuée avec succès', 'success');
      } else {
        toast('Validation impossible pour cet élément', 'error');
      }
    }
  };

  const archiveStatus = async (props: Props<any>) => {
    const response = await confirm('Confirmez-vous l\'archivage de cet élément ?');
    if (response) {
      if (props.row.canArchive) {
        await dp.update!(props.resource, {
          id: props.row.id,
        });

        if (props.dataSource) {
          props.dataSource.refreshData();
        }

        toast('Archivage effectué avec succès', 'success');
      } else {
        toast('Archivage impossible pour cet élément', 'error');
      }
    }
  };

  const unarchiveStatus = async (props: Props<any>) => {
    const response = await confirm('Confirmez-vous le désarchivage de cet élément ?');
    if (response) {
      if (props.row.canUnarchive) {
        await dp.update!(props.resource, {
          id: props.row.id,
        });

        if (props.dataSource) {
          props.dataSource.refreshData();
        }

        toast('Désarchivage effectué avec succès', 'success');
      } else {
        toast('Désarchivage impossible pour cet élément', 'error');
      }
    }
  };

  return {
    deleteRow,
    duplicateRow,
    updateStatus,
    validateStatus,
    archiveStatus,
    unarchiveStatus,
  };
}
