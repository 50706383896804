// eslint-disable-next-line import/no-extraneous-dependencies
import axios, { AxiosStatic } from 'axios';
import { JwtAuthProvider as LeelooJwtAuthProvider } from 'leeloo-react/authProvider/JwtAuthProvider';
import { LeelooConfig } from 'leeloo-react/Leeloo';
import { LoginType } from 'leeloo-react/model/authProvider/LoginType';
import { AuthReducerType } from 'leeloo-react/model/store/reducer/AuthReducerType';
/**
 * Auth provider basique reposant sur l'usage du token JWT sur api-platform
 * Compatible avec toute plateforme api-platform acceptant le JWT token
 * avec des routes api POST cohérente
 */
export const JwtAuthProvider: any = (apiUrl: string) => ({

  ...LeelooJwtAuthProvider(apiUrl),

  /**
         * Gère l'authentification a l'API + gestion du token ou autre
         * @return Une donnée pour indiquer si la connection a réussie, renvoi null si échoue
         * La donnée sera stocké dans le reducer "auth" de Leeloo pour un usage futur
         */
  async login(axiosProp: AxiosStatic, loginData: LoginType): Promise<AuthReducerType|null> {
    let url = '';
    let params = {};

    if (loginData.autoLoginData) {
      // mode auto login detecté
      url = `${apiUrl}${(LeelooConfig.autoConnect?.resourceApiLogin) ? LeelooConfig.autoConnect.resourceApiLogin : '/users/externalLogin'}`;
      params = { externalId: loginData.autoLoginData };
    } else if (loginData.username && loginData.password) {
      // mode auth form
      url = `${apiUrl}/login`;
      params = { username: loginData.username, password: loginData.password };
    } else {
      // aucun des deux detecté, le login a probablement échoué
      // eslint-disable-next-line no-throw-literal
      throw 'message.loginFail';
    }

    // tentative de connexion
    try {
      const r = await axiosProp.post(url, params);
      if (r.status === 200) {
        return r.data;
      }
      // eslint-disable-next-line no-throw-literal
      throw 'message.loginFail';
    } catch (e) {
      // eslint-disable-next-line no-throw-literal
      throw 'message.loginFail';
    }
  },

  async logoutBeforeRedirect() {
    // On supprime le vieux token
    axios.defaults.headers.common.Authorization = null;
  },
});
