import React, { ReactElement, useState } from 'react';

import { SimpleColumn } from 'leeloo-react/component/dataTable/column/SimpleColumn';
import { DataTable } from 'leeloo-react/component/dataTable/DataTable';
import { Filter } from 'leeloo-react/component/filter/Filter';
import { InputField } from 'leeloo-react/component/form/field/InputField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { CustomFilterDataProviderType } from '../../../../model/dataProvider/CustomFilterDataProviderType';
import { DataTableBulksActions } from '../../../dataTable/DataTableBulksActions';
import { DataTableHeaderActions } from '../../../dataTable/DataTableHeaderActions';
import { DataTableListActions } from '../../../dataTable/DataTableListActions';

export function ProviderDataTable(): ReactElement {
  const [filters, setFilters] = useState<CustomFilterDataProviderType>({});
  const dsProviders = useDataSource({
    resource: ResourceDataProviderEnum.provider,
    params: {
      pagination: { page: 1, take: 50 },
    },
  });
  const exportNameFile = 'prestataires.csv';

  return (
    <DataTable
      dataSource={dsProviders}
      filter={(
        <Filter>
          <InputField name="search" label="Rechercher" onChangeValue={(v) => setFilters({ search: v })} />
        </Filter>
      )}
      actionHeader={(
        <DataTableHeaderActions
          addRoute={RouteEnum.ADMIN_PROVIDER_ADD}
          addRouteValue="Ajouter un prestataire"
          importResource={ResourceDataProviderEnum.providerImport}
          exportResource={ResourceDataProviderEnum.providerExport}
          exportNameFile={exportNameFile}
          exportFilters={filters}
          dataSource={dsProviders}
        />
      )}
      actionRow={(row) => [
        <DataTableListActions
          key={0}
          row={row}
          dataSource={dsProviders}
          readRoute={RouteEnum.ADMIN_PROVIDER_SHOW}
          deleteResource={ResourceDataProviderEnum.provider}
          editRoute={RouteEnum.ADMIN_PROVIDER_EDIT}
        />,
      ]}
      onChangeCheckedRow={(rows: {id: number|string}[], cleanSelection) => (
        <DataTableBulksActions
          rows={rows}
          dataSource={dsProviders}
          deleteResource={ResourceDataProviderEnum.provider}
          exportResource={ResourceDataProviderEnum.providerExport}
          exportNameFile={exportNameFile}
          cleanSelection={cleanSelection}
        />
      )}
    >
      <SimpleColumn field="name" title="Nom" sortable />
      <SimpleColumn field="country" title="Pays" sortable />
      <SimpleColumn field="contact_name" title="Nom Contact" sortable />
      <SimpleColumn field="contact_surname" title="Prénom Contact" sortable />
      <SimpleColumn field="tel" title="Téléphone" sortable />
      <SimpleColumn field="email" title="Email" sortable />
    </DataTable>
  );
}
