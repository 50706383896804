import { ThematicDto } from '../../model/dto/ThematicDto';
import React, { ReactElement } from 'react';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { ELearningDto } from '../../model/dto/ELearningDto';
import {
  ResourceDataProviderEnum
} from '../../enum/ResourceDataProviderEnum';
import { DropdownItem } from './DropdownItem';
import { OnSiteDto } from '../../model/dto/OnSiteDto';

export function ThematicDropdownItem(props: {resource? : string,statusField:string,thematic:ThematicDto}): ReactElement {

// on va récupérer la liste de mes thématiques pour une catégorie donnée
  const dsFormations = useDataSource<ELearningDto | OnSiteDto>({
    resource: props.resource ? props.resource : ResourceDataProviderEnum.eLearning,
    params: {
      filter: {
        thematic: props.thematic.id,
        [`${props.statusField}`]: true,
        light: true
      },
      pagination: { page: 1, take: 12 },
    },
  });

  return (
    <>
    {dsFormations.total > 0 && (
      <>
        <strong>{props.thematic.name}</strong>
        <ul>
          {dsFormations.dataList.map((formation : ELearningDto | OnSiteDto) => (
            <li>
              <DropdownItem formation={formation}></DropdownItem>
            </li>
          ))}
        </ul>
      </>
    )}
    </>
  );

}
