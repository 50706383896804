import * as Yup from 'yup';

import React from 'react';

import { Form } from 'leeloo-react/component/form/Form';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { useToast } from 'leeloo-react/component/hook/useToast';
import { Breadcrumb } from 'react-bootstrap';

import { CategoryForm } from './CategoryForm';
import { ResourceDataProviderEnum } from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { BaseContainer } from '../../../layout/BaseContainer';

export function CategoryEditAddPage() {
  const { currentParamsRoute, goTo } = useRouter();
  const toast = useToast();
  const { goBack } = useRouter();

  return (
    <BaseContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          Thématiques
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => goTo(RouteEnum.ADMIN_CATEGORY)}>
          Catégories
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {`${currentParamsRoute?.id == null ? 'Ajouter' : 'Modifier'} une catégorie`}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="h-100 p-5 text-white bg-dark rounded-3">
        <h4 className="pb-5">
          {`${currentParamsRoute?.id == null ? 'Ajouter' : 'Modifier'} une catégorie`}
        </h4>
        <Form
          id={currentParamsRoute?.id}
          resource={ResourceDataProviderEnum.category}
          onSubmit={async (d: any) => {
            d.categoryOrder = parseInt(d.categoryOrder, 10);
          }}
          initialValue={{}}
          validationSchema={{
            name: Yup.string().required('Champ obligatoire.'),
            type: Yup.number().required('Champ obligatoire.'),
            categoryOrder: Yup.number().typeError('Veuillez choisir un ordre').required('Champ obligatoire.'),
          }}
          onSubmitEndUpdate={() => {
            toast('message.updateSuccess', 'success');
            goBack();
          }}
        >
          <CategoryForm />
        </Form>
      </div>
    </BaseContainer>
  );
}
