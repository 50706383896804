import React, { ReactElement, useEffect, useState } from 'react';

import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { useRouter } from 'leeloo-react/component/hook/useRouter';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { Breadcrumb } from 'react-bootstrap';

import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../enum/RouteEnum';
import { ThematicDto } from '../../../model/dto/ThematicDto';
import { BaseContainer } from '../../layout/BaseContainer';
import { ThematicList } from '../../thematic/ThematicList';

export function ThematicPage(): ReactElement {
  const [isELearning, setIsELearning] = useState(false);
  const { currentParamsRoute } = useRouter();

  // On va récupérer la thématique en cours
  const dsThematic = useDataSource<ThematicDto>({
    resource: ResourceDataProviderEnum.thematic,
    params: { id: currentParamsRoute.id },
    singleResult: true,
  });

  useEffect(() => {
    if (currentParamsRoute.category) {
      setIsELearning(currentParamsRoute.category === 'elearning');
    }
  }, [currentParamsRoute]);

  return (
    <BaseContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <RouterLink routeName={isELearning ? RouteEnum.E_LEARNING : RouteEnum.PRESENTIEL}>
            {isELearning ? 'Formations e-learning' : 'Formations présentielles'}
          </RouterLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {dsThematic.oneData?.name}
        </Breadcrumb.Item>
      </Breadcrumb>
      <h2 className="plus mb-4">
        {dsThematic.oneData?.name}
      </h2>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>
        {dsThematic.oneData != null && (
          <ThematicList
            thematic={dsThematic.oneData}
            resource={isELearning ? ResourceDataProviderEnum.eLearning : ResourceDataProviderEnum.training}
            eLearning={isELearning}
            statusField={isELearning ? 'actif' : 'status'}
          />
        )}
      </>
    </BaseContainer>
  );
}
