export const formatStringToSeo = (string: string) => {
  // make the url lowercase
  let encodedString = string.toString().toLowerCase();

  // replace accented characters
  encodedString = encodedString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // replace & with and
  encodedString = encodedString.split(/&+/).join('-and-');

  // remove invalid characters
  encodedString = encodedString.split(/[^a-z0-9]/).join('-');

  // remove duplicates
  encodedString = encodedString.split(/-+/).join('-');

  return encodedString;
};
