import React from 'react';

import PopupDejaInscrit from './PopupDejaInscrit';
import { CollaboratorDto } from '../../model/dto/CollaboratorDto';
import { InscritDto } from '../../model/dto/InscritDto';

type Props = {
    collaborators: CollaboratorDto[],
    trainingTitle: string,
    sessionLabel: string,
    dejaInscrits: InscritDto [],
    enableConcurrentRegistration: boolean
}

export default function PopupInscrit(props : Props) {
    return (
        <>
            {
                // Les collaborateurs inscrits avec succes
                props.collaborators.length > 0
                && (
                    <>
                        <div className="col-md-7">
                            <h5>
                                Nous vous confirmons l&apos;inscription de :
                            </h5>
                            {props.collaborators.map((inscrip) => (
                                <p className="text-white textBox" key={inscrip.id}>
                                    {inscrip.fullname}
                                </p>
                            ))}
                        </div>
                        <div className="col-md-5">
                            <h5>
                                Formation :
                            </h5>
                            <p className="text-white textBox">
                                {props.trainingTitle}
                            </p>
                        </div>
                        <div className="col-md-12">
                            <h5>
                                Session(s) :
                            </h5>
                            <p className="text-white textBox">
                                {props.sessionLabel}
                            </p>
                        </div>
                    </>
                )
            }
            {
                // Les collaborateurs n'ayant pas pu être inscrits
                props.dejaInscrits.length > 0
                && (
                    <>
                        {props.collaborators.length > 0 && (
                            <hr />
                        )}
                        {props.collaborators.length > 0 &&
                         props.enableConcurrentRegistration &&
                          (<h4>Attention, conflits possibles !</h4>)
                        }
                        <PopupDejaInscrit
                            trainingTitle={props.trainingTitle}
                            inscrits={props.dejaInscrits}
                        />
                    </>
                )
            }
        </>
    );
}
