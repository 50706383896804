import React from 'react';

import Table from 'react-bootstrap/Table';

import { InscritDto } from '../../model/dto/InscritDto';

type Props = {
    trainingTitle : string,
    inscrits: InscritDto [],
}

export default function PopupDejaInscrit(props : Props) {
    /**
     * Le titre de la session est non renseigné si le participant est déjà
     *   inscrit à la session courante
     * @param inscrit
     */
    const getTrainingTitle = (inscrit : InscritDto) : string => inscrit.session.title ?? props.trainingTitle;

    return (
        <div className="col-12">
            <h5>
                Déjà inscrits à une session de formation :
            </h5>
            <Table striped hover>
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Formation</th>
                        <th>Dates</th>
                    </tr>
                </thead>
                <tbody>
                    {props.inscrits.map((inscrit) => {
                        const startDate = (new Date(inscrit.session.dateStart))?.toLocaleDateString('fr', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        });
                        const endDate = (new Date(inscrit.session.dateEnd))?.toLocaleDateString('fr', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        });
                        return (
                            <tr className="text-white textBox" key={inscrit.ContactID}>
                                <td>
                                    {inscrit.contactNom}
                                </td>
                                <td>
                                    {inscrit.contactPrenom}
                                </td>
                                <td>
                                    {getTrainingTitle(inscrit)}
                                </td>
                                <td>
                                    {`du ${startDate} au ${endDate}`}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
}
