import React, { ReactElement } from 'react';

import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { ELearningDto } from '../../../model/dto/ELearningDto';
import Banner from '../../banner/Banner';

export function ELearningBanner(): ReactElement {
  const dsHighlight = useDataSource<ELearningDto>({
    resource: ResourceDataProviderEnum.eLearning,
    params: {
      filter: { mis_en_avant: true, actif: true },
    },
  });

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dsHighlight.total > 0 && (
        <div className="pb-3">
          <Banner
            data={dsHighlight.dataList}
            url="detailformations"
            from="elearning"
          />
        </div>
      )}
    </>
  );
}
