import React, { useEffect, useState } from 'react';

import { SelectField } from 'leeloo-react/component/form/field/SelectField';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import defaultProfileImage from '../../../assets/profil.png';
import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import RoleUserEnum from '../../../enum/RoleUserEnum';
import { UserDto } from '../../../model/dto/UserDto';

type Props = {
    user?:UserDto|null,
    handleUserChange: (newValue: string) => void,
}

type StatsType = {
    minScore: number,
    maxScore: number,
    averageScore: number,
    totalTime: string,
}

const canListUsers = [
  RoleUserEnum.ROLE_SUPER_ADMIN,
  RoleUserEnum.ROLE_GESTIONNAIRE,
  RoleUserEnum.ROLE_ASSOCIE,
  RoleUserEnum.ROLE_AGENT_IMMOBILIER,
  RoleUserEnum.ROLE_RESPONSABLE_TRANSACTION,
];

export function ProfileBanner({ user, handleUserChange }: Props) {
  const { authData, checkAccess } = useAuthProvider()!;
  const [mainColor, setMainColor] = useState(authData.id === user?.id);

  const dsUserStatistics = useDataSource<StatsType>({
    resource: ResourceDataProviderEnum.participantStatistics,
    singleResult: true,
    noLoadAfterInit: true,
  });

  const dsCollaborators = useDataSource<any>({
    resource: ResourceDataProviderEnum.userCollaborators,
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (user?.id != null) {
      setMainColor(authData.id === user?.id);
      dsUserStatistics.setParams({ id: user?.id });
      dsCollaborators.setParams({ id: user?.id });
    }
  }, [user?.id]);

  const handlerChangeCurrentUserId = (newLoadState: string) => {
    handleUserChange(newLoadState);
  };

  return (
    <div className={`banner-profile mb-5 ${mainColor ? 'firstColor' : 'secondColor'}`}>

      <div className="banner-profile-head">
        <img src={defaultProfileImage} alt="profile" className="img-fluid" />
      </div>
      <div className="banner-profile-body">

        <div className="row align-items-center text-center text-md-start">
          <div className="col-lg-2 col-md-2 d-xl-none">
            <img src={defaultProfileImage} alt="profile" className="image-profile img-fluid mb-3 mb-md-0" />
          </div>
          <div className="col-xl-12 col-lg-10 col-md-10">
            <div className="row mb-lg-3">
              <div className="col-md-6 col-lg-8 mb-3 mb-md-0">
                <h5>{`${user?.contactNom} ${user?.contactPrenom}`}</h5>
                <h6>{user?.metier}</h6>
              </div>
              {checkAccess(canListUsers) && (
                <div className="col-md-6 col-lg-4">
                  {/* @TODO rajouter Mes informations ? */}
                  <SelectField
                    isClearable={false}
                    value={user?.id}
                    optionsList={[{ label: 'Mes informations', value: authData.id }, ...dsCollaborators.dataList.map((c) => ({ label: c.fullname, value: c.id })).filter((f) => f.value !== authData.id)]}
                    onChangeValue={(v: string) => {
                      handlerChangeCurrentUserId(v);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <hr className="my-4" />

        <div className="row cards-grid">
          <div className="col-6 col-md-3">
            <div className="card text-white bg-dark h-100">
              <div className={`card-body ${mainColor ? 'firstColorCadre' : 'secondColorCadre'}`}>
                <h5 className="card-title">Score moyen</h5>
                {dsUserStatistics.oneData?.averageScore != null ? Math.floor(dsUserStatistics.oneData.averageScore) : 'Aucune donnée disponible'}
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="card text-white bg-dark h-100">
              <div className={`card-body ${mainColor ? 'firstColorCadre' : 'secondColorCadre'}`}>
                <h5 className="card-title">Score maximum</h5>
                {dsUserStatistics.oneData?.maxScore != null ? Math.floor(dsUserStatistics.oneData.maxScore) : 'Aucune donnée disponible'}
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="card text-white bg-dark h-100">
              <div className={`card-body ${mainColor ? 'firstColorCadre' : 'secondColorCadre'}`}>
                <h5 className="card-title">Score minimum</h5>
                {dsUserStatistics.oneData?.minScore != null ? Math.floor(dsUserStatistics.oneData.minScore) : 'Aucune donnée disponible'}
              </div>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="card text-white bg-dark h-100">
              <div className={`card-body ${mainColor ? 'firstColorCadre' : 'secondColorCadre'}`}>
                <h5 className="card-title">Temps total</h5>
                {dsUserStatistics.oneData?.totalTime != null ? dsUserStatistics.oneData.totalTime : 'Aucune donnée disponible'}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

ProfileBanner.defaultProps = {
  user: null,
};
