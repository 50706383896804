export const getYoutubeIdHelper = (url?: string) => {
  if (!url) {
    return '';
  }
  if (url === '' || url.length <= 11) {
    return url;
  }
  const regex = /(https?:\/\/)?(((m|www)\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|shorts\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
  const match = url.match(regex);
  if (match) {
    return match[8];
  }
  return '';
};
