import React, { ReactElement } from 'react';

import { WebinarDataTable } from './WebinarDataTable';
import { BaseContainer } from '../../../layout/BaseContainer';

export function Webinar(): ReactElement {
  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Webinaires</h1>
      </div>
      <div className="pt-5">
        <WebinarDataTable />
      </div>
    </BaseContainer>
  );
}
