import React from 'react';

import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { useParams } from 'react-router-dom';

import { ParticipantAddDataTable } from './ParticipantAddDataTable';
import {
  ResourceDataProviderEnum,
} from '../../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../../enum/RouteEnum';
import { BaseContainer } from '../../../layout/BaseContainer';

export function ParticipantOnSiteManagePage() {
  const { idEntity } = useParams();

  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Formations présentielles</h1>
        <h2 className="mt-4 plus">Ajouter un participant</h2>
      </div>
      <div className="pt-5">
        <ParticipantAddDataTable resource={ResourceDataProviderEnum.participantSession} />
      </div>
      <div className=" mt-3 d-flex justify-content-end">
        <RouterLink routeName={RouteEnum.ADMIN_SESSION_SHOW} params={{ id: idEntity ?? '' }} className="btn btn-primary">
          Retour à la session
        </RouterLink>
      </div>
    </BaseContainer>
  );
}
