import React, { ReactElement } from 'react';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';

import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { RouteEnum } from '../../../enum/RouteEnum';
import { ELearningDto } from '../../../model/dto/ELearningDto';
import { FormationCard } from '../../formation/FormationCard';
import { useSplide } from '../../hook/useSplide';

export function ELearningStarted(): ReactElement {
  const { defaultConfig } = useSplide();

  // on va récupérer la liste de mes formations dans une thématique donnée
  const dsFormations = useDataSource<ELearningDto>({
    resource: ResourceDataProviderEnum.eLearningStarted,
  });

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dsFormations.total > 0 && (
        <section className="thematic-carousel-wrapper mb-5">
          <h3 className="plus mb-4">Mes formations e-learning en cours</h3>
          <div className="thematic-carousel with-title">
            <div className="title-wrapper">
              <div className="title">Reprendre les formations en cours</div>
              {dsFormations.total >= 6 && (
                <RouterLink routeName={RouteEnum.STARTED} className="d-none d-md-block">
                  Voir tout
                </RouterLink>
              )}
            </div>
            <Splide options={defaultConfig}>
              {dsFormations.dataList.map((formation) => (
                <SplideSlide key={formation.id}>
                  <FormationCard
                    formation={formation}
                  />
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </section>
      )}
    </>
  );
}
