import React, { ReactElement, useState } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { useWriteReducer } from 'leeloo-react/component/hook/useWriteRedux';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';
import { Nav } from 'react-bootstrap';

import { MenuLink } from './MenuLink';
import { ReactComponent as Gh } from '../../assets/icons/Gh.svg';
import RoleUserEnum from '../../enum/RoleUserEnum';
import { RouteEnum } from '../../enum/RouteEnum';
import { ReducerEnum } from '../../enum/store/ReducerEnum';
import { AuthReducerType } from '../../model/authProvider/AuthReducerType';
import { ConfigReducerType } from '../../model/store/reducers/ConfigReducerType';
import { LogoutAsLink } from '../auth/logout/LogoutAsLink';
import { LogoutLink } from '../auth/logout/LogoutLink';

const customMenu = [
  {
    label: 'Formations e-learning',
    icon: 'uil uil-desktop',
    routes: [
      RouteEnum.ADMIN_E_LEARNING,
      RouteEnum.ADMIN_E_LEARNING_LEARNER,
    ],
  },
  {
    label: 'Formations présentielles',
    icon: 'uil uil-book-alt',
    routes: [
      RouteEnum.ADMIN_TRAINING_REQUEST,
      RouteEnum.ADMIN_ON_SITE,
      RouteEnum.ADMIN_SESSION,
      RouteEnum.ADMIN_ON_SITE_PARTICIPANT,
      RouteEnum.ADMIN_TRAINER,
      RouteEnum.ADMIN_PROVIDER,
      RouteEnum.ADMIN_TRAINING_PLACE,
    ],
  },
  {
    label: 'Thématiques',
    icon: 'uil uil-th',
    routes: [
      RouteEnum.ADMIN_CATEGORY,
      RouteEnum.ADMIN_THEMATIC,
    ],
  },
  {
    label: 'Compétences',
    icon: 'uil uil-star',
    routes: [RouteEnum.ADMIN_SKILL],
  },
  {
    label: 'Webinaires',
    icon: 'uil uil-video',
    routes: [RouteEnum.ADMIN_WEBINAR],
  },
  {
    label: 'Utilisateurs',
    icon: 'uil uil-users-alt',
    routes: [RouteEnum.ADMIN_USERS],
  },
  {
    label: 'Paramètres',
    icon: 'uil uil-setting',
    routes: [RouteEnum.ADMIN_SETTINGS],
  },
];

export function AdminSidebar(): ReactElement {
  const { checkAccess, authData }: {checkAccess: (roles: string[]) => boolean, authData:AuthReducerType} = useAuthProvider()! as any;
  const [activeIndex, setActiveIndex] = useState<number|null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const dispatchConfig = useWriteReducer<ConfigReducerType>(ReducerEnum.config);

  return (
    <div className={`sidebar ${open ? 'opened' : ''}`} onMouseLeave={() => setActiveIndex(null)}>
      <RouterLink
        routeName={RouteEnum.ADMIN_DASHBOARD}
        className="nav_logo"
      >
        <Gh />
      </RouterLink>
      <div role="button" className="burger" tabIndex={0} onClick={() => setOpen(!open)} onKeyUp={() => setOpen(!open)}>
        <span />
        <span />
        <span />
      </div>
      <Nav defaultActiveKey={RouteEnum.ADMIN_DASHBOARD} className="main-nav">
        <MenuLink
          classNameLink="nav-link cursor-pointer"
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          prependRoute={(
            <li>
              <RouterLink icon="uil uil-estate" className="nav-link" routeName={RouteEnum.ADMIN_DASHBOARD}>
                <span>Dashboard</span>
              </RouterLink>
            </li>
          )}
          customMenu={customMenu}
        />
        <ul>
          {checkAccess([RoleUserEnum.ROLE_SUPER_ADMIN]) && (
            <li role="presentation" onClick={() => dispatchConfig.set('displayFront', true)}>
              <RouterLink icon="uil uil-user-arrows" className="nav-link" routeName={RouteEnum.E_LEARNING}>
                <span>Plateforme</span>
              </RouterLink>
            </li>
          )}
          <li>
            { authData.loginAs
              ? <LogoutAsLink />
              : (
                <LogoutLink className="nav-link logout cursor-pointer">
                  <Icon name="uil uil-lock" />
                  <span>Déconnexion</span>
                </LogoutLink>
              )}
          </li>
        </ul>
      </Nav>

    </div>
  );
}
