import React, { ReactElement } from 'react';

import { CategoryDataTable } from './CategoryDataTable';
import { BaseContainer } from '../../../layout/BaseContainer';

export function CategoryListPage(): ReactElement {
  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Catégories</h1>
      </div>
      <div className="pt-5">
        <CategoryDataTable />
      </div>
    </BaseContainer>
  );
}
