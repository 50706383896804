import { Collapse } from 'react-bootstrap';
import React, { ReactElement, useState } from 'react';
import { DataSourceType } from 'leeloo-react/component/hook/useDataSource';
import { ThematicDto } from '../../model/dto/ThematicDto';
import {
  ResourceDataProviderEnum
} from '../../enum/ResourceDataProviderEnum';
import { ELearningDto } from '../../model/dto/ELearningDto';
import { ThematicDropdownItem } from './ThematicDropdownItem';
import { DropdownItem } from './DropdownItem';
import { OnSiteDto } from '../../model/dto/OnSiteDto';

export function Dropdown(props: {
  href:string,
  label:string,
  ds: DataSourceType,
  resource?: string
}): ReactElement {

  const [catSubnavOpened, setCatSubnavOpened] = useState<boolean>(false);

  return (
    <li>
      <a
        href={props.href}
        className={(catSubnavOpened) ? 'opened' : ''}
        onClick={(e) => {e.preventDefault(); setCatSubnavOpened(!catSubnavOpened)}}>
        {props.label}
      </a>
      <Collapse in={catSubnavOpened}>
        <ul>
          {props.ds.dataList.map((item) => (
            <li>
              {
                props.ds.resource === ResourceDataProviderEnum.thematic
                ?
                <ThematicDropdownItem
                  resource={props.resource}
                  statusField="actif"
                  thematic={item as ThematicDto}
                ></ThematicDropdownItem>
                : <DropdownItem formation={item as ELearningDto | OnSiteDto}></DropdownItem>
              }
            </li>
          ))}
        </ul>
      </Collapse>
    </li>
  );

}
