import React, { useState } from 'react';

import { useDataProvider } from 'leeloo-react/component/hook/useDataProvider';

import { DataTableDefaultButton } from './DataTableDefaultButton';
import { DataTableButtonType } from '../../../model/dataTable/DataTableButtonType';

export function DataTableDuplicateButton<T>(props: DataTableButtonType<T>) {
  const [isLoad, setIsLoad] = useState(false);
  const dp = useDataProvider();

  const fetchPromise = () => dp.create!(props.resource, { id: props.row?.id });
  const fetchPromises = () => (props.rows as []).map((row: T & {id: any}) => dp.create!(props.resource, { id: row.id }));

  const handleLoad = (newLoadState: boolean) => {
    setIsLoad(newLoadState);
  };

  return (
    <DataTableDefaultButton
      {...props}
      isLoad={isLoad}
      onClick={() => setIsLoad(true)}
      label="Dupliquer"
      icon="layers"
      fetchPromise={fetchPromise}
      fetchPromises={fetchPromises}
      handleLoad={handleLoad}
      messageFetchOne="Confirmez-vous la duplication de cet élément ?"
      messageFetchMultiples="Confirmez-vous la duplication de ces éléments ?"
      messageSuccess="Duplication effectuée avec succès"
    />
  );
}

DataTableDuplicateButton.defaultProps = {
  variant: 'primary',
};
