import { useEffect, useState } from 'react';

import axios from 'axios';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Delete from '../../../../../assets/icons/delete.png';

import './createUnite.css';

function DeleteUniteDeFormation({ uniteToBeDeleted }) {
  const [showModal, setShowModal] = useState(false);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const uri = process.env.REACT_APP_BASE_URL;

  const deleteUnite = (unite) => {
    axios.post(`${uri}/unite/${unite?.id}`)
      .then((result) => {
        hideModal();
        toast.success('Cette unité de formation a bien été supprimée!');
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((err) => {
        hideModal();
        toast.error('Désolé vous ne pouvez pas supprimer cette unité de formation');
        console.log('err sessions', err);
      });
  };

  const showModalFunction = (unite) => {
    setShowModal(unite);
    console.log('setShowModal', showModal);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {

  }, [uniteToBeDeleted]);

  return (
    <>
      <span className="cursor-pointer" onClick={(e) => showModalFunction(uniteToBeDeleted)}><img src={Delete} className="pe-1" /></span>
      <Modal show={showModal} onHide={(e) => hideModal()} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            Etes-vous sûr(e) de vouloir supprimer "
            {uniteToBeDeleted?.titre}
            " ?
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={(e) => hideModal()} style={{ color: '#006FFF' }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-danger"
            onClick={(e) => deleteUnite(uniteToBeDeleted)}
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default DeleteUniteDeFormation;
