import React from 'react';

import { DataSourceType } from 'leeloo-react/component/hook/useDataSource';
import { RouterLink } from 'leeloo-react/component/router/RouterLink';

import { DataTableExportButton } from './button/DataTableExportButton';
import {
  CustomFilterDataProviderType,
} from '../../model/dataProvider/CustomFilterDataProviderType';
import { ImportFile } from '../form/ImportFile';

type Props = {
    addRoute?: string,
    addRouteParams?: {[key in string|number]: string|number},
    addRouteValue?: string,
    importResource?: string,
    exportResource?: string,
    exportNameFile?: string,
    exportFilters?: CustomFilterDataProviderType,
    dataSource?: DataSourceType,
}

export function DataTableHeaderActions(props: Props) {
  return (
    <>
      {props.importResource && (
        <ImportFile resource={props.importResource} dataSource={props.dataSource} />
      )}
      {props.exportResource && (
        <DataTableExportButton resource={props.exportResource} nameFile={props.exportNameFile ?? ''} exportFilters={props.exportFilters} variant="outline-primary" pictoOnly />
      )}
      {props.addRoute && (
        <RouterLink
          className="btn btn-primary"
          icon="add"
          routeName={props.addRoute}
          params={props.addRouteParams}
        >
          {props.addRouteValue}
        </RouterLink>
      )}
    </>
  );
}

DataTableHeaderActions.defaultProps = {
  addRoute: null,
  addRouteParams: null,
  addRouteValue: null,
  importResource: null,
  exportResource: null,
  exportNameFile: null,
  exportFilters: null,
  dataSource: null,
};
