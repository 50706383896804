import React from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useDataProvider } from 'leeloo-react/component/hook/useDataProvider';
import { DataSourceType } from 'leeloo-react/component/hook/useDataSource';
import { useToast } from 'leeloo-react/component/hook/useToast';
import Papa from 'papaparse';

type Props = {
    resource: string,
    dataSource?: DataSourceType,
}

/**
 * Composant pour importer un fichier CSV
 */
export function ImportFile(props: Props) {
  const dp = useDataProvider();
  const toast = useToast();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      // @TODO, ne pas faire ca en react -_-'
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete(results: any) {
          results.data[0].ref = `${results.data[0].ref}x2`;

                    dp.create!(props.resource, { data: JSON.stringify(results.data) }).then(() => {
                      if (props.dataSource) {
                        props.dataSource.refreshData();
                      }

                      toast('L\'import effectué avec succès', 'success');
                    }).catch(() => {
                      toast('Une erreur est survenue lors de l\'import', 'error');
                    });
        },
        error() {
          toast('Une erreur est survenue lors de l\'import', 'error');
        },
      });
    }
  };

  return (
    <div className="square">
      <input
        type="file"
        name="file"
        id="import-file"
        accept=".csv"
        className="d-none"
        onChange={handleOnChange}
      />
      <label htmlFor="import-file" className="btn btn-outline-primary">
        <Icon name="uil uil-import" />
      </label>
    </div>

  );
}

ImportFile.defaultProps = {
  dataSource: 'primary',
};
