// eslint-disable-next-line import/no-extraneous-dependencies
import { Options } from '@splidejs/splide';

export function useSplide() {
  const defaultConfig:Options = {
    fixedWidth: '260px',
    padding: {
      right: '2rem',
    },
    perMove: 1,
    gap: '1.25rem',
    loop: false,
    rewind: false,
    pagination: false,
    breakpoints: {
      1200: {
        fixedWidth: '200px',
        gap: '1rem',
      },
      992: {
        fixedWidth: '140px',
        gap: '.75rem',
      },
    },
  };

  return {
    defaultConfig,
  };
}
