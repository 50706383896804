import React, { ReactElement, useEffect } from 'react';

import { ChartOptions } from 'chart.js';
import { Icon } from 'leeloo-react/component/display/Icon';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../../enum/ResourceDataProviderEnum';
import { KpiType } from '../../../../../model/kpis/KpiType';
import { LearnerStatsType } from '../../../../../model/kpis/LearnerStatsType';
import HorizontalBarchart from '../../../../chart/HorizontalBarchart';
import { RouterCustomFileLink } from '../../../../router/RouterCustomFileLink';

const learnersOptions:ChartOptions = {
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: '',
    },
  },
};

export function KpiLearner({ dateRange }: KpiType): ReactElement {
  const dsLearners = useDataSource<LearnerStatsType>({
    resource: ResourceDataProviderEnum.kpisLearners,
    singleResult: true,
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (dateRange) {
      const params = {
        uriParameters: {
          startAt: dateRange?.startAt || null,
          endAt: dateRange?.endAt || null,
        },
      };

      dsLearners.setParams(params);
    }
  }, [dateRange]);

  return (
    <div className="p-3 bg-dark-grey h-100">
      <div className="d-flex justify-content-between">
        <h4>Nombre de participants</h4>
        <RouterCustomFileLink
          resource={ResourceDataProviderEnum.kpisLearnersExport}
          params={{
            data: {
              startAt: dateRange?.startAt || null,
              endAt: dateRange?.endAt || null,
            },
          }}
          nameFile="dashboard_participant.csv"
          className="btn btn-outline-primary square"
        >
          <Icon name="uil uil-export" />
        </RouterCustomFileLink>
      </div>
      <HorizontalBarchart
        datasets={[
          {
            label: 'Participants',
            data: [dsLearners.oneData?.nbrParticipant ?? 0],
            backgroundColor: '#6B6DFF',
          },
          {
            label: 'Inscrits',
            data: [dsLearners.oneData?.nbrInscrit ?? 0],
            backgroundColor: '#703afe',
          },
        ]}
        options={learnersOptions}
      />
    </div>
  );
}
