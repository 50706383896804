import React, { ReactElement } from 'react';

import { ThematicDataTable } from './ThematicDataTable';
import { BaseContainer } from '../../../layout/BaseContainer';

export function ThematicListPage(): ReactElement {
  return (
    <BaseContainer>
      <div className="lists-title">
        <h1>Thématiques</h1>
      </div>
      <div className="pt-5">
        <ThematicDataTable />
      </div>
    </BaseContainer>
  );
}
