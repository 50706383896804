import React, { ReactElement } from 'react';

import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../enum/ResourceDataProviderEnum';
import { CategoryDto } from '../../../model/dto/CategoryDto';
import { ThematicDto } from '../../../model/dto/ThematicDto';
import { ThematicCarousel } from '../../thematic/ThematicCarousel';

type OnSiteCategoryProps = {
    category: CategoryDto
}

export function OnSiteCategory(props: OnSiteCategoryProps): ReactElement {
  // on va récupérer la liste de mes thématiques pour une catégorie donnée
  const dsThematics = useDataSource<ThematicDto>({
    resource: ResourceDataProviderEnum.thematic,
    params: {
      filter: { category: props.category.id },
      sort: { field: 'position', order: 'asc' },
    },
  });

  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dsThematics.total > 0 && (
        <section className="mb-5">
          <h3 className="plus mb-4">{props.category.name}</h3>
          {dsThematics.dataList.map((thematic) => (
            <ThematicCarousel
              key={thematic.id}
              thematic={thematic}
              eLearning={false}
              resource={ResourceDataProviderEnum.training}
              statusField="status"
            />
          ))}
        </section>
      )}
    </>
  );
}
