import * as Yup from 'yup';

import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { ErrorMessage, Formik } from 'formik';
import { useAuthProvider } from 'leeloo-react/component/hook/useAuthProvider';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import edit from '../../../../../assets/icons/edit.png';

import './createUnite.css';

const validationSchema = Yup.object().shape({
  titre: Yup.string().required('Le titre est obligatoire'),
});

function EditChapitre({ chapitreToBeEdited }) {
  const [showModal, setShowModal] = useState(false);
  const { authData } = useAuthProvider();
  axios.defaults.headers.common = { Authorization: `Bearer ${authData.token}` };
  const uri = process.env.REACT_APP_BASE_URL;

  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return 'danger';
    }
    if (value) {
      return 'info';
    }
    return 'warning';
  };

  const showModalFunction = (chapitre) => {
    setShowModal(chapitre);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const handleSubmit = (values) => {
    const data = new FormData();
    data.append('titre', values.titre);

    axios.post(
      `${uri}/chapitre/${chapitreToBeEdited?.id}/edit`,
      data,
    )
      .then(() => {
        values.titre = '';
        document.getElementById('formulaire-edit-unite').reset();
        toast.success('Ce chapitre a bien été modifiée!');
        window.location.reload();
      })
      .catch(() => {
        toast.error('Action non autorisée!');
      });
  };

  useEffect(() => {

  }, [chapitreToBeEdited]);

  return (
    <>
      <span className="dropdown-item cursor-pointer text-white" onClick={(e) => showModalFunction(chapitreToBeEdited)}>
        <img src={edit} className="pe-1" />
        Modifier
      </span>
      <Modal show={showModal} onHide={(e) => hideModal()} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            {`Modification du chapitre "${chapitreToBeEdited?.titre}"`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row g-3">
            <Formik
              initialValues={{
                titre: chapitreToBeEdited?.titre,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form className="row g-3" id="formulaire-edit-unite" onSubmit={handleSubmit}>

                  <div className="col-md-12">
                    <label className="form-label">Titre</label>
                    <input
                      status={onHandelStatus(
                        touched.ref,
                        errors.ref,
                        values.ref,
                      )}
                      type="text"
                      className="form-control"
                      id="titre"
                      name="titre"
                      placeholder="Ajouter un titre"
                      onChange={handleChange('titre')}
                      value={values.titre}
                      onBlur={handleBlur('titre')}
                      errors={errors.titre}
                    />
                    <ErrorMessage name="titre">
                      {(msg) => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div />

                  <div className="row">
                    <div className="col-md-6">
                      <Button variant="" onClick={(e) => hideModal()} style={{ color: '#006FFF' }}>
                        Annuler
                      </Button>
                    </div>

                    <div className="col-md-6 text-right">
                      <Button
                        type="submit"
                        variant="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>

                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditChapitre;
