import React, { ReactElement, useEffect, useState } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { SelectField } from 'leeloo-react/component/form/field/SelectField';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../../enum/ResourceDataProviderEnum';
import { KpiType } from '../../../../../model/kpis/KpiType';
import { SectorStatsType } from '../../../../../model/kpis/SectorStatsType';
import Barchart from '../../../../chart/Barchart';
import { RouterCustomFileLink } from '../../../../router/RouterCustomFileLink';
import {ChartDataset} from "chart.js";

export function KpiSector({ dateRange }: KpiType): ReactElement {
  const [sectorType, setSectorType] = useState('region');
  let kpisTitle: string = 'Par région';
  let kpisExport: string = ResourceDataProviderEnum.kpisRegionsExport;

  const dsSectors = useDataSource<SectorStatsType>({
    resource: ResourceDataProviderEnum.kpisSectors,
    singleResult: true,
    noLoadAfterInit: true,
  });

  const dsRegions = useDataSource<SectorStatsType>({
    resource: ResourceDataProviderEnum.kpisRegions,
    singleResult: true,
    noLoadAfterInit: true,
  });
  let dataSource = dsRegions;

  let datasets: ChartDataset<'bar', (number | [number, number] | null)[]>[] = [
    {
      label: 'Sessions de formation',
      data: dsRegions?.oneData?.data.map((value) => value.nbrSector) ?? [],
      backgroundColor: '#9500FF',
    },
    {
      label: 'Participants',
      data: dsRegions?.oneData?.data.map((value) => value.nbrParticipants) ?? [],
      backgroundColor: '#6B6DFF',
    },
  ];

  if (sectorType !== 'region') {
    kpisTitle = 'Par secteur/agence';
    kpisExport = ResourceDataProviderEnum.kpisSectorsExport;
    dataSource = dsSectors;
    datasets = [
      {
        label: 'Formation',
        data: dsSectors?.oneData?.data.map((value) => value.nbrSector) ?? [],
        backgroundColor: '#6B6DFF',
      },
    ];
  }

  useEffect(() => {
    if (dateRange) {
      const params = {
        uriParameters: {
          by: sectorType,
          startAt: dateRange.startAt || null,
          endAt: dateRange.endAt || null,
        },
      };
      dataSource.setParams(params);
    }
  }, [dateRange, sectorType]);

  return (
    <div className="w-100 p-3 bg-dark-grey mt-4">
      <div className="d-flex justify-content-between">
        <SelectField
          isClearable={false}
          value={sectorType}
          optionsList={[
            { label: 'Par Région', value: 'region' },
            { label: 'Par Secteur', value: 'sector' },
            { label: 'Par Agence', value: 'agence' },
          ]}
          onChangeValue={(v: string) => {
            setSectorType(v);
          }}
        />
        <h4>{kpisTitle}</h4>
        <RouterCustomFileLink
          resource={kpisExport}
          params={{
            data: {
              startAt: dateRange?.startAt || null,
              endAt: dateRange?.endAt || null,
            },
          }}
          nameFile="dashboard_secteur_agence.csv"
          className="btn btn-outline-primary square"
        >
          <Icon name="uil uil-export" />
        </RouterCustomFileLink>
      </div>
      <Barchart
        labels={dataSource?.oneData?.data.map((value) => ('name' in value ? value.name : value.sector)) ?? []}
        datasets={datasets}
      />
    </div>
  );
}
