import React, { ReactElement } from 'react';

import { FadeAnim } from 'leeloo-react/component/animation/FadeAnim';

/**
 * Représente le container de base des pages simple sans auth
 * (page login, mdp oublié etc....)
 * @returns
 */
export function SimpleContainer(props: {className?:string, children?: any}): ReactElement {
  return (
    <div className={`${(props.className) ? props.className : ''} container simple-container d-flex flex-column justify-content-center`}>
      <FadeAnim duration={0.5}>
        {props.children}
      </FadeAnim>
    </div>
  );
}

SimpleContainer.defaultProps = {
  className: null,
  children: null,
};
