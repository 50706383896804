import Plus from '../../assets/icons/Plus.svg';
import './hn.css';

function Hn({
  title, level, plus = true, className = '',
}) {
  return (
    <div className={className}>
      {(() => {
        switch (level) {
        case 'h1':
          return (
            <h1 className="search-title">
              {plus && (
                <span>
                  <img src={Plus} alt="Plus" />
                </span>
              )}
              {title}
            </h1>
          );
        case 'h2':
          return (
            <h2 className="search-title">
              {plus && (
                <span>
                  <img src={Plus} alt="Plus" />
                </span>
              )}
              {title}
            </h2>
          );
        case 'h3':
          return (
            <h3 className="search-title">
              {plus && (
                <span>
                  <img src={Plus} alt="Plus" />
                </span>
              )}
              {title}
            </h3>
          );
        case 'h4':
          return (
            <h4 className="search-title">
              {plus && (
                <span>
                  <img src={Plus} alt="Plus" />
                </span>
              )}
              {title}
            </h4>
          );
        case 'h5':
          return (
            <h5 className="search-title">
              {plus && (
                <span>
                  <img src={Plus} alt="Plus" />
                </span>
              )}
              {title}
            </h5>
          );

        default:
          return (
            <p className="search-title mb-1 d-flex">

              {plus && (
                <span>
                  <img src={Plus} alt="Plus" />
                </span>
              )}
              {title}
            </p>
          );
        }
      })()}
    </div>
  );
}

export default Hn;
