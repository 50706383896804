import React, { ReactElement, useEffect } from 'react';

import { Icon } from 'leeloo-react/component/display/Icon';
import { useDataSource } from 'leeloo-react/component/hook/useDataSource';

import { ResourceDataProviderEnum } from '../../../../../enum/ResourceDataProviderEnum';
import { KpiType } from '../../../../../model/kpis/KpiType';
import { TrainedStatsType } from '../../../../../model/kpis/TrainedStatsType';
import DoughnutChart from '../../../../chart/DoughnutChart';
import { RouterCustomFileLink } from '../../../../router/RouterCustomFileLink';

export function KpiTrained({ dateRange }: KpiType): ReactElement {
  const dsTrained = useDataSource<TrainedStatsType>({
    resource: ResourceDataProviderEnum.kpisTrained,
    singleResult: true,
    noLoadAfterInit: true,
  });

  useEffect(() => {
    if (dateRange) {
      const params = {
        uriParameters: {
          startAt: dateRange?.startAt || null,
          endAt: dateRange?.endAt || null,
        },
      };

      dsTrained.setParams(params);
    }
  }, [dateRange]);

  return (
    <div className="p-3 bg-dark-grey h-100">
      <div className="d-flex justify-content-between">
        <h4>Personnes formées / Non formées</h4>
        <RouterCustomFileLink
          resource={ResourceDataProviderEnum.kpisTrainedExport}
          params={{
            data: {
              startAt: dateRange?.startAt || null,
              endAt: dateRange?.endAt || null,
            },
          }}
          nameFile="dashboard_trained.csv"
          className="btn btn-outline-primary square"
        >
          <Icon name="uil uil-export" />
        </RouterCustomFileLink>
      </div>

      <div className="row trained-container py-5 align-items-center d-flex h-100">
        <div className="col-md-4">
          <div className="chartContainer">
            <DoughnutChart data={[dsTrained.oneData?.nbrNotTrained ?? 0, dsTrained.oneData?.nbrTrained ?? 0]} backgroundColor={['#FFFFFF', '#3EA279']} borderColor={['#FFFFFF', '#3EA279']} />
            <div className="chartInner">
              <div className="chartStatus d-flex flex-column">
                <span>Total</span>
                <span>{(dsTrained.oneData?.nbrNotTrained ?? 0) + (dsTrained.oneData?.nbrTrained ?? 0)}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="legend text-white p-3 d-flex align-items-center">
            <span className="green" />
            {`Personnes formées (${dsTrained.oneData?.nbrTrained ?? 0})`}
          </div>
          <div className="legend text-white p-3 d-flex align-items-center">
            <span className="white" />
            {`Personnes non formées (${dsTrained.oneData?.nbrNotTrained ?? 0})`}
          </div>
        </div>
      </div>
    </div>
  );
}
